<template>
  <div
      class="mx-1">
    <b-btn
        v-b-toggle.mainMenu>
      <span
          class="nav__brand">
        <img
            src="@/assets/navbar-logo.png"
            style="height: 30px; margin-right: 10px;"
        />
        <span
            class="nav__brand__secondary"
            v-text="navText"
        />
      </span>
    </b-btn>
    <b-sidebar
        id="mainMenu"
        :title="$t('mainMenu.header')"
        v-model="mainMenu"
        backdrop
        shadow>
      <transition
          name="slide-fade"
          mode="out-in">
        <div
            v-if="$store.state.autosaveTriggered"
            key="autosaveWarning">
          <h4
              class="mx-3">
            <b-icon
                icon="cog"
                color="lightgrey"
                :scale="0.9"
            />
            {{$t('autosaveInProgress')}}
          </h4>
        </div>
        <div
            v-else
            key="normalView">
          <div
              v-if="$_app.switch.invoicing"
              @click="viewCollection('billing')"
              class="collectionButton__billing">
            {{$t('collections.billing')}}
          </div>
          <div
              v-for="collection in collections"
              :key="collection._id"
              class="collectionButton"
              @click="viewCollection(collection)">
            <span
                v-text="collection.name"
            />
          </div>
          <div
              @click="viewCollection('collectionAdmin')"
              v-if="$_app.switch.collectionAdminAccess"
              class="collectionButton__edit">
            <b-icon-pencil
                scale="1.25"
                class="mr-3 ml-1 my-auto"
            />
            {{$t('collections.editCollections')}}
          </div>
          <div
              @click="viewCollection('workDay')"
              v-if="$_app.switch.workDay"
              class="collectionButton">
            <b-icon-calendar
                scale="1.25"
                class="mr-3 ml-1 my-auto"
            />
            {{$t('workDay.title')}}
          </div>
          <div
              @click="viewCollection('workDayTimecards')"
              v-if="$_app.switch.workDay"
              class="collectionButton">
            <b-icon-card-heading
                scale="1.25"
                class="mr-3 ml-1 my-auto"
            />
            {{$t('workDay.titles.timeSheetsAdmin')}}
          </div>
        </div>
      </transition>
      <b-btn
          id="systemSettingsButton"
          v-if="$_app.switch.settingsButton"
          @click="showSettings()">
        <b-icon
              icon="cog"
              :aria-label="$t('aria.showSettings')"
              color="lightgrey"
              :scale="0.9"
          />
        {{$t('sys.settings')}}
      </b-btn>
      <b-btn
          id="restartButton"
          v-text="$t('sys.restartApp')"
          @click="restartApp()"
      />
      <b-btn
          id="logOutButton"
          v-text="$t('sys.logout')"
          @click="$store.state.signOut = true"
      />
      <foot-bar
          id="footerInMenu"
          v-if="$_app.switch.footerInMenu"
      />
    </b-sidebar>
  </div>
</template>

<script>
import FootBar from '@/components/util/Footer';

export default {
  components: {
    FootBar,
  },
  title() {
    return `${this.$t('mainMenu.pageTitle')} | ${this.$t('project.brand')}`;
  },
  computed: {
    collections() {
      return this.$store.getters.collections;
    },
    itemActionCancel: {
      get() {
        return this.$store.state.itemActionCancelPress;
      },
      set(newVal) {
        this.$store.state.itemActionCancelPress = newVal;
      },
    },
    navText: {
      get() {
        return this.$store.state.navText;
      },
      set(newVal) {
        this.$store.state.navText = newVal;
      },
    },
  },
  data() {
    return {
      mainMenu: false,
    };
  },
  methods: {
    forceSave() {
      this.$store.commit('AUTOSAVE_NOW');
    },
    restartApp() {
      if (this.$store.state.autosaveTriggered) {
        this.forceSave();
      }
      this.$store.dispatch('restartApp');
      this.$router.push('/');
      this.$router.go();
    },
    showSettings() {
      this.$store.commit('CLOSE_PREFERENCES');
      this.$bvModal.show('settingsModal');
    },
    viewCollection(coll) {
      if (this.$store.state.autosaveTriggered) {
        setTimeout(() => { this.viewCollection(coll); }, 1500);
      } else if (!this.$store.state.autosaveTriggered) {
        let link;
        if (coll === 'billing') {
          link = '/dashboard/billing';
          this.navText = this.$t('navText.billing');
        } else if (coll === 'collectionAdmin') {
          link = '/dashboard/collections';
          this.navText = this.$t('navText.collectionAdmin');
        } else if (coll === 'workDay') {
          link = '/dashboard/workday';
          this.navText = this.$t('navText.workDay');
        } else if (coll === 'workDayTimecards') {
          link = '/dashboard/timecards';
          this.navText = this.$t('navText.timecards');
        } else {
          link = `/dashboard/records/${coll._id}`;
          this.navText = coll.name ? coll.name : null;
        }
        if (this.$route.fullPath !== link) {
          this.$router.push(link);
        } else {
          this.itemActionCancel = true;
          if (this.$store.state.justViewed.collection) {
            this.$store.state.justViewed.collection = null;
          }
          if (this.$store.state.justViewed.record) {
            this.$store.state.justViewed.record = null;
          }
        }
        this.mainMenu = false;
      }
    },
  },
};
</script>
