<!-- eslint-disable max-len -->
<template>
  <b-row id="bodyPage"
      class="main-container"
      h-align="center">
    <b-col
        class="m-0 p-0"
        align="center">
      <div
          id="brand"
          class="text-center mb-2">
          Valley Roof Removal
        <div
            id="header"
            class="text-center">
            Roof Tearoff Order Form
        </div>
      </div>
      <div id="form">
        <b-form
            id="formData">
          <b-container
              id="dateContainer"
              class="form-container text-center">
            <b-row
                align-h="center">
              <b-col
                  cols="auto"
                  align="center">
                <b-input-group
                      class="i-small"
                      prepend="Requested Tearoff Date">
                  <b-form-input
                      id="dateRequested"
                      type="date"
                      v-model="formData.dateRequested">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <div
                class="error-label"
                v-if="!$v.formData.dateRequested.required
                      && $v.formData.dateRequested.$dirty">
              Please select a date.
            </div>
          </b-container>
          <b-container
              id="contactInfo"
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Contractor Details
              </span>
            </b-container>
            <b-form-row class="my-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Contractor's Name">
                  <b-form-input
                      id="contractorName"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.name"
                  />
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.name.required
                          && $v.formData.contractor.name.$dirty">
                  Enter the contractor's name as it appears on their license.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                  prepend="Contractor's License Number"
                  class="i-medium">
                  <b-form-input
                      id="contractorLicence"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.license">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.license.required
                          && $v.formData.contractor.license.$dirty">
                  Enter the contractor's license number.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Phone Number"
                    class="i-medium">
                  <b-form-input
                      id="contractorPhone"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.phone">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.phone.required
                          && $v.formData.contractor.phone.$dirty">
                  Enter your phone number (numbers only).
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                  prepend="Email"
                  class="i-medium">
                  <b-form-input
                      id="contractorEmail"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.email">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                  prepend="Billing Address"
                  class="i-medium">
                  <b-form-input
                      id="contractorBillingAddress"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.billingStreet">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingStreet.required
                          && $v.formData.contractor.billingStreet.$dirty">
                Enter your billing street address.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="City, State, Zip"
                    class="i-medium">
                  <b-form-input
                      id="contractorBillingCityStateZip"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.billingCityStateZip"
                  />
              </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingCityStateZip.required
                          && $v.formData.contractor.billingCityStateZip.$dirty">
                  Enter your billing city, state, and zip code.
                </div>
              </b-col>
            </b-form-row>
          </b-container>
          <b-container
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Jobsite Details
              </span>
            </b-container>
            <b-row
                class="mb-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Jobsite Address"
                    class="i-medium">
                  <b-form-input
                      id="jobAddress"
                      type="text"
                      class="formInput"
                      v-model="formData.job.address">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.address.required
                          && $v.formData.job.address.$dirty">
                  Enter the jobsite address.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Cross Street"
                    class="i-medium">
                  <b-form-input
                      id="jobCrossStreet"
                      type="text"
                      class="formInput"
                      v-model="formData.job.crossStreet">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.crossStreet.required
                          && $v.formData.job.crossStreet.$dirty">
                  Enter the cross-street.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="City"
                    class="i-medium">
                  <b-form-input
                      id="jobCity"
                      type="text"
                      class="formInput"
                      v-model="formData.job.city">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.city.required
                          && $v.formData.job.city.$dirty">
                  Enter the city.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Homeowner's Name"
                    class="i-medium">
                  <b-form-input
                      id="homeownerName"
                      type="text"
                      class="formInput"
                      v-model="formData.homeowner.name">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.name.required
                          && $v.formData.homeowner.name.$dirty">
                  Enter the name of the homeowner.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Homeowner's Phone"
                    class="i-medium">
                  <b-form-input
                      id="homeownerPhone"
                      type="text"
                      class="formInput"
                      v-model="formData.homeowner.phone">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.required
                          && $v.formData.homeowner.$dirty">
                  Enter the homeowner's phone number.
                </div>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                    class="i-small"
                    prepend="Access">
                  <b-form-radio-group
                      id="jobNarrowDriveway"
                      buttons
                      class="formInput"
                      v-model="formData.job.access">
                    <b-form-radio
                        class="mx-1"
                        value="Good Access"
                        :button-variant="formData.job.access === 'Good Access' ? 'success' : 'outline-success'">
                        Good
                      </b-form-radio>
                      <b-form-radio
                      class="mx-1"
                      value="Poor Access"
                      :button-variant="formData.job.access === 'Poor Access' ? 'success' : 'outline-success'">
                      Poor
                    </b-form-radio>
                    <b-form-radio
                    class="mx-1"
                    value="No Access"
                    :button-variant="formData.job.access === 'No Access' ? 'success' : 'outline-success'">
                    None
                  </b-form-radio>
                </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                  prepend="Narrow Driveway?"
                  class="i-medium">
                  <b-form-radio-group
                      id="jobNarrowDriveway"
                      buttons
                      class="formInput"
                      v-model="formData.job.narrowDriveway">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.narrowDriveway === 'Narrow Driveway' ? 'success' : 'outline-success'"
                        value="Narrow Driveway">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.narrowDriveway === 'Driveway is not narrow' ? 'success' : 'outline-success'"
                        value="Driveway is not narrow">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.narrowDriveway.required
                          && $v.formData.job.narrowDriveway.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                    class="i-medium"
                    prepend="Low Trees?">
                  <b-form-radio-group
                      id="jobLowTrees"
                      buttons
                      class="formInput"
                      v-model="formData.job.lowTrees">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.lowTrees === 'Low Trees present' ? 'success' : 'outline-success'"
                        value="Low Trees present">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.lowTrees === 'Now Low Trees' ? 'success' : 'outline-success'"
                        value="No Low Trees">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.lowTrees.required
                          && $v.formData.job.lowTrees.$dirty">
                  Select a response.
                </div>
              </b-col>
            </b-row>
            <b-row
                align-h="center"
                class="mt-3">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Ground Drop?">
                  <b-form-radio-group
                      id="jobGroundDrop"
                      buttons
                      class="formInput"
                      v-model="formData.job.groundDrop">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.groundDrop === 'Yes' ? 'success' : 'outline-success'"
                        value="Yes">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.groundDrop === 'No' ? 'success' : 'outline-success'"
                        value="No">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <small>
                  (ground drop required for 8/12 and above, or poor access)
                </small>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="auto"
                  class="inputContainer">
                <b-input-group
                    prepend="Does material need to be carried up or down?">
                  <b-form-radio-group
                      id="jobNarrowDriveway"
                      buttons
                      class="formInput"
                      v-model="formData.job.carried">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.carried === 'Material needs to be carried' ? 'success' : 'outline-success'"
                        value="Material needs to be carried">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.carried === 'No' ? 'success' : 'outline-success'"
                        value="No">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <br />
                <span
                    style="font-size: 0.9rem"
                    v-if="formData.job.carried === 'Material needs to be carried'">
                  This type of transition could result in a double handling charge of $10.00/square extra.
                </span>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.carried.required
                          && $v.formData.job.carried.$dirty">
                  Select a response.
                </div>
              </b-col>
            </b-row>
            <b-row
                align-h="center"
                class="mt-3">
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Number of AC units:">
                  <b-form-input
                      id="numberOfAcUnits"
                      type="text"
                      class="formInputSmall"
                      v-model="formData.job.acQty"
                  />
                </b-input-group>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Number of Skylights:">
                  <b-form-input
                      id="numberOfSkylights"
                      type="text"
                      class="formInputSmall"
                      v-model="formData.job.skylightsQty"
                  />
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                  prepend="Dogs?">
                  <b-form-input
                      id="jobDogs"
                      type="text"
                      class="formInputSmall"
                      v-model="formData.job.dogs">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.dogs.required
                          && $v.formData.job.dogs.$dirty">
                  Select a response.
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-container
              id="diagram"
              class="form-container mt-4">
            <b-container
                class="headlineContainer">
              <b-row
                  h-align="center">
                <b-col
                    align="center"
                    class="p-2 image-upload__container">
                  <div
                      class="headline">
                    Upload a Diagram
                  </div>
                  <input
                      id="inputFileToLoad"
                      type="file"
                      accept="image/*"
                      @change="addImage()"
                      />
                  <b-button
                      v-if="formData.diagram"
                      @click="formData.diagram = null">
                    Remove
                  </b-button>
                    <div
                      v-if="formData.diagram">
                    <img
                        :src="previewImage"
                    />
                  </div>
                  <!-- <image-modal
                      v-if="imageLoader"
                      @onConfirm="useImage"
                      @onCancel="imageLoader=false"
                      @uploadFail="imageError"
                  />
                  <b-btn
                      v-if="formData.diagram === null"
                      @click="startLoader"
                      v-text="$t('addImage')"
                      variant="success"
                      class="pr-2"
                      v-show="!imageLoader"
                      style="border: 1px solid lightgrey"
                  />
                  <b-btn
                      v-if="formData.diagram !== null"
                      @click="clearImage(formData.diagram)"
                      v-text="$t('deleteImage')"
                      variant="warning"
                      class="pr-2"
                      v-show="!imageLoader"
                      style="border: 1px solid lightgrey"
                  /> -->
                </b-col>
              </b-row>
              <!-- <b-row
                  h-align="center">
                <b-col
                    align="center">
                  <b-img
                      fluid
                      :src="formData.diagram"
                  />
                </b-col>
              </b-row> -->
            </b-container>
          </b-container>
          <b-container
              class="form-container">
            <b-container
              class="headlineContainer">
              <span
                  class="headline">
                Project Information
              </span>
            </b-container>
            <b-row
              align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend='Squares:'>
                  <b-form-input
                      id="squaresInput"
                      type="text"
                      v-model="formData.details.squares">
                  </b-form-input>
                </b-input-group>
                <b-input-group
                  prepend='Type of Roof:'>
                  <b-form-input
                      id="typeInput"
                      type="text"
                      v-model="formData.details.type">
                  </b-form-input>
                </b-input-group>
                <b-input-group
                    prepend='Layers:'>
                  <b-form-input
                    id="layersInput"
                    type="text"
                    v-model="formData.details.layers">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend="Number of Stories:">
                  <b-form-input
                      id="numberOfStories"
                      type="text"
                      v-model="formData.details.stories">
                  </b-form-input>
                </b-input-group>
                <b-input-group
                    prepend="Roof Pitch:">
                  <b-form-input
                      id="buildingPitch"
                      type="text"
                      v-model="formData.details.pitch">
                  </b-form-input>
                  <div
                      class="error-label"
                      v-if="!$v.formData.details.pitch.required
                            && $v.formData.details.pitch.$dirty">
                    Enter the pitch of the roof.
                  </div>
                </b-input-group>
              </b-col>
            </b-row>
          </b-container>
          <b-container
              id="roofInfo"
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Details & Options
              </span>
            </b-container>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  cols="auto">
                <b-input-group
                    prepend="Tearoff Options:"
                    checkboxes>
                  <b-form-checkbox-group
                      v-model="formData.building.tearOff"
                      :options="tearOffOptions"
                      class="mx-1"
                      buttons
                      button-variant="outline-success"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center"
                class="mt-3">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                    prepend="Jacks:">
                  <b-form-radio-group
                      buttons
                      id="jacksOptions"
                      class="formInput"
                      v-model="formData.building.jacks">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.jacks === 'Save jacks' ? 'success' : 'outline-success'"
                        value="Save jacks">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.jacks === 'Tear Off' ? 'success' : 'outline-success'"
                        value="Tear Off">
                      Tear Off
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                    prepend="Gutters:">
                  <b-form-radio-group
                      id="buildingRemoveExisting"
                      buttons
                      class="formInput"
                      v-model="formData.building.gutters">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.gutters === 'Save' ? 'success' : 'outline-success'"
                        value="Save">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.gutters === 'Tear Off' ? 'success' : 'outline-success'"
                        value="Tear Off">
                      Tear Off
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <div
                    v-if="formData.building.gutters === 'Tear Off'"
                    class="form-labels">
                  Gutter Footage:
                </div>
                <b-form-input
                    v-if="formData.building.gutters === 'Tear Off'"
                    id="guttersFootage"
                    type="text"
                    v-model="formData.building.guttersFt" />
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                    prepend="Antenna:">
                  <b-form-radio-group
                      id="buildingAntenna"
                      buttons
                      class="formInput"
                      v-model="formData.building.antenna">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.antenna === 'Save Antenna' ? 'success' : 'outline-success'"
                        value="Save Antenna">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.antenna === 'Tear Off' ? 'success' : 'outline-success'"
                        value="Tear Off">
                      Tear Off
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.antenna === 'N/A' ? 'success' : 'outline-success'"
                        value="N/A">
                      N/A
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center"
                class="my-3">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Dormer Vents:">
                  <b-form-radio-group
                      id="buildingDormerVents"
                      buttons
                      class="formInput"
                      v-model="formData.building.dormerVents">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.dormerVents === 'Save Vents' ? 'success' : 'outline-success'"
                        value="Save Vents">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.dormerVents === 'Tear Off' ? 'success' : 'outline-success'"
                        value="Tear Off">
                      Tear Off
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.dormerVents === 'N/A' ? 'success' : 'outline-success'"
                        value="N/A">
                      N/A
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Turbines:">
                  <b-form-radio-group
                      id="buildingTurbines"
                      buttons
                      class="formInput"
                      v-model="formData.building.turbines">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.turbines === 'Save Turbines' ? 'success' : 'outline-success'"
                        value="Save Turbines">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.turbines === 'Tear Off' ? 'success' : 'outline-success'"
                        value="Tear Off">
                      Tear Off
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.turbines === 'N/A' ? 'success' : 'outline-success'"
                        value="N/A">
                      N/A
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Solar Panels:">
                  <b-form-radio-group
                      id="buildingSolarPanels"
                      buttons
                      class="formInput"
                      v-model="formData.building.solarPanels">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.solarPanels === 'Save Solar Panels' ? 'success' : 'outline-success'"
                        value="Save Solar Panels">
                      Save
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.solarPanels === 'Tear Off Solar Panels' ? 'success' : 'outline-success'"
                        value="Tear Off Solar Panels">
                      Tear Off
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.solarPanels === 'N/A' ? 'success' : 'outline-success'"
                        value="N/A">
                      N/A
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <span style="font-size: 0.9rem">
                  (must by disconnected by homeowner or contractor)
                </span>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Cathedral Ceiling:">
                  <b-form-radio-group
                      id="buildingCathedralCeiling"
                      buttons
                      class="formInput"
                      v-model="formData.building.cathedral">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.cathedral === 'Cathedral Ceiling' ? 'success' : 'outline-success'"
                        value="Cathedral Ceiling">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.cathedral === 'No Cathedral Ceiling' ? 'success' : 'outline-success'"
                        value="No Cathedral Ceiling">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  align="center">
              <b-input-group
                  prepend="Special Instructions"
                  class="full-size">
                <b-textarea id="specialInstructionsInput"
                    v-model="formData.specialInstructions"
                    :rows="4"
                    :max-rows="8"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
                cols="12"
                align="center">
              <b-input-group
                  prepend="Partial Tearoff (Contractor/Homeowner must be present)"
                  class="full-size">
                <b-textarea id="partialTearoffInput"
                    v-model="formData.partialTearoff"
                    :rows="2"
                    :max-rows="4"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
                cols="12"
                align="center">
              <b-input-group
                  prepend="Tarp Flowers, etc."
                  class="full-size">
                <b-textarea id="tarpFlowersInput"
                    v-model="formData.tarpFlowers"
                    :rows="2"
                    :max-rows="4"
                  />
              </b-input-group>
            </b-col>
          </b-row>
          </b-container>
          <b-container
              id="signatureBox"
              class="form-container">
            <b-container
                class="headlineContainer"
                v-if="!complete">
              <span
                  class="headline">
                Sign
              </span>
            </b-container>
            <b-form-row>
              <b-col
                  align="center"
                  class="inputContainer"
                  v-if="!complete">
                <span
                    class="form-labels py-3">
                  By typing your name into this field, you are signing this order form and contract electronically.
                </span>
                <b-form-input
                    id="signature"
                    type="text"
                    placeholder="Enter your name..."
                    class="name-input"
                    v-model="formData.signature">
                </b-form-input>
                <span
                    class="form-labels">
                  Sign this to designate that you understand and agree with all stipulations as outlined in our terms and conditions, which can be viewed <a href="http://valleyroofremoval.com/terms/" _target="blank" style="color: goldenrod">here</a>, and that the roof is ready for tearoff.
                </span>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  v-else>
                <span
                    v-if="showSpinner">
                  <b-spinner />
                  Your request is uploading. Please wait.
                </span>
                <span
                    v-else>
                    Your request has been received. We will contact you once we have reviewed the details.
                </span>
              </b-col>
            </b-form-row>
            <div
                class="error-label"
                v-if="!$v.formData.signature.required
                      && $v.formData.signature.$dirty">
            You must enter a digital signature.
            </div>
            <b-container
                align="center"
                class="mt-2">
              <b-btn
                @click="submitThis(formData)"
                variant="primary"
                size="lg"
                :disabled="complete || $v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalid">
                <b-spinner
                    v-if="showSpinner" />
                Submit
              </b-btn>
              <p />
              <span v-show="$v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalids">
                Before submitting, please enter the following information:
                <br />
              </span>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.contractor.name.$invalid">
                • Contractor's Name
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.contractor.email.$invalid">
                • Contractor's Email Address
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.job.address.$invalid">
                • Project Address
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.signature.$invalid">
                • Please sign the form
                <br />
              </b>
            </b-container>
          </b-container>
        </b-form>
      </div>
      <!-- <div id="thankYou"
          v-show="complete">
        <b-container id="thankYouContainer"
            class="form-container text-center">
          <b-form-row>
            <b-col align="center"
                class="headlineContainer">
              <span
                  class="headline">
                Thank you
              </span>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col
                align="center"
                v-if="formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request or we can email it to {{formData.contractor.email}}.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mr-2 ml-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                    <b-btn id="emailReceipt"
                        variant="primary"
                        class="ml-2 mr-auto"
                        @click="emailPdf('customer')"
                        :disabled="emailSent ? true : false">
                      {{emailSent === false ? 'Email' : 'Email Sent'}}
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
            <b-col
                align="center"
                v-if="!formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request by clicking below.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mx-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div> -->
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// import ImageModal from '@/components/data/ImageUpload';
import { s3 } from '@/store/aws';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'Tear-offForm',
  mixins: [
    validationMixin,
  ],
  // components: {
  //   ImageModal,
  // },
  title() {
    return `Tearoff Order Form | ${this.$t('project.brand')}`;
  },
  data() {
    return {
      active: 0,
      complete: false,
      changesMade: false,
      message: '',
      previewImage: null,
      errorMsg: null,
      fileName: null,
      emailSent: false,
      imageLoader: false,
      tearOffOptions: [
        { text: 'House', value: 'House' },
        { text: 'Patio', value: 'Patio' },
        { text: 'Attached Garage', value: 'Attached Garage' },
        { text: 'Detached Garage', value: 'Detached Garage' },
      ],
      formData: {
        building: {
          tearOff: [],
          jacks: null,
          gutters: null,
          guttersFt: null,
          antenna: null,
          solarPanels: null,
          dormerVents: null,
          turbines: null,
          cathedral: null,
        },
        diagram: null,
        contractor: {
          name: null,
          license: null,
          email: null,
          phone: null,
          billingStreet: null,
          billingCityStateZip: null,
        },
        dateRequested: null,
        homeowner: {
          name: null,
          phone: null,
        },
        job: {
          address: null,
          crossStreet: null,
          city: null,
          groundDrop: null,
          lowTrees: null,
          dogs: null,
          carried: null,
          narrowDriveway: null,
          access: null,
          acQty: null,
          skylightsQty: null,
        },
        details: {
          squares: null,
          type: null,
          layers: null,
          stories: null,
          pitch: null,
        },
        signature: null,
        specialInstructions: null,
        partialTearoff: null,
        tarpFlowers: null,
        dateSigned: moment().format('Do MMMM YYYY'),
      },
      emailFormData: new FormData(),
      customerFormData: new FormData(),
      email: {
        to: null,
        file: null,
        file2: null,
        fileName: null,
        subjectLine: 'Tearoff Request',
        message: null,
        from: 'valleydemo@hotmail.com',
        replyTo: null,
        serviceName: 'valleyroofremoval.com',
      },
    };
  },
  computed: {
    readyToCleanup() {
      return this.$store.state.cleanup;
    },
    completed() {
      return this.$store.state.sendFileSuccess;
    },
    showSpinner() {
      return this.$store.state.showSpinner;
    },
    uploadedDiagram() {
      return document.getElementById('inputFileToLoad');
    }
  },
  created() {
    // Catch reloads/page closures
    const that = this;
    window.addEventListener('beforeunload', (e) => {
      if (that.changesMade) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.changesMade) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(this.$t('unsavedChangesConfirm'));
      if (answer) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    formData: {
      handler() {
        if (this.changesMade === false) {
          this.changesMade = true;
        }
      },
      deep: true,
    },
    uploadedDiagram(newVal) {
      console.log('this', newVal);
    },
    readyToCleanup(newVal) {
      if (newVal) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
      }
    },
  },
  methods: {
    addImage() {
      const file = document.getElementById('inputFileToLoad').files[0];
      if(file.size > 9285760){
        alert("File is too big! Please select an image under 9mb");
      } else {
        // let diagram;
        // const toBase64 = file => new Promise((resolve, reject) => {
        //       const reader = new FileReader();
        //       reader.readAsDataURL(file);
        //       reader.onload = () => resolve(reader.result);
        //       reader.onerror = reject;
        //     });
        // toBase64(file).then((res) => {
        //   console.log('yesssss', res);
        //   this.email.file2 = res;
        //   // diagram = res;
        // });
        this.formData.diagram = file;
        this.formData.filename2 = file.name;
        this.displayUpload();
      }
    },
    displayUpload() {
      if (this.formData.diagram) {
        this.previewImage = URL.createObjectURL(this.formData.diagram);
      }
    },
    leavingPage() {
      console.log('leavin');
      // eslint-disable-next-line no-alert
      window.confirm(this.$t('unsavedChangesConfirm'));
    },
    imageError() {
      this.errorMsg = this.$t('upload.ErrorOversize');
      this.imageLoader = false;
      this.formData.diagram = null;
    },
    clearImage(src) {
      this.deleteImage(src);
      this.formData.diagram = null;
    },
    cleanPhone(numberString) {
      // eslint-disable-next-line prefer-template
      const cleaned = ('' + numberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },
    cancelUpload() {
      this.imageLoader = false;
    },
    useImage(data) {
      const img = {
        src: data.data.src,
        title: '',
        size: 'lg',
      };
      this.formData.diagram = img.src;
      this.imageLoader = false;
    },
    deleteImage(url) {
      const key = url.substring(url.lastIndexOf('/') + 1);
      s3.deleteObject({ Key: key }, (err, data) => {
        if (err) {
          this.message = `There was an error deleting your photo ${err.message}`;
        }
        this.message = 'Image deleted';
        this.interval = function run() {
          this.message = null;
        }.bind((this), 3000);
        console.log(this.message);
        return console.log('Success:', data);
      });
    },
    startLoader() {
      this.imageLoader = true;
      this.errorMsg = null;
    },
    async submitThis() {
      await this.emailPdf('client').then(this.changesMade = false);
    },
    makeTearoffItems(items) {
      const allItems = {
        columns: [],
        columnGap: 5,
        margin: [0, 0, 0, 10],
      };
      for (let i = 0; i < items.length; i += 1) {
        const item = {
          text: items[i],
          width: '15%',
        };
        allItems.columns.push(item);
      }
      return allItems;
    },
    makePdf() {
      let projectName = '';
      if (this.formData.job.address) {
        projectName = this.formData.job.address.replace(/[, ]+/g, ' ');
      } else {
        projectName = this.formData.contractor.name + this.formData.dateRequested;
      }
      const document = {
        pageMargins: [30, 85, 30, 30],
        header: [
          {
            text: 'VALLEY DEMO & RAINGUTTER',
            fontSize: 15,
            alignment: 'center',
            margin: [0, 20, 0, 0],
          },
          {
            text: '8996 Fruitridge Rd, Bldg 5, Sacramento, CA 95826',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 5],
          },
          {
            text: 'Phone: (916) 455-1690 Fax: (916)452-3571',
            fontSize: 10,
            alignment: 'center',
          },
          {
            text: 'Email: valleydemo@hotmail.com',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 40],
          },
        ],
        footer(currentPage, pageCount) {
          if (pageCount > 1) {
            return [{
              text: `Order form for ${projectName} | Page ${currentPage.toString()} of ${pageCount}`,
              alignment: 'center',
            }];
          }
          return {};
        },
        content: [
          {
            text: [
              {
                text: 'Requested Tearoff Date:   ',
                bold: true,
              },
              {
                text: moment(this.formData.dateRequested).format('l'),
                bold: true,
                fontSize: 13,
              },
            ],
            margin: [0, 15, 0, 10],
            alignment: 'right',
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor:   ',
                    bold: true,
                  },
                  this.formData.contractor.name ? `${this.formData.contractor.name}` : '',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  this.formData.contractor.phone ? `${this.cleanPhone(this.formData.contractor.phone)}` : '',
                ],
                width: 'auto',
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Job Location:   ',
                    bold: true,
                  },
                  `${this.formData.job.address ? this.formData.job.address : ''}, ${this.formData.job.city ? this.formData.job.city : ''}`,
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Cross Street:  ',
                    bold: true,
                  },
                  `${this.formData.job.crossStreet ? this.formData.job.crossStreet : ''}`,
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Homeowner\'s Name:   ',
                    bold: true,
                  },
                  `${this.formData.homeowner.name ? this.formData.homeowner.name : ''}`,
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  `${this.formData.homeowner.phone ? this.cleanPhone(this.formData.homeowner.phone) : ''}`,
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor\'s E-mail:   ',
                    bold: true,
                  },
                  `${this.formData.contractor.email ? this.formData.contractor.email : ''}`,
                ],
              },
              {
                text: [
                  {
                    text: 'Contractor\'s License:   ',
                    bold: true,
                  },
                  `${this.formData.contractor.license ? this.formData.contractor.license : ''}`,
                ],
              },
            ],
            margin: [0, 5],
          },
          {
            text: [
              {
                text: 'Contractor\'s Billing Address:  ',
                bold: true,
              },
              `${this.formData.contractor.billingStreet ? this.formData.contractor.billingStreet : ''},
              ${this.formData.contractor.billingCityStateZip ? this.formData.contractor.billingCityStateZip : ''}`,
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          this.formData.diagram !== null ? {
            text: [
              {
                text: 'Diagram:  ',
                bold: true,
              },
              {
                text: 'Link to Diagram',
                link: this.formData.diagram,
                decoration: 'underline',
              },
            ],
            margin: [0, 5, 0, 0],
          } : {
            text: {
              text: 'No Diagram Provided',
              bold: true,
            },
            margin: [0, 5, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            text: 'Tear Off:',
            bold: true,
            margin: [0, 10, 0, 0],
          },
          this.makeTearoffItems(this.formData.building.tearOff),
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Squares:',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  {
                    text: 'Layers:',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  {
                    text: 'Type of Roof:',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  {
                    text: 'Pitch:',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  {
                    text: 'Access:',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                width: '20%',
              },
            ],
            margin: [0, 10, 0, 2],
          },
          {
            columns: [
              {
                text: [
                  this.formData.details.squares === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.details.squares}`,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  this.formData.details.layers === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.details.layers}`,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  this.formData.details.type === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.details.type}`,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  this.formData.details.pitch === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.details.pitch}`,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  this.formData.job.access === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.job.access}`,
                  },
                ],
              },
            ],
            margin: [0, 2, 0, 10],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: 'Ground Drop:',
                bold: true,
              },
              {
                text: 'Double Handle/Carry:',
                bold: true,
              },
              {
                text: '# of Stories:',
                bold: true,
              },
              {
                text: 'Low Trees:',
                bold: true,
              },
              {
                text: 'Dogs:',
                bold: true,
              },
            ],
            margin: [0, 10, 0, 3],
          },
          {
            columns: [
              {
                text: [
                  this.formData.job.groundDrop !== null ? {
                    text: `${this.formData.job.groundDrop}`,
                  } : {
                    text: '',
                  },
                ],
              },
              {
                text: [
                  this.formData.job.carried !== null ? {
                    text: `${this.formData.job.carried}`,
                  } : {
                    text: '',
                  },
                ],
              },
              {
                text: [
                  this.formData.details.stories === null ? {
                    text: '',
                  } : {
                    text: `${this.formData.details.stories}`,
                  },
                ],
                width: '20%',
              },
              {
                text: [
                  this.formData.job.lowTrees !== null ? {
                    text: `${this.formData.job.lowTrees}`,
                  } : {
                    text: '',
                  },
                ],
              },
              {
                text: [
                  this.formData.job.dogs !== null ? {
                    text: `${this.formData.job.dogs}`,
                  } : {
                    text: '',
                  },
                ],
              },
            ],
            margin: [0, 0, 0, 10],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Jacks:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: 'Gutters:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: 'Antenna:',
                    bold: true,
                  },
                ],
              },
              {
                text: {
                  text: '# of AC Units:',
                  bold: true,
                },
              },
              {
                text: 'Dormer Vents:',
                bold: true,
              },
            ],
            margin: [0, 10, 0, 3],
          },
          {
            columns: [
              {
                text: [
                  this.formData.building.jacks !== null
                    ? `${this.formData.building.jacks}` : '',
                ],
              },
              {
                text: [
                  this.formData.building.gutters !== null
                    ? `${this.formData.building.gutters} ${this.formData.building.guttersFt} ft.` : '',
                ],
              },
              {
                text: [
                  this.formData.building.antenna !== null
                    ? `${this.formData.building.antenna}` : '',
                ],
              },
              {
                text: [
                  this.formData.job.acQty !== null
                    ? `${this.formData.job.acQty}` : '',
                ],
              },
              {
                text: [
                  this.formData.building.dormerVents !== null
                    ? `${this.formData.building.dormerVents}` : '',
                ],
              },
            ],
            margin: [0, 0, 0, 10],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Turbines:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: 'Solar Panels:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: 'Cathedral Ceiling:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: '# of Skylights:',
                    bold: true,
                  },
                ],
              },
              {
                text: [
                  {
                    text: 'Narrow Driveway',
                    bold: true,
                  },
                ],
              },
            ],
            margin: [0, 5, 0, 0],
          },
          {
            columns: [
              {
                text: [
                  this.formData.building.turbines !== null
                    ? `${this.formData.building.turbines}` : '',
                ],
              },
              {
                text: [
                  this.formData.building.solarPanels !== null
                    ? `${this.formData.building.solarPanels}` : '',
                ],
              },
              {
                text: [
                  this.formData.building.cathedral !== null
                    ? `${this.formData.building.cathedral}` : '',
                ],
              },
              {
                text: [
                  this.formData.job.skylightsQty !== null
                    ? `${this.formData.job.skylightsQty}` : '',
                ],
              },
              {
                text: [
                  this.formData.job.narrowDriveway !== null
                    ? `${this.formData.job.narrowDriveway}` : '',
                ],
              },
            ],
            margin: [0, 3, 0, 10],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            text: 'Special Instructions',
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: `${this.formData.specialInstructions !== null ? this.formData.specialInstructions : 'Not Specified'}`,
            margin: [0, 3, 0, 10],
          },
          {
            text: 'Partial Tearoff',
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: `${this.formData.partialTearoff !== null ? this.formData.partialTearoff : 'Not Specified'}`,
            margin: [0, 3, 0, 10],
          },
          {
            text: 'Tarp Flowers?',
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: `${this.formData.tarpFlowers !== null ? this.formData.tarpFlowers : 'Not Specified'}`,
            margin: [0, 3, 0, 15],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            text: [
              'Digitally signed by  ',
              {
                text: `${this.formData.signature}`,
                bold: true,
              },
              '  on  ',
              {
                text: `${this.formData.dateSigned}`,
                bold: true,
              },
            ],
            margin: [50, 15],
          },
        ],
        // eslint-disable-next-line
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.headlineLevel === 1
            && nodesOnNextPage.length > 0) {
            return true;
          }
          return false;
        },
      };
      return document;
    },
    nameFile() {
      if (this.formData.job.address) {
        this.fileName = this.formData.job.address.replace(/\./g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\ /g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\,/g, '_');
        this.fileName = `${this.fileName}.pdf`;
      } else {
        this.fileName = `${this.formData.contractor.name}.pdf`;
      }
    },
    downloadPdf() {
      this.nameFile(this.formData);
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).download(this.fileName);
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
            resolve(fr.result);
        }
        fr.readAsDataURL(file);
    });
    },
    async emailPdf() {
      this.nameFile(this.formData);
      const fn = this.fileName;
      const document = this.makePdf(this.formData);
      const file = pdfMake.createPdf(document);
      // this.email.file2 = await this.getBase64(this.formData.diagram);
      // console.log(this.getBase64(this.formData.diagram));
      if (this.formData.diagram) {
        await this.getBase64(this.formData.diagram).then((res) => {
          this.emailFormData.append('filename2', this.formData.filename2);
          this.emailFormData.append('diagram', res.split(',')[1]);
        });
      }
      await file.getBase64((data) => {
        this.emailFormData.append('filename', fn);
        this.emailFormData.append('file', data);
        this.emailFormData.set('service', 'valleyoroofremoval.com');
        this.emailFormData.set('subjectLine', `Tearoff for ${this.formData.job.address}`);
        this.emailFormData.set('from', 'valleydemo@hotmail.com');
        this.emailFormData.set('fromName', 'valleyroofremoval.com');
        const name = this.formData.contractor.name.split(' ');
        this.emailFormData.set('adminTo', 'valleydemo@hotmail.com');
        this.emailFormData.set('adminFrom', 'david@windsong.io');
        this.emailFormData.set('customerTo', this.formData.contractor.email);
        this.emailFormData.set('adminReplyTo', this.formData.contractor.email),
        this.emailFormData.set('customerReplyTo', 'valleydemo@hotmail.com'),
        this.emailFormData.set('customerMessage', `Hello ${name[0]},

          You recently requested a quote on valleyroofremoval.com.

          Project: ${this.formData.job.address}

          Attached is a copy of this request.

          We will contact you once we have reviewed the information you provided.

          Thank you,

          Valley Demo & Raingutter / Valley Roof Removal

          (916) 455-1690  |  valleydemo@hotmail.com
          \n
          `);
        this.emailFormData.set('adminMessage', `Hello,

          A new tearoff request has been received on valleyroofremoval.com.

          Project: ${this.formData.job.address}

          Attached is a copy of this request
          \n
          `);
        // if (this.formData.diagram) {
        //   this.emailFormData.append('diagram', this.formData.diagram);
        //   this.emailFormData.append('filename2', this.formData.filename2);
        // }
        this.emailSent = true;
        // Admin Email
        // this.emailFormData.set('to', 'valleydemo@hotmail.com');
        // this.emailFormData.set('name', this.formData.contractor.email);
        // this.emailFormData.set('replyTo', this.formData.contractor.email);
        this.sendFileAdmin();
        this.changesMade = false;
        this.complete = true;
      });
    },
    // sendFile() {
    //   this.$store.dispatch('sendFile', this.emailFormData);
    // },
    sendFileAdmin() {
      // console.log(this.emailFormData);
      this.$store.dispatch('sendFileAdmin', this.emailFormData);
    },
    previewPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).open();
    },
    printPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).print();
    },
  },
  validations: {
    formData: {
      building: {
        type: {
          required,
        },
        pitch: {
          required,
        },
        longestRun: {
          required,
        },
        resheet: {
          required,
        },
        bare: {
          required,
        },
        tuck: {
          required,
        },
      },
      contractor: {
        email: {
          required,
        },
        name: {
          required,
        },
        license: {
          required,
        },
        phone: {
          required,
        },
        billingStreet: {
          required,
        },
        billingCityStateZip: {
          required,
        },
      },
      dateRequested: {
        required,
      },
      homeowner: {
        name: {
          required,
        },
        phone: {
          required,
        },
      },
      job: {
        address: {
          required,
        },
        crossStreet: {
          required,
        },
        city: {
          required,
        },
        carried: {
          required,
        },
        groundDrop: {
          required,
        },
        lowTrees: {
          required,
        },
        access: {
          required,
        },
        dogs: {
          required,
        },
        narrowDriveway: {
          required,
        },
        acQty: {
          required,
        },
        skylightsQty: {
          required,
        },
      },
      details: {
        squares: {
          required,
        },
        type: {
          required,
        },
        layers: {
          required,
        },
        stories: {
          required,
        },
        pitch: {
          required,
        },
      },
      signature: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
  #header {
    color: white;
    font-size: 0.5em;
  }
  #brand {
    font-size: 4em;
    font-weight: 600;
    background: cornflowerblue;
    color: white;
    border-radius: 5px;
  }
  .headline {
    font-size: 1.5em;
    font-weight: 800;
  }
  .main-container {
    width: 100vw;
  }
  .form-container {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 2vh;
    padding: 0.5vmax;
  }
  .input-group {
    margin: 5px;
    background: white;
  }
  .full-size {
    width: 100%
  }

  .input-group-text {
    color: white;
    font-weight: 600;
    background: slategrey;
  }
  .name-input {
    width: 500px !important;
  }
  .custom-control-input {
    width: 1.5rem;
  }
  // .i-micro {
  //   width: 225px;
  // }
  // .i-small {
  //   width: 360px;
  // }
  // .i-medium {
  //   width: 450px;
  // }
  .outline-primary {
    background-color: none !important;
    background: none !important;
  }
</style>