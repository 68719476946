import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate';
// import SecureLS from 'secure-ls';
import { v4 as uuid } from 'uuid';

import { projectUrl } from '../translations';
import {
  dataGetters,
  utilityGetters,
  workDayGetters,
} from './getters';
import {
  dataMutations,
  utilityMutations,
  workdayMutations,
} from './mutations';
import {
  dataActions,
  utilityActions,
  workDayActions,
} from './actions';

// const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  plugins: [
    // createPersistedState({
    //   key: 'easyintheory.com',
    //   //     // storage: {
    //   //     //   getItem: key => ls.get(key),
    //   //     //   setItem: (key, value) => ls.set(key, value),
    //   //     //   removeItem: key => ls.remove(key),
    //   //     // },
    //   paths: [
    //     'logo', // Prevents the need to constantly re-process image data.
    //     'chords',
    //     //       // 'data.customers',
    //     //       // 'data.invoices',
    //     //       // 'dbversion',
    //     //       // 'preferences',
    //   ],
    // }),
  ],
  state: {
    account: {
      services: null,
      people: null,
      employees: null,
      employeesFields: null,
      team: null,
    },
    activeItem: 0,
    additionalActions: [],
    addNew: false,
    adminLogEntry: {
      customer: projectUrl,
    },
    autosaveNow: false,
    autosaveTriggered: false,
    changesMade: false,
    chords: {
      arpDuration: {
        value: 0.5,
        label: 'Quick',
      },
      chordDuration: { value: 2, label: '2 sec' },
      chromatic: false,
      clickIn: 1,
      descend: false,
      displayMode: 'Neck',
      flat: false,
      hideNoteNames: false,
      instrumentChosen: 'Guitar',
      keyOf: 'C',
      leftHand: false,
      letter: 'C',
      mode: 'Major',
      oscillatorType: 'triangle',
      referenceTone: 440,
      repeats: 1,
      repeatTonic: true,
      sharp: false,
      showAllNoteNames: false,
      showAllNoteNamesWhenPlaying: false,
      spanOctaves: 1,
      startingOctave: 3,
      stringsData: [],
      synthVolume: 3,
      thisTuning: null,
      tuningChosen: null,
      tuningOptions: {
        Guitar: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              -5,
              -10,
              -14,
              -19,
              -24,
              -29,
            ],
          },
          {
            _id: uuid(),
            label: 'Standard (+1 Octave)',
            tuning: [
              7,
              2,
              -2,
              -7,
              -12,
              -17,
            ],
          },
          {
            _id: uuid(),
            label: 'Drop D',
            tuning: [
              -5,
              -10,
              -14,
              -19,
              -24,
              -31,
            ],
          },
          {
            _id: uuid(),
            label: 'Step Down',
            tuning: [
              -7,
              -12,
              -16,
              -21,
              -26,
              -31,
            ],
          },
        ],
        Bass: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              -26,
              -31,
              -36,
              -41,
            ],
          },
          {
            _id: uuid(),
            label: 'Standard (+1 Octave)',
            tuning: [
              -14,
              -19,
              -24,
              -29,
            ],
          },
          {
            _id: uuid(),
            label: 'Fretless',
            tuning: [
              -26,
              -31,
              -36,
              -41,
            ],
            fretless: true,
          },
          {
            _id: uuid(),
            label: 'Fretless (+1 Octave)',
            tuning: [
              -14,
              -19,
              -24,
              -29,
            ],
            fretless: true,
          },
        ],
        Violin: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              7,
              0,
              -7,
              -14,
            ],
            fretless: true,
          },
        ],
        Ukulele: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              0,
              -5,
              -9,
              -2,
            ],
          },
        ],
        Mandolin: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              7,
              0,
              -7,
              -14,
            ],
          },
        ],
        Viola: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              0,
              -7,
              -14,
              -21,
            ],
            fretless: true,
          },
        ],
        Cello: [
          {
            _id: uuid(),
            label: 'Standard',
            tuning: [
              -12,
              -19,
              -26,
              -33,
            ],
            fretless: true,
          },
        ],
        Custom: [
          {
            _id: uuid(),
            label: 'Charango',
            tuning: [
              7,
              0,
              -5,
              3,
              -2,
            ],
          },
        ],
      },
    },
    collectionFilters: [],
    collectionFilterActive: null,
    collectionsChangedRestart: false,
    data: {
      collections: [],
      customers: [],
      customer: {},
      customerHasBeenAdded: false,
      invoices: [],
      invoice: {},
      printData: {},
      records: {},
      userId: null,
      workDays: [],
      workDayTimecards: [],
    },
    dbversion: 0,
    dbversionMismatch: false,
    deleteRequest: null,
    initialLoad: false,
    editItem: false,
    errors: [],
    errorsCount: 0,
    enterButton: false,
    escButton: false,
    fileAction: { // data posts here to trigger pdf creation
      _id: null,
      action: null,
      cmd: null,
      result: null,
      restartRequired: false,
    },
    fileUploadToDelete: null,
    filterAddressesBy: {
      address: true,
      type: null,
      label: null,
      cityState: [],
      cityCountry: [],
      state: [],
      country: [],
      postal: [],
    },
    filterStatusesBy: {
      hasStatus: false,
      hasUrgent: false,
      noStatus: false,
    },
    filterAddressesByDefault: {
      address: true,
      type: null,
      label: null,
      cityState: [],
      cityCountry: [],
      state: [],
      country: [],
      postal: [],
    },
    itemActionButtonPress: false,
    itemActionCancelPress: false,
    justViewed: {
      collection: null,
      record: null,
    },
    launchPreview: null,
    linkedRecordSaved: {
      recordId: null,
      requestId: null,
    },
    logo: {
      location: null,
      data: null,
    },
    navText: null,
    needToClosePreferences: false,
    offline: false, // offline development mode. Set by AuthenticationPortal
    preferences: {},
    refreshNeeded: false,
    restartNeeded: false,
    retry: {
      cmd: null,
      data: null,
    },
    retrySuccess: false,
    searchTermPartial: null,
    searchTermLastEntry: null,
    searchTerms: null,
    sendFileSuccess: null,
    showItem: null,
    showLoader: false,
    showSpinner: false,
    showToast: {},
    signedIn: false,
    signOut: false,
    spinnerById: null,
    user: null,
    viewItem: {
      record: null,
      collection: null,
    },
    zipLookup: {
      id: null,
      city: null,
      state: null,
      zip: null,
    },
    zipRequest: null,
  },
  getters: Object.assign(
    {},
    dataGetters,
    utilityGetters,
    workDayGetters,
  ),
  mutations: Object.assign(
    {},
    dataMutations,
    utilityMutations,
    workdayMutations,
  ),
  actions: Object.assign(
    {},
    dataActions,
    utilityActions,
    workDayActions,
  ),
});
