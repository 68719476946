<!-- eslint-disable max-len
<template>
  <b-row id="bodyPage"
      align-h="center">
    <b-col
        align="center">
      <div
          id="header"
          class="text-center"
          style="color: darkslategrey">
        <h3>
          Raingutter Order Form
        </h3>
      </div>
      <div
          id="form">
        <b-form
            id="formData"
            v-show="!complete">
          <b-container
              id="dateContainer"
              class="formContainer text-center">
            <b-form-row>
              <b-col align="center"
                  class="headlineContainer">
                <span
                    class="headline">
                  Requested Installation Date
                </span>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col align="center"
                  class="inputContainer my-3">
                <b-form-input
                    id="dateRequested"
                    type="date"
                    class="formInputSmall my-2 mx-5"
                    v-model="formData.dateRequested">
                </b-form-input>
              </b-col>
            </b-form-row>
            <div
                class="error-label"
                v-if="!$v.formData.dateRequested.required
                      && $v.formData.dateRequested.$dirty">
              Please select a date.
            </div>
          </b-container>
          <b-container
              id="contactInfo"
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Contractor Details
              </span>
            </b-container>
            <b-form-row
                class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Name
                </div>
                <b-form-input
                    id="contractorName"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.name"
                />
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.name.required
                          && $v.formData.contractor.name.$dirty">
                  Enter the contractor's name as it appears on their license.
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Contractor's License Number
                </div>
                <b-form-input
                    id="contractorLicence"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.license">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.license.required
                          && $v.formData.contractor.license.$dirty">
                  Enter the contractor's license number.
                </div>
              </b-col>
            </b-form-row>
            <b-form-row class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Phone Number
                </div>
                <b-form-input
                    id="contractorPhone"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.phone">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.phone.required
                          && $v.formData.contractor.phone.$dirty">
                  Enter your phone number (numbers only).
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Email
                </div>
                <b-form-input
                    id="contractorEmail"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.email">
                </b-form-input>
              </b-col>
            </b-form-row>
            <b-form-row class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Billing Address
                </div>
                <b-form-input
                    id="contractorBillingAddress"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.billingStreet">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingStreet.required
                          && $v.formData.contractor.billingStreet.$dirty">
                Enter your billing street address.
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  City, State, Zip
                </div>
                <b-form-input
                    id="contractorBillingCityStateZip"
                    type="text"
                    class="formInput"
                    v-model="formData.contractor.billingCityStateZip"
                />
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingCityStateZip.required
                          && $v.formData.contractor.billingCityStateZip.$dirty">
                  Enter your billing city, state, and zip code.
                </div>
              </b-col>
            </b-form-row>
          </b-container>
          <b-container
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Job Details
              </span>
            </b-container>
            <b-form-row
                class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Jobsite Address
                </div>
                <b-form-input
                    id="jobAddress"
                    type="text"
                    class="formInput"
                    v-model="formData.job.address">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.address.required
                          && $v.formData.job.address.$dirty">
                  Enter the jobsite address.
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  City
                </div>
                <b-form-input
                    id="jobCity"
                    type="text"
                    class="formInput"
                    v-model="formData.job.city">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.city.required
                          && $v.formData.job.city.$dirty">
                  Enter the city.
                </div>
              </b-col>
            </b-form-row>
            <b-form-row class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Homeowner's Name
                </div>
                <b-form-input
                    id="homeownerName"
                    type="text"
                    class="formInput"
                    v-model="formData.homeowner.name">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.name.required
                          && $v.formData.homeowner.name.$dirty">
                  Enter the name of the homeowner.
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Homeowner's Phone Number
                </div>
                <b-form-input
                    id="homeownerPhone"
                    type="text"
                    class="formInput"
                    v-model="formData.homeowner.phone">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.required
                          && $v.formData.homeowner.$dirty">
                  Enter the homeowner's phone number.
                </div>
              </b-col>
            </b-form-row>
            <b-form-row class="my-3">
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Power Available?
                </div>
                <b-form-radio-group
                    id="jobPower"
                    buttons
                    class="formInput"
                    v-model="formData.job.power">
                  <b-form-radio
                      class="mx-1"
                      value="Power is available">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="No power available">
                    No
                  </b-form-radio>
                </b-form-radio-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.power.required
                          && $v.formData.job.power.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col align="center"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Dogs?
                </div>
                <b-form-input
                    id="jobDogs"
                    type="text"
                    class="formInputSmall"
                    v-model="formData.job.dogs">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.dogs.required
                          && $v.formData.job.dogs.$dirty">
                  Select a response.
                </div>
              </b-col>
            </b-form-row>
          </b-container>
          <b-container
              id="diagram"
              class="formContainer mt-4">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Upload a Diagram
              </span>
              <br />
              <span
                  class="form-label">
                Please provide a brief sketch or diagram showing the layout of the house and mark areas for gutters and downspouts.
                <br />
                <i>
                  If no diagram is included, gutters and downspouts will be installed in pre-existing locations or at installer's discretion.
                </i>
              </span>
              <b-row
                  h-align="center">
                <b-col
                    align="center"
                    class="p-2 image-upload__container">
                  <image-modal
                      v-if="imageLoader"
                      @onConfirm="useImage"
                      @onCancel="imageLoader=false"
                      @uploadFail="imageError"
                  />
                  <b-btn
                      v-if="formData.diagram === null"
                      @click="startLoader"
                      v-text="$t('addImage')"
                      variant="success"
                      class="pr-2"
                      v-show="!imageLoader"
                      style="border: 1px solid lightgrey"
                  />
                  <b-btn
                      v-if="formData.diagram !== null"
                      @click="clearImage(formData.diagram)"
                      v-text="$t('deleteImage')"
                      variant="warning"
                      class="pr-2"
                      v-show="!imageLoader"
                      style="border: 1px solid lightgrey"
                  />
                </b-col>
              </b-row>
              <b-row
                  h-align="center">
                <b-col
                    align="center">
                  <span
                    style="font-size: 1.2rem; font-weight: bold; color: yellow"
                    v-text="errorMsg"
                  />
                  <b-img-lazy
                      fluid
                      :src="formData.diagram"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-container>
          <b-container
              class="formContainer">
            <b-container
              class="headlineContainer">
              <span
                  class="headline">
                Gutters
              </span>
            </b-container>
            <div
                class="headlineMini text-center">
              Please Indicate Footage
            </div>
            <b-row
              align-h="center" class="my-3 mx-1">
              <b-col align="center"
                  cols="4"
                  class="inputContainer my-3">
                <b-input-group
                    prepend='5"'
                    class="formInputGroup">
                  <b-form-input
                      id="guttersFive"
                      class="formInputSmall ml-0"
                      type="text"
                      v-model="formData.details.gutters.five">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer my-3">
                <b-input-group
                  prepend='7"'
                  class="formInputGroup">
                  <b-form-input
                      id="guttersSeven"
                      class="formInputSmall ml-0"
                      type="text"
                      v-model="formData.details.gutters.seven">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer my-3">
                <b-input-group
                    prepend='OGEE 5"'
                    class="formInputGroup">
                  <b-form-input
                    id="guttersOG"
                    class="formInputSmall ml-0"
                    type="text"
                    v-model="formData.details.gutters.og">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col align="center"
                  cols="6"
                  class="inputContainer my-3">
                <b-input-group
                    prepend="Gutter Screens"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen"
                      class="formInputSmall ml-0"
                      type="text"
                      v-model="formData.details.gutters.screen">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col align="center"
                  cols="6"
                  class="inputContainer my-3">
                <b-input-group
                    prepend="Brand"
                    class="formInputGroup">
                  <b-form-select
                      id="gutterScreenBrand"
                      class="formInputSmall ml-0"
                      v-model="formData.details.gutters.screenBrand"
                      :options="gutterScreenBrandOptions"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  cols="auto"
                  class="inputContainer my-3">
                <div
                    class="headlineMini text-left ml-4">
                  Color
                </div>
                <b-input-group
                    class="px-2">
                  <b-form-input
                      id="guttersColor"
                      type="text"
                      class="formInput mr-2"
                      v-model="formData.details.gutters.color">
                  </b-form-input>
                  <b-input-group-append
                      class="mr-3">
                    <b-btn
                        id="showColorChoices"
                        @click="showColorChoices()"
                        variant="info">
                      <span
                          v-if="!showColorsGutter">
                        Show Color Choices
                      </span>
                      <span
                          v-if="showColorsGutter">
                        Hide Color Choices
                      </span>
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.details.gutters.required
                          && $v.formData.details.gutters.$dirty">
                  Enter a color.
                </div>
              </b-col>
            </b-row>
            <transition
                name="fade"
                mode="out-in">
              <b-container
                  v-if="showColorsGutter">
                <b-row id="colorOptions"
                  align-h="center"
                  class="p-3">
                    <b-col align="center"
                        id="GutterColor02"
                        @click="updateColorGutter('02 - 50 White')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/02.jpg"
                          class="colorSample"
                          alt="50 White"
                          fluid
                      />
                      <br />
                        White
                    </b-col>
                    <b-col align="center"
                        id="GutterColor04"
                        @click="updateColorGutter('04 - Musket Brown')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/04.jpg"
                          class="colorSample"
                          alt="Musket Brown"
                          fluid
                      />
                      <br />
                        Musket Brown
                    </b-col>
                    <b-col align="center"
                        id="GutterColor06"
                        @click="updateColorGutter('06 - Old Town Gray')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/06.jpg"
                          class="colorSample"
                          alt="Old Town Gray"
                          fluid
                      />
                      <br />
                        Old Town Gray
                    </b-col>
                    <b-col align="center"
                        id="GutterColor08"
                        @click="updateColorGutter('08 - GS Blue')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/08.jpg"
                          class="colorSample"
                          alt="GS Blue"
                          fluid
                      />
                      <br />
                        GS Blue
                    </b-col>
                    <b-col align="center"
                        id="GutterColor10"
                        @click="updateColorGutter('10 - Almond')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/10.jpg"
                          class="colorSample"
                          alt="Almond"
                          fluid
                      />
                      <br />
                        Almond
                    </b-col>
                    <b-col align="center"
                        id="GutterColor05"
                        @click="updateColorGutter('05 - Royal Brown')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/05.jpg"
                          class="colorSample"
                          alt="Royal Brown"
                          fluid
                      />
                      <br />
                        Royal Brown
                    </b-col>
                    <b-col align="center"
                        id="GutterColor07"
                        @click="updateColorGutter('07 - Storm Gray')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/07.jpg"
                          class="colorSample"
                          alt="Storm Gray"
                          fluid
                      />
                      <br />
                        Storm Gray
                    </b-col>
                    <b-col align="center"
                        id="GutterColor09"
                        @click="updateColorGutter('09 - Tahoe Blue')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/09.jpg"
                          class="colorSample"
                          alt="Tahoe Blue"
                          fluid
                      />
                      <br />
                        Tahoe Blue
                    </b-col>
                    <b-col align="center"
                        id="GutterColor11"
                        @click="updateColorGutter('11 - Ivory')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/11.jpg"
                          class="colorSample"
                          alt="Ivory"
                          fluid
                      />
                      <br />
                        Ivory
                    </b-col>
                    <b-col align="center"
                        id="GutterColor12"
                        @click="updateColorGutter('12 - Raffia Beige')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/12.jpg"
                          class="colorSample"
                          alt="Raffia Beige"
                          fluid
                      />
                      <br />
                        Raffia Beige
                    </b-col>
                    <b-col align="center"
                        id="GutterColor14"
                        @click="updateColorGutter('14 - Leaf Green')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/14.jpg"
                          class="colorSample"
                          alt="Leaf Green"
                          fluid
                      />
                      <br />
                        Leaf Green
                    </b-col>
                    <b-col align="center"
                        id="GutterColor16"
                        @click="updateColorGutter('16 - Marine Green')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/16.jpg"
                          class="colorSample"
                          alt="Marine Green"
                          fluid
                      />
                      <br />
                        Marine Green
                    </b-col>
                    <b-col align="center"
                        id="GutterColor18"
                        @click="updateColorGutter('18 - Colonial Red')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/18.jpg"
                          class="colorSample"
                          alt="Colonial Red"
                          fluid
                      />
                      <br />
                        Colonial Red
                    </b-col>
                    <b-col align="center"
                        id="GutterColor46"
                        @click="updateColorGutter('46 - Colonial Gray')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/46.jpg"
                          class="colorSample"
                          alt="Colonial Grey"
                          fluid
                      />
                      <br />
                        Colonial Gray
                    </b-col>
                    <b-col align="center"
                        id="GutterColor15"
                        @click="updateColorGutter('15 - Forest Green')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/15.jpg"
                          class="colorSample"
                          alt="Forest Green"
                          fluid
                      />
                      <br />
                        Forest Green
                    </b-col>
                    <b-col align="center"
                        id="GutterColor17"
                        @click="updateColorGutter('17 - Spanish Green')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/14.jpg"
                          class="colorSample"
                          alt="Spanish Green"
                          fluid
                      />
                      <br />
                        Spanish Green
                    </b-col>
                    <b-col align="center"
                        id="GutterColor19"
                        @click="updateColorGutter('19 - Black')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/19.jpg"
                          class="colorSample"
                          alt="Black"
                          fluid
                      />
                      <br />
                        Black
                    </b-col>
                    <b-col align="center"
                        id="GutterColor41"
                        @click="updateColorGutter('41 - Buckskin Brown')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/41.jpg"
                          class="colorSample"
                          alt="Buckskin Brown"
                          fluid
                      />
                      <br />
                        Buckskin Brown
                    </b-col>
                    <b-col align="center"
                        id="GutterColor40"
                        @click="updateColorGutter('40 - Beaver Brown')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/40.jpg"
                          class="colorSample"
                          alt="Beaver Brown"
                          fluid
                      />
                      <br />
                        Beaver Brown
                    </b-col>
                    <b-col align="center"
                        id="GutterColor42"
                        @click="updateColorGutter('42 - Pebblestone Clay')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/42.jpg"
                          class="colorSample"
                          alt="Pebblestone Clay"
                          fluid
                      />
                      <br />
                        Pebblestone Clay
                    </b-col>
                    <b-col align="center"
                        id="GutterColor44"
                        @click="updateColorGutter('44 - Classic Cream')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/44.jpg"
                          class="colorSample"
                          alt="Classic Cream"
                          fluid
                      />
                      <br />
                        Classic Cream
                    </b-col>
                    <b-col align="center"
                        id="GutterColor48"
                        @click="updateColorGutter('48 - Woodbeige')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/48.jpg"
                          class="colorSample"
                          alt="Woodbeige"
                          fluid
                      />
                      <br />
                        Woodbeige
                    </b-col>
                    <b-col align="center"
                        id="GutterColor49"
                        @click="updateColorGutter('49 - Bronze')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/49.jpg"
                          class="colorSample"
                          alt="Bronze"
                          fluid
                      />
                      <br />
                        Bronze
                    </b-col>
                    <b-col align="center"
                        id="GutterColor43"
                        @click="updateColorGutter('43 - Adobe Tan')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/43.jpg"
                          class="colorSample"
                          alt="Adobe Tan"
                          fluid
                      />
                      <br />
                        Adobe Tan
                    </b-col>
                    <b-col align="center"
                        id="GutterColor45"
                        @click="updateColorGutter('45 - Heritage Cream')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/45.jpg"
                          class="colorSample"
                          alt="Heritage Cream"
                          fluid
                      />
                      <br />
                        Heritage Cream
                    </b-col>
                    <b-col align="center"
                        id="GutterColor35"
                        @click="updateColorGutter('35 - Weathered Bronze')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/35.jpg"
                          class="colorSample"
                          alt="Weathered Bronze"
                          fluid
                      />
                      <br />
                        Weathered Bronze
                    </b-col>
                    <b-col align="center"
                        id="GutterColor47"
                        @click="updateColorGutter('47 - Sea Blue')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/47.jpg"
                          class="colorSample"
                          alt="Sea Blue"
                          fluid
                      />
                      <br />
                        Sea Blue
                    </b-col>
                    <b-col align="center"
                        id="GutterColor50"
                        @click="updateColorGutter('50 - Peach')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/50.jpg"
                          class="colorSample"
                          alt="Peach"
                          fluid
                      />
                      <br />
                        Peach
                    </b-col>
                    <b-col align="center"
                        id="GutterColor51"
                        @click="updateColorGutter('51 - Linen')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/51.jpg"
                          class="colorSample"
                          alt="Linen"
                          fluid
                      />
                      <br />
                        Linen
                    </b-col>
                    <b-col align="center"
                        id="GutterColor56"
                        @click="updateColorGutter('56 - Eggshell')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/56.jpg"
                          class="colorSample"
                          alt="Eggshell (Steel Only)"
                          fluid
                      />
                      <br />
                        Eggshell (Steel Only)
                    </b-col>
                    <b-col align="center"
                        id="  "
                        @click="updateColorGutter('55 - Arch Bronze')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/55.jpg"
                          class="colorSample"
                          alt="Arch Bronze (Steel Only)"
                          fluid
                      />
                      <br />
                        Arch Bronze (Steel Only)
                    </b-col>
                    <b-col align="center"
                        id="GutterColor52"
                        @click="updateColorGutter('52 - Champagne')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/52.jpg"
                          class="colorSample"
                          alt="Champagne"
                          fluid
                      />
                      <br />
                        Champagne
                    </b-col>
                    <b-col align="center"
                        id="GutterColor54"
                        @click="updateColorGutter('54 - Satin')"
                        class="colorPanel">
                      <b-img-lazy
                          src="@/assets/colors/54.jpg"
                          class="colorSample"
                          alt="Satin"
                          fluid
                      />
                      <br />
                        Satin
                    </b-col>
                </b-row>
                <i class="pt-3">
                  Note: Colors shown are based on a printer's representation. Variations may exist.
                  <br />
                  Before making final color choices, please ask for a metal color chip sample.
                </i>
              </b-container>
            </transition>
          </b-container>
          <b-container
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Downspouts
              </span>
            </b-container>
            <div
                class="headlineMini text-center">
              Number of Downspouts
            </div>
            <b-form-row
                align-h="center"
                style="text-align: center">
              <b-col align="center"
                  class="inputContainer mt-0">
              <b-input-group
                  prepend="1st Story"
                  class="formInputGroup">
                <b-form-input
                    id="guttersScreen"
                    type="number"
                    class="formInputSmall ml-0"
                    v-model="formData.details.downspouts.first">
                </b-form-input>
              </b-input-group>
              </b-col>
              <b-col align="center"
                  class="inputContainer mt-0">
                <b-input-group
                    prepend="2nd Story"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen"
                      type="number"
                      class="formInputSmall ml-0"
                      v-model="formData.details.downspouts.second">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col align="center"
                  class="inputContainer mt-0">
                <b-input-group
                    prepend="3rd Story"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen"
                      type="number"
                      class="formInputSmall ml-0"
                      v-model="formData.details.downspouts.third">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-form-row>
            <b-form-row
                align-h="center">
              <b-col align="center"
                  cols="auto"
                  class="inputContainer my-3">
                <div
                    class="headlineMini text-left ml-4">
                  Color
                </div>
                <b-input-group
                    class="px-2">
                  <b-form-input
                      id="downspoutsColor"
                      type="text"
                      class="formInput mr-2"
                      v-model="formData.details.downspouts.color">
                  </b-form-input>
                  <b-input-group-append
                      class="mr-3">
                    <b-btn
                        id="showColorChoices"
                        @click="showColorChoicesDownspout()"
                        variant="info">
                  <span
                      v-if="!showColorsDownspout">
                    Show Color Choices
                  </span>
                  <span
                      v-if="showColorsDownspout">
                    Hide Color Choices
                  </span>
                </b-btn>
                  </b-input-group-append>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.details.downspouts.color.required
                          && $v.formData.details.downspouts.color.$dirty">
                  Enter a color.
                </div>
              </b-col>
            </b-form-row>
            <transition
                name="fade"
                mode="out-in">
              <b-container
                  v-if="showColorsDownspout">
                <b-row
                      align-h="center"
                      id="colorOptions"
                      class="inputContainer">
                      <b-col align="center"
                          id="DownspoutColor02"
                          @click="updateColorDownspout('02 - 50 White')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/02.jpg"
                            class="colorSample"
                            alt="White"
                            fluid
                        />
                        <br />
                          White
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor04"
                          @click="updateColorDownspout('04 - Musket Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/04.jpg"
                            class="colorSample"
                            alt="Musket Brown"
                            fluid
                        />
                        <br />
                          Musket Brown
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor06"
                          @click="updateColorDownspout('06 - Old Town Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/06.jpg"
                            class="colorSample"
                            alt="Old Town Gray"
                            fluid
                        />
                        <br />
                          Old Town Gray
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor08"
                          @click="updateColorDownspout('08 - GS Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/08.jpg"
                            class="colorSample"
                            alt="GS Blue"
                            fluid
                        />
                        <br />
                          GS Blue
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor10"
                          @click="updateColorDownspout('10 - Almond')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/10.jpg"
                            class="colorSample"
                            alt="Almond"
                            fluid
                        />
                        <br />
                          Almond
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor05"
                          @click="updateColorDownspout('05 - Royal Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/05.jpg"
                            class="colorSample"
                            alt="Royal Brown"
                            fluid
                        />
                        <br />
                          Royal Brown
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor07"
                          @click="updateColorDownspout('07 - Storm Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/07.jpg"
                            class="colorSample"
                            alt="Storm Gray"
                            fluid
                        />
                        <br />
                          Storm Gray
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor09"
                          @click="updateColorDownspout('09 - Tahoe Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/09.jpg"
                            class="colorSample"
                            alt="Tahoe Blue"
                            fluid
                        />
                        <br />
                          Tahoe Blue
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor11"
                          @click="updateColorDownspout('11 - Ivory')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/11.jpg"
                            class="colorSample"
                            alt="Ivory"
                            fluid
                        />
                        <br />
                          Ivory
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor12"
                          @click="updateColorDownspout('12 - Raffia Beige')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/12.jpg"
                            class="colorSample"
                            alt="Raffia Beige"
                            fluid
                        />
                        <br />
                          Raffia Beige
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor14"
                          @click="updateColorDownspout('14 - Leaf Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/14.jpg"
                            class="colorSample"
                            alt="Leaf Green"
                            fluid
                        />
                        <br />
                          Leaf Green
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor16"
                          @click="updateColorDownspout('16 - Marine Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/16.jpg"
                            class="colorSample"
                            alt="Marine Green"
                            fluid
                        />
                        <br />
                          Marine Green
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor18"
                          @click="updateColorDownspout('18 - Colonial Red')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/18.jpg"
                            class="colorSample"
                            alt="Colonial Red"
                            fluid
                        />
                        <br />
                          Colonial Red
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor46"
                          @click="updateColorDownspout('46 - Colonial Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/46.jpg"
                            class="colorSample"
                            alt="Colonial Grey"
                            fluid
                        />
                        <br />
                          Colonial Gray
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor15"
                          @click="updateColorDownspout('15 - Forest Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/15.jpg"
                            class="colorSample"
                            alt="Forest Green"
                            fluid
                        />
                        <br />
                          Forest Green
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor17"
                          @click="updateColorDownspout('17 - Spanish Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/14.jpg"
                            class="colorSample"
                            alt="Spanish Green"
                            fluid
                        />
                        <br />
                          Spanish Green
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor19"
                          @click="updateColorDownspout('19 - Black')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/19.jpg"
                            class="colorSample"
                            alt="Black"
                            fluid
                        />
                        <br />
                          Black
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor41"
                          @click="updateColorDownspout('41 - Buckskin Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/41.jpg"
                            class="colorSample"
                            alt="Buckskin Brown"
                            fluid
                        />
                        <br />
                          Buckskin Brown
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor40"
                          @click="updateColorDownspout('40 - Beaver Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/40.jpg"
                            class="colorSample"
                            alt="Beaver Brown"
                            fluid
                        />
                        <br />
                          Beaver Brown
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor42"
                          @click="updateColorDownspout('42 - Pebblestone Clay')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/42.jpg"
                            class="colorSample"
                            alt="Pebblestone Clay"
                            fluid
                        />
                        <br />
                          Pebblestone Clay
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor44"
                          @click="updateColorDownspout('44 - Classic Cream')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/44.jpg"
                            class="colorSample"
                            alt="Classic Cream"
                            fluid
                        />
                        <br />
                          Classic Cream
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor48"
                          @click="updateColorDownspout('48 - Woodbeige')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/48.jpg"
                            class="colorSample"
                            alt="Woodbeige"
                            fluid
                        />
                        <br />
                          Woodbeige
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor49"
                          @click="updateColorDownspout('49 - Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/49.jpg"
                            class="colorSample"
                            alt="Bronze"
                            fluid
                        />
                        <br />
                          Bronze
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor43"
                          @click="updateColorDownspout('43 - Adobe Tan')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/43.jpg"
                            class="colorSample"
                            alt="Adobe Tan"
                            fluid
                        />
                        <br />
                          Adobe Tan
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor45"
                          @click="updateColorDownspout('45 - Heritage Cream')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/45.jpg"
                            class="colorSample"
                            alt="Heritage Cream"
                            fluid
                        />
                        <br />
                          Heritage Cream
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor35"
                          @click="updateColorDownspout('35 - Weathered Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/35.jpg"
                            class="colorSample"
                            alt="Weathered Bronze"
                            fluid
                        />
                        <br />
                          Weathered Bronze
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor47"
                          @click="updateColorDownspout('47 - Sea Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/47.jpg"
                            class="colorSample"
                            alt="Sea Blue"
                            fluid
                        />
                        <br />
                          Sea Blue
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor50"
                          @click="updateColorDownspout('50 - Peach')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/50.jpg"
                            class="colorSample"
                            alt="Peach"
                            fluid
                        />
                        <br />
                          Peach
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor51"
                          @click="updateColorDownspout('51 - Linen')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/51.jpg"
                            class="colorSample"
                            alt="Linen"
                            fluid
                        />
                        <br />
                          Linen
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor56"
                          @click="updateColorDownspout('56 - Eggshell')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/56.jpg"
                            class="colorSample"
                            alt="Eggshell (Steel Only)"
                            fluid
                        />
                        <br />
                          Eggshell (Steel Only)
                      </b-col>
                      <b-col align="center"
                          id="  "
                          @click="updateColorDownspout('55 - Arch Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/55.jpg"
                            class="colorSample"
                            alt="Arch Bronze (Steel Only)"
                            fluid
                        />
                        <br />
                          Arch Bronze (Steel Only)
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor52"
                          @click="updateColorDownspout('52 - Champagne')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/52.jpg"
                            class="colorSample"
                            alt="Champagne"
                            fluid
                        />
                        <br />
                          Champagne
                      </b-col>
                      <b-col align="center"
                          id="DownspoutColor54"
                          @click="updateColorDownspout('54 - Satin')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/54.jpg"
                            class="colorSample"
                            alt="Satin"
                            fluid
                        />
                        <br />
                          Satin
                      </b-col>
                </b-row>
                <i v-show="showColorsGutter" class="pt-3">
                  Note: Colors shown are based on a printer's representation. Variations may exist.
                  <br />
                  Before making final color choices, please ask for a metal color chip sample.
                </i>
              </b-container>
            </transition>
          </b-container>
          <b-container
              id="roofInfo"
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Details & Options
              </span>
            </b-container>
            <b-row
                align-h="center"
                class="my-2 mx-1">
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Type
                </div>
                <b-form-radio-group
                    buttons
                    id="buildingType"
                    class="formInput"
                    v-model="formData.building.type">
                  <b-form-radio
                      class="mx-1"
                      value="Existing Gutters">
                    Existing Gutters
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="New Install">
                    New Install
                  </b-form-radio>
                </b-form-radio-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.type.required
                          && $v.formData.building.type.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Remove Existing Gutters?
                </div>
                <b-form-radio-group
                    id="buildingRemoveExisting"
                    buttons
                    class="formInput"
                    v-model="formData.building.removeExisting"
                    :disabled="formData.building.type === 'Existing Gutters' ? false : true">
                  <b-form-radio
                      class="mx-1"
                      value="Remove Existing">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="Keep Existing">
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Reuse Downspouts?
                </div>
                <b-form-radio-group
                    id="buildingReuseDownspouts"
                    buttons
                    class="formInput"
                    v-model="formData.building.reuseDownspout"
                    :disabled="formData.building.type === 'Existing Gutters' ? false : true">
                  <b-form-radio
                      class="mx-1"
                      value="Reuse Downspouts">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="Don't Reuse Downspouts">
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Roof Pitch
                </div>
                <b-form-input
                    id="buildingPitch"
                    type="text"
                    class="formInputSmall"
                    v-model="formData.building.pitch">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.pitch.required
                          && $v.formData.building.pitch.$dirty">
                  Enter the pitch of the roof.
                </div>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Longest Run
                </div>
                <b-form-input
                    id="buildingLongestRun"
                    type="text"
                    class="formInputSmall"
                    v-model="formData.building.longestRun">
                </b-form-input>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.longestRun.required
                          && $v.formData.building.longestRun.$dirty">
                  Enter a response.
                </div>
              </b-col>
              <b-col align="center"
                  cols="4"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Resheeting?
                </div>
                <b-form-radio-group
                    id="resheetingOptions"
                    buttons
                    class="formInput"
                    v-model="formData.building.resheet">
                  <b-form-radio
                      class="mx-1"
                      value="Resheeting">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="No Resheeting">
                    No
                  </b-form-radio>
                </b-form-radio-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.resheet.required
                          && $v.formData.building.resheet.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col align="center"
                  cols="6"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Bare
                </div>
                <b-form-radio-group
                    id="buildingBare"
                    buttons
                    class="formInput"
                    v-model="formData.building.bare">
                  <b-form-radio
                      class="mx-1"
                      value="Bare">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="Not Bare">
                    No
                  </b-form-radio>
                </b-form-radio-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.bare.required
                          && $v.formData.building.bare.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col align="center"
                  cols="6"
                  class="inputContainer">
                <div
                    class="form-labels">
                  Tuck
                </div>
                <b-form-radio-group
                    id="buildingTuck"
                    buttons
                    class="formInput"
                    v-model="formData.building.tuck">
                  <b-form-radio
                      class="mx-1"
                      value="Tuck">
                    Yes
                  </b-form-radio>
                  <b-form-radio
                      class="mx-1"
                      value="Don't Tuck">
                    No
                  </b-form-radio>
                </b-form-radio-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.building.tuck.required
                          && $v.formData.building.tuck.$dirty">
                Select a response.
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-container id="specialInstructions"
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Special Instructions
              </span>
            </b-container>
            <b-form-row>
              <b-col
                  class="inputContainer px-5 pt-2"
                  align="center">
                <b-textarea id="specialInstructionsInput"
                    class="textarea"
                    v-model="formData.specialInstructions"
                    :rows="4"
                    :max-rows="8"
                />
              </b-col>
            </b-form-row>
          </b-container>
          <b-container
              id="signatureBox"
              class="formContainer">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Sign
              </span>
            </b-container>
            <b-form-row>
              <b-col
                  align="center"
                  class="inputContainer">
                <span
                    class="form-labels py-3">
                  By typing your name into this field, you are signing this order form and contract electronically.
                </span>
                <b-form-input
                    id="signature"
                    type="text"
                    class="formInput my-4"
                    placeholder="Enter your name..."
                    v-model="formData.signature">
                </b-form-input>
                <span
                    class="form-labels">
                  Sign this to designate that you understand and agree with all stipulations as outlined in our terms and conditions, which can be viewed <a href="http://valleyroofremoval.com/terms/" _target="blank" style="color: goldenrod">here</a>, and that the roof is ready for gutter installation.
                </span>
              </b-col>
            </b-form-row>
            <div
                class="error-label"
                v-if="!$v.formData.signature.required
                      && $v.formData.signature.$dirty">
            You must enter a digital signature.
            </div>
            <b-container
                align="center"
                class="mt-2">
              <b-btn
                @click="submitThis(formData)"
                variant="primary"
                size="lg"
                :disabled="$v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalid">
                Submit
              </b-btn>
              <p />
              <span v-show="$v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalids">
                Before submitting, please enter the following information:
                <br />
              </span>
              <b style="font-size: 1.2em;"
                  v-show="$v.formData.contractor.name.$invalid">
                • Contractor's Name
                <br />
              </b>
              <b style="font-size: 1.2em;"
                  v-show="$v.formData.contractor.email.$invalid">
                • Contractor's Email Address
                <br />
              </b>
              <b style="font-size: 1.2em;"
                  v-show="$v.formData.job.address.$invalid">
                • Project Address
                <br />
              </b>
              <b style="font-size: 1.2em;"
                  v-show="$v.formData.signature.$invalid">
                • Please sign the form
                <br />
              </b>
            </b-container>
          </b-container>
        </b-form>
      </div>
      <div id="thankYou"
          v-show="complete">
        <b-container id="thankYouContainer"
            class="formContainer text-center">
          <b-form-row>
            <b-col align="center"
                class="headlineContainer">
              <span
                  class="headline">
                Thank you
              </span>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col
                align="center"
                v-if="formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request or we can email it to {{formData.contractor.email}}.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mr-2 ml-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                    <b-btn id="emailReceipt"
                        variant="primary"
                        class="ml-2 mr-auto"
                        @click="emailPdf('customer')"
                        :disabled="emailSent ? true : false">
                      {{emailSent === false ? 'Email' : 'Email Sent'}}
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
            <b-col
                align="center"
                v-if="!formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request by clicking below.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mx-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// import ImageModal from '@/components/data/ImageUpload';
import { s3 } from '@/store/aws';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default {
  name: 'GuttersForm',
  mixins: [
    validationMixin,
  ],
  components: {
    // ImageModal,
  },
  title() {
    return `Gutter Order Form | ${this.$t('project.brand')}`;
  },
  data() {
    return {
      active: 0,
      changesMade: false,
      errorMsg: null,
      complete: false,
      emailSent: false,
      message: '',
      fileName: null,
      showColorsGutter: false,
      showColorsDownspout: false,
      imageLoader: false,
      gutterScreenBrandOptions: [
        'E-Z-Lock',
        'LeafBlaster',
      ],
      formData: {
        building: {
          type: null,
          removeExisting: null,
          pitch: null,
          longestRun: null,
          resheet: null,
          bare: null,
          tuck: null,
          reuseDownspout: null,
        },
        diagram: null,
        contractor: {
          name: null,
          license: null,
          phone: null,
          billingStreet: null,
          billingCityStateZip: null,
          email: null,
        },
        dateRequested: null,
        homeowner: {
          name: null,
          phone: null,
        },
        job: {
          address: null,
          city: null,
          power: null,
          dogs: null,
        },
        details: {
          gutters: {
            color: null,
            five: null,
            seven: null,
            og: null,
            screen: null,
            screenBrand: null,
          },
          downspouts: {
            color: null,
            first: null,
            second: null,
            third: null,
          },
        },
        signature: null,
        specialInstructions: null,
        dateSigned: moment().format('Do MMMM YYYY'),
      },
      email: {
        to: null,
        file: null,
        file2: null,
        fileName: null,
        subjectLine: 'Gutter Installation Request',
        message: null,
        from: 'valleydemo@hotmail.com',
        replyTo: null,
        serviceName: 'valleyroofremoval.com',
      },
    };
  },
  computed: {
    readyToCleanup() {
      return this.$store.state.cleanup;
    },
  },
  created() {
    // Catch reloads/page closures
    const that = this;
    window.addEventListener('beforeunload', (e) => {
      if (that.changesMade) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.changesMade) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(this.$t('unsavedChangesConfirm'));
      if (answer) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    formData: {
      handler() {
        if (this.changesMade === false) {
          this.changesMade = true;
        }
      },
      deep: true,
    },
    readyToCleanup(newVal) {
      if (newVal) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
      }
    },
  },
  methods: {
    leavingPage() {
      console.log('leavin');
      // eslint-disable-next-line no-alert
      window.confirm(this.$t('unsavedChangesConfirm'));
    },
    takeMe(cmd) {
      if (cmd === 'back' && this.active !== 0) this.active -= 1;
      else this.active += 1;
    },
    imageError() {
      this.errorMsg = this.$t('upload.ErrorOversize');
      this.imageLoader = false;
      this.formData.diagram = null;
    },
    updateColorGutter(colorKey) {
      this.formData.details.gutters.color = colorKey;
      this.showColorsGutter = false;
    },
    updateColorDownspout(colorKey) {
      this.formData.details.downspouts.color = colorKey;
      this.showColorsDownspout = false;
    },
    clearImage(src) {
      this.deleteImage(src);
      this.formData.diagram = null;
    },
    cleanPhone(numberString) {
      // eslint-disable-next-line prefer-template
      const cleaned = ('' + numberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },
    cancelUpload() {
      this.imageLoader = false;
    },
    useImage(data) {
      console.log('data here', data);
      const img = {
        src: data.data.src,
        title: '',
        size: 'lg',
      };
      this.formData.diagram = img.src;
      this.imageLoader = false;
    },
    deleteImage(url) {
      const key = url.substring(url.lastIndexOf('/') + 1);
      s3.deleteObject({ Key: key }, (err, data) => {
        if (err) {
          this.message = `There was an error deleting your photo ${err.message}`;
        }
        this.message = 'Image deleted';
        this.interval = function run() {
          this.message = null;
        }.bind((this), 3000);
        console.log(this.message);
        return console.log('Success:', data);
      });
    },
    showColorChoices() {
      if (this.showColorsGutter) this.showColorsGutter = false;
      else this.showColorsGutter = true;
    },
    showColorChoicesDownspout() {
      if (this.showColorsDownspout) this.showColorsDownspout = false;
      else this.showColorsDownspout = true;
    },
    startLoader() {
      this.imageLoader = true;
      this.errorMsg = null;
    },
    async submitThis() {
      this.complete = true;
      await this.emailPdf('client')
        .then(this.changesMade = false);
    },
    makePdf() {
      let projectName = '';
      if (this.formData.job.address) {
        projectName = this.formData.job.address.replace(/[, ]+/g, ' ');
      } else {
        projectName = this.formData.contractor.name + this.formData.dateRequested;
      }
      const document = {
        pageMargins: [30, 85, 30, 30],
        header: [
          {
            text: 'VALLEY DEMO & RAINGUTTER',
            fontSize: 15,
            alignment: 'center',
            margin: [0, 20, 0, 0],
          },
          {
            text: '8996 Fruitridge Rd, Bldg 5, Sacramento, CA 95826',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 5],
          },
          {
            text: 'Phone: (916) 455-1690 Fax: (916)452-3571',
            fontSize: 10,
            alignment: 'center',
          },
          {
            text: 'Email: valleydemo@hotmail.com',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 40],
          },
        ],
        footer(currentPage, pageCount) {
          if (pageCount > 1) {
            return [{
              text: `Order form for ${projectName} | Page ${currentPage.toString()} of ${pageCount}`,
              alignment: 'center',
            }];
          }
          return {};
        },
        content: [
          {
            text: [
              {
                text: 'Requested Installation Date:   ',
                bold: true,
              },
              {
                text: moment(this.formData.dateRequested).format('l'),
                bold: true,
                fontSize: 13,
              },
            ],
            margin: [0, 15, 0, 10],
            alignment: 'right',
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor:  ',
                    bold: true,
                  },
                  `${this.formData.contractor.name}`,
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  this.formData.contractor.phone ? `${this.cleanPhone(this.formData.contractor.phone)}` : '',
                ],
                width: 'auto',
              },
            ],
          },
          {
            text: [
              {
                text: 'Job Location:  ',
                bold: true,
              },
              `${this.formData.job.address}, ${this.formData.job.city ? this.formData.job.city : ''}`,
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Homeowner\'s Name:  ',
                    bold: true,
                  },
                  this.formData.homeowner.name ? `${this.formData.homeowner.name}` : '',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  this.formData.homeowner.phone ? `${this.cleanPhone(this.formData.homeowner.phone)}` : '',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor\'s E-mail:  ',
                    bold: true,
                  },
                  `${this.formData.contractor.email}`,
                ],
              },
              {
                text: [
                  {
                    text: 'Contractor\'s License:  ',
                    bold: true,
                  },
                  this.formData.contractor.license ? `${this.formData.contractor.license}` : 'Not provided',
                ],
              },
            ],
            margin: [0, 5],
          },
          {
            text: [
              {
                text: 'Contractor\'s Billing Address:  ',
                bold: true,
              },
              `${this.formData.contractor.billingStreet ? this.formData.contractor.billingStreet : ''}, ${this.formData.contractor.billingCityStateZip ? this.formData.contractor.billingCityStateZip : ''}`,
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          this.formData.diagram !== null ? {
            text: [
              {
                text: 'Diagram:  ',
                bold: true,
              },
              {
                text: 'Diagram attached',
                decoration: 'underline',
              },
            ],
            margin: [0, 5, 0, 0],
          } : {
            text: {
              text: 'No Diagram Provided  ',
              bold: true,
            },
            margin: [0, 5, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Gutters',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: 'Downspouts',
                bold: true,
                fontSize: 13,
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 10],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Color:  ',
                    bold: true,
                  },
                  this.formData.details.gutters.color !== null ? `${this.formData.details.gutters.color}` : 'Not selected',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Color:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.color !== null ? `${this.formData.details.downspouts.color}` : 'Not selected',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: '5":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.five !== null ? `${this.formData.details.gutters.five}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '1st Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.first !== null ? `${this.formData.details.downspouts.first}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: '7":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.seven !== null ? `${this.formData.details.gutters.seven}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '2nd Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.second !== null ? `${this.formData.details.downspouts.second}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'OGEE 5":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.og !== null ? `${this.formData.details.gutters.og}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '3rd Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.third !== null ? `${this.formData.details.downspouts.third}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              {
                text: 'Gutter Screen:  ',
                bold: true,
              },
              this.formData.details.gutters.screen !== null ? `${this.formData.details.gutters.screen}` : 'None',
            ],
            margin: [0, 3],
          },
          this.formData.details.gutters.screenBrand !== null ? {
            text: [
              {
                text: 'Gutter Screen Brand:  ',
                bold: true,
              },
              `${this.formData.details.gutters.screenBrand}`,
            ],
            margin: [0, 3],
          } : {},
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Type of Roof:  ',
                    bold: true,
                  },
                  this.formData.building.type !== null ? `${this.formData.building.type}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Remove Existing Gutters?  ',
                    bold: true,
                  },
                  this.formData.building.removeExisting !== null ? `${this.formData.building.removeExisting}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Roof Pitch:  ',
                    bold: true,
                  },
                  this.formData.building.pitch !== null ? `${this.formData.building.pitch}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Longest Run:  ',
                    bold: true,
                  },
                  this.formData.building.longestRun !== null ? `${this.formData.building.longestRun}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Resheeting?  ',
                    bold: true,
                  },
                  this.formData.building.resheet !== null ? `${this.formData.building.resheet}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Bare:  ',
                    bold: true,
                  },
                  this.formData.building.bare !== null ? `${this.formData.building.bare}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Tuck:  ',
                    bold: true,
                  },
                  this.formData.building.tuck !== null ? `${this.formData.building.tuck}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Reuse Downspouts?  ',
                    bold: true,
                  },
                  this.formData.building.reuseDownspout !== null ? `${this.formData.building.reuseDownspout}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Power Available?  ',
                    bold: true,
                  },
                  this.formData.job.power !== null ? `${this.formData.job.power}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Dogs?  ',
                    bold: true,
                  },
                  this.formData.job.dogs !== null ? `${this.formData.job.dogs}` : 'Not Specified',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            text: 'Special Instructions:',
            bold: true,
          },
          this.formData.specialInstructions ? {
            text: `${this.formData.specialInstructions}`,
            margin: [0, 5, 0, 15],
          } : {
            text: 'None',
            margin: [0, 5, 0, 15],
          },
          '**** One year leak warranty',
          '**** There will be a service charge for all service calls for clogged gutters or downspouts',
          {
            text: [
              'Digitally signed by  ',
              {
                text: `${this.formData.signature}`,
                bold: true,
              },
              '  on  ',
              {
                text: `${this.formData.dateSigned}`,
                bold: true,
              },
            ],
            margin: [50, 15],
          },
        ],
        // eslint-disable-next-line
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.headlineLevel === 1
            && nodesOnNextPage.length > 0) {
            return true;
          }
          return false;
        },
      };
      return document;
    },
    nameFile() {
      if (this.formData.job.address) {
        this.fileName = this.formData.job.address.replace(/\./g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\ /g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\,/g, '_');
        this.fileName = `${this.fileName}.pdf`;
      } else {
        this.fileName = `${this.formData.name}.pdf`;
      }
    },
    downloadPdf() {
      this.nameFile(this.formData);
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).download(this.fileName);
    },
    async emailPdf(command) {
      this.nameFile(this.formData);
      const document = this.makePdf(this.formData);
      const file = pdfMake.createPdf(document);
      this.email.fileName = this.fileName;
      await file.getBase64((data) => {
        this.email.file = data;
        if (command === 'customer') {
          const name = this.formData.contractor.name.split(' ');
          this.email.to = this.formData.contractor.email;
          this.email.replyTo = 'valleydemo@hotmail.com';
          this.email.message = `Hello ${name[0]},

            You recently requested a quote on ${this.$t('project.url')}. 

            Project: ${this.formData.job.address}

            Attached is a copy of this request.

            We will contact you once we have reviewed the information you provided.

            Thank you,

            Valley Demo & Raingutter / Valley Roof Removal

            (916) 455-1690  |  valleydemo@hotmail.com

            `;
          this.emailSent = true;
          this.email.file2 = null;
          this.sendFile();
        }
        if (command === 'client') {
          if (this.formData.diagram) { this.email.file2 = this.formData.diagram; }
          this.email.to = this.$t('project.brandEmailNotifications');
          this.email.replyTo = this.formData.contractor.email;
          this.email.subjectLine = `Gutters for ${this.formData.job.address}`;
          this.email.message = `Hello,

            A new gutter installation request has been received on valleyroofremoval.com. 

            Project: ${this.formData.job.address}

            Attached is a copy of this request`;
          this.sendFileAdmin();
          this.changesMade = false;
        }
      });
    },
    sendFile() {
      this.$store.dispatch('sendFilePublic', this.email);
    },
    sendFileAdmin() {
      this.$store.dispatch('sendFile', this.email);
    },
    previewPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).open();
    },
    printPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).print();
    },
  },
  validations: {
    formData: {
      building: {
        type: {
          required,
        },
        pitch: {
          required,
        },
        longestRun: {
          required,
        },
        resheet: {
          required,
        },
        bare: {
          required,
        },
        tuck: {
          required,
        },
      },
      contractor: {
        email: {
          required,
        },
        name: {
          required,
        },
        license: {
          required,
        },
        phone: {
          required,
        },
        billingStreet: {
          required,
        },
        billingCityStateZip: {
          required,
        },
      },
      dateRequested: {
        required,
      },
      homeowner: {
        name: {
          required,
        },
        phone: {
          required,
        },
      },
      job: {
        address: {
          required,
        },
        city: {
          required,
        },
        power: {
          required,
        },
        dogs: {
          required,
        },
      },
      details: {
        gutters: {
          color: {
            required,
          },
        },
        downspouts: {
          color: {
            required,
          },
        },
      },
      signature: {
        required,
      },
    },
  },
};
</script> -->



<!-- eslint-disable max-len -->
<template>
  <b-row id="bodyPage"
      class="main-container"
      h-align="center">
    <b-col
        class="m-0 p-0"
        align="center">
      <div
          id="brand"
          class="text-center mb-2">
          Valley Demo & Raingutter
        <div
            id="header"
            class="text-center">
            Raingutter Order Form
        </div>
      </div>
      <div id="form">
        <b-form
            id="formData">
          <b-container
              id="dateContainer"
              class="form-container text-center">
            <b-row
                align-h="center">
              <b-col
                  cols="auto"
                  align="center">
                <b-input-group
                      class="i-small"
                      prepend="Requested Installation Date">
                  <b-form-input
                      id="dateRequested"
                      type="date"
                      v-model="formData.dateRequested">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <div
                class="error-label"
                v-if="!$v.formData.dateRequested.required
                      && $v.formData.dateRequested.$dirty">
              Please select a date.
            </div>
          </b-container>
          <b-container
              id="contactInfo"
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Contractor Details
              </span>
            </b-container>
            <b-form-row class="my-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Contractor's Name">
                  <b-form-input
                      id="contractorName"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.name"
                  />
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.name.required
                          && $v.formData.contractor.name.$dirty">
                  Enter the contractor's name as it appears on their license.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                  prepend="Contractor's License Number"
                  class="i-medium">
                  <b-form-input
                      id="contractorLicence"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.license">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.license.required
                          && $v.formData.contractor.license.$dirty">
                  Enter the contractor's license number.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Phone Number"
                    class="i-medium">
                  <b-form-input
                      id="contractorPhone"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.phone">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.phone.required
                          && $v.formData.contractor.phone.$dirty">
                  Enter your phone number (numbers only).
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                  prepend="Email"
                  class="i-medium">
                  <b-form-input
                      id="contractorEmail"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.email">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                  prepend="Billing Address"
                  class="i-medium">
                  <b-form-input
                      id="contractorBillingAddress"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.billingStreet">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingStreet.required
                          && $v.formData.contractor.billingStreet.$dirty">
                Enter your billing street address.
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="City, State, Zip"
                    class="i-medium">
                  <b-form-input
                      id="contractorBillingCityStateZip"
                      type="text"
                      class="formInput"
                      v-model="formData.contractor.billingCityStateZip"
                  />
              </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.contractor.billingCityStateZip.required
                          && $v.formData.contractor.billingCityStateZip.$dirty">
                  Enter your billing city, state, and zip code.
                </div>
              </b-col>
            </b-form-row>
          </b-container>
          <b-container
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Jobsite Details
              </span>
            </b-container>
            <b-row
                class="mb-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  align="center"
                  class="inputContainer">
                <b-input-group
                    prepend="Jobsite Address"
                    class="i-medium">
                  <b-form-input
                      id="jobAddress"
                      type="text"
                      class="formInput"
                      v-model="formData.job.address">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.address.required
                          && $v.formData.job.address.$dirty">
                  Enter the jobsite address.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Cross Street"
                    class="i-medium">
                  <b-form-input
                      id="jobCrossStreet"
                      type="text"
                      class="formInput"
                      v-model="formData.job.crossStreet">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.crossStreet.required
                          && $v.formData.job.crossStreet.$dirty">
                  Enter the cross-street.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="City"
                    class="i-medium">
                  <b-form-input
                      id="jobCity"
                      type="text"
                      class="formInput"
                      v-model="formData.job.city">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.city.required
                          && $v.formData.job.city.$dirty">
                  Enter the city.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Homeowner's Name"
                    class="i-medium">
                  <b-form-input
                      id="homeownerName"
                      type="text"
                      class="formInput"
                      v-model="formData.homeowner.name">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.name.required
                          && $v.formData.homeowner.name.$dirty">
                  Enter the name of the homeowner.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                    prepend="Homeowner's Phone"
                    class="i-medium">
                  <b-form-input
                      id="homeownerPhone"
                      type="text"
                      class="formInput"
                      v-model="formData.homeowner.phone">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.homeowner.required
                          && $v.formData.homeowner.$dirty">
                  Enter the homeowner's phone number.
                </div>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="auto">
                <b-input-group
                  prepend="Power Available?"
                  class="i-medium">
                  <b-form-radio-group
                      id="powerAvailable"
                      buttons
                      class="formInput"
                      v-model="formData.job.power">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.power === 'Yes' ? 'success' : 'outline-success'"
                        value="Yes">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.job.power === 'No' ? 'success' : 'outline-success'"
                        value="No">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.narrowDriveway.required
                          && $v.formData.job.narrowDriveway.$dirty">
                  Select a response.
                </div>
              </b-col>
              <b-col
                  align="center"
                  sm="12"
                  md="6"
                  lg="4"
                  class="inputContainer">
                <b-input-group
                  prepend="Dogs?">
                  <b-form-input
                      id="jobDogs"
                      type="text"
                      class="formInputSmall"
                      v-model="formData.job.dogs">
                  </b-form-input>
                </b-input-group>
                <div
                    class="error-label"
                    v-if="!$v.formData.job.dogs.required
                          && $v.formData.job.dogs.$dirty">
                  Select a response.
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-container
              id="diagram"
              class="form-container mt-4">
            <b-container
                class="headlineContainer">
              <b-row
                  h-align="center">
                <b-col
                    align="center"
                    class="p-2 image-upload__container">
                  <div
                      class="headline">
                    Upload a Diagram
                  </div>
                  Please provide a brief sketch or diagram showing the layout of the house and mark areas for gutters and downspouts.
                  <i>
                    If no diagram is included, gutters and downspouts will be installed in pre-existing locations or at installer's discretion. 
                  </i>
                  <br />
                  <input
                      id="inputFileToLoad"
                      type="file"
                      accept="image/*"
                      @change="addImage()"
                      />
                  <b-button
                      v-if="formData.diagram"
                      @click="formData.diagram = null">
                    Remove
                  </b-button>
                    <div
                      v-if="formData.diagram">
                    <img
                        :src="previewImage"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-container>
          <b-container
              class="form-container">
            <b-container
              class="headlineContainer">
              <span
                  class="headline">
                Gutters
              </span>
              Please Indicate Footage
            </b-container>
            <b-row
              align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend='5"'>
                  <b-form-input
                      id="guttersFive"
                      type="text"
                      v-model="formData.details.gutters.five">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend='7"'>
                  <b-form-input
                      id="guttersSeven"
                      type="text"
                      v-model="formData.details.gutters.seven">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend='OGEE 5"'>
                  <b-form-input
                      id="guttersOG"
                      type="text"
                      v-model="formData.details.gutters.og">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend='Gutter Screens'>
                  <b-form-input
                      id="gutterScreens"
                      type="text"
                      v-model="formData.details.gutters.screen">
                  </b-form-input>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend="Brand">
                  <b-form-select
                      id="gutterScreenBrand"
                      class="formInputSmall ml-0"
                      v-model="formData.details.gutters.screenBrand"
                      :options="['E-Z-Lock', 'LeafBlaster']"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </b-container>
          <b-container
              class="form-container">
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                  <b-container
                      class="headlineContainer">
                    <span
                        class="headline">
                      Color
                    </span>
                  </b-container>
                <b-input-group>
                  <b-form-input
                      id="guttersColor"
                      type="text"
                      class="formInput mr-2"
                      v-model="formData.details.gutters.color"
                  />
                  <b-input-group-append
                      class="mr-3">
                    <b-btn
                        id="showColorChoices"
                        @click="showColorChoices()"
                        variant="info">
                      <span
                          v-if="!showColorsGutter">
                        Show Color Choices
                      </span>
                      <span
                          v-if="showColorsGutter">
                        Hide Color Choices
                      </span>
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <transition
                name="fade"
                mode="out-in">
                <b-container
                    v-if="showColorsGutter"
                    class="form-container">
                  <b-row id="colorOptions"
                      align-h="center"
                      class="p-3">
                    <b-col align="center"
                          id="GutterColor02"
                          @click="updateColorGutter('02 - 50 White')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/02.jpg"
                            class="colorSample"
                            alt="50 White"
                            fluid
                        />
                        <br />
                          White
                      </b-col>
                      <b-col align="center"
                          id="GutterColor04"
                          @click="updateColorGutter('04 - Musket Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/04.jpg"
                            class="colorSample"
                            alt="Musket Brown"
                            fluid
                        />
                        <br />
                          Musket Brown
                      </b-col>
                      <b-col align="center"
                          id="GutterColor06"
                          @click="updateColorGutter('06 - Old Town Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/06.jpg"
                            class="colorSample"
                            alt="Old Town Gray"
                            fluid
                        />
                        <br />
                          Old Town Gray
                      </b-col>
                      <b-col align="center"
                          id="GutterColor08"
                          @click="updateColorGutter('08 - GS Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/08.jpg"
                            class="colorSample"
                            alt="GS Blue"
                            fluid
                        />
                        <br />
                          GS Blue
                      </b-col>
                      <b-col align="center"
                          id="GutterColor10"
                          @click="updateColorGutter('10 - Almond')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/10.jpg"
                            class="colorSample"
                            alt="Almond"
                            fluid
                        />
                        <br />
                          Almond
                      </b-col>
                      <b-col align="center"
                          id="GutterColor05"
                          @click="updateColorGutter('05 - Royal Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/05.jpg"
                            class="colorSample"
                            alt="Royal Brown"
                            fluid
                        />
                        <br />
                          Royal Brown
                      </b-col>
                      <b-col align="center"
                          id="GutterColor07"
                          @click="updateColorGutter('07 - Storm Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/07.jpg"
                            class="colorSample"
                            alt="Storm Gray"
                            fluid
                        />
                        <br />
                          Storm Gray
                      </b-col>
                      <b-col align="center"
                          id="GutterColor09"
                          @click="updateColorGutter('09 - Tahoe Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/09.jpg"
                            class="colorSample"
                            alt="Tahoe Blue"
                            fluid
                        />
                        <br />
                          Tahoe Blue
                      </b-col>
                      <b-col align="center"
                          id="GutterColor11"
                          @click="updateColorGutter('11 - Ivory')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/11.jpg"
                            class="colorSample"
                            alt="Ivory"
                            fluid
                        />
                        <br />
                          Ivory
                      </b-col>
                      <b-col align="center"
                          id="GutterColor12"
                          @click="updateColorGutter('12 - Raffia Beige')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/12.jpg"
                            class="colorSample"
                            alt="Raffia Beige"
                            fluid
                        />
                        <br />
                          Raffia Beige
                      </b-col>
                      <b-col align="center"
                          id="GutterColor14"
                          @click="updateColorGutter('14 - Leaf Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/14.jpg"
                            class="colorSample"
                            alt="Leaf Green"
                            fluid
                        />
                        <br />
                          Leaf Green
                      </b-col>
                      <b-col align="center"
                          id="GutterColor16"
                          @click="updateColorGutter('16 - Marine Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/16.jpg"
                            class="colorSample"
                            alt="Marine Green"
                            fluid
                        />
                        <br />
                          Marine Green
                      </b-col>
                      <b-col align="center"
                          id="GutterColor18"
                          @click="updateColorGutter('18 - Colonial Red')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/18.jpg"
                            class="colorSample"
                            alt="Colonial Red"
                            fluid
                        />
                        <br />
                          Colonial Red
                      </b-col>
                      <b-col align="center"
                          id="GutterColor46"
                          @click="updateColorGutter('46 - Colonial Gray')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/46.jpg"
                            class="colorSample"
                            alt="Colonial Grey"
                            fluid
                        />
                        <br />
                          Colonial Gray
                      </b-col>
                      <b-col align="center"
                          id="GutterColor15"
                          @click="updateColorGutter('15 - Forest Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/15.jpg"
                            class="colorSample"
                            alt="Forest Green"
                            fluid
                        />
                        <br />
                          Forest Green
                      </b-col>
                      <b-col align="center"
                          id="GutterColor17"
                          @click="updateColorGutter('17 - Spanish Green')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/14.jpg"
                            class="colorSample"
                            alt="Spanish Green"
                            fluid
                        />
                        <br />
                          Spanish Green
                      </b-col>
                      <b-col align="center"
                          id="GutterColor19"
                          @click="updateColorGutter('19 - Black')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/19.jpg"
                            class="colorSample"
                            alt="Black"
                            fluid
                        />
                        <br />
                          Black
                      </b-col>
                      <b-col align="center"
                          id="GutterColor41"
                          @click="updateColorGutter('41 - Buckskin Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/41.jpg"
                            class="colorSample"
                            alt="Buckskin Brown"
                            fluid
                        />
                        <br />
                          Buckskin Brown
                      </b-col>
                      <b-col align="center"
                          id="GutterColor40"
                          @click="updateColorGutter('40 - Beaver Brown')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/40.jpg"
                            class="colorSample"
                            alt="Beaver Brown"
                            fluid
                        />
                        <br />
                          Beaver Brown
                      </b-col>
                      <b-col align="center"
                          id="GutterColor42"
                          @click="updateColorGutter('42 - Pebblestone Clay')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/42.jpg"
                            class="colorSample"
                            alt="Pebblestone Clay"
                            fluid
                        />
                        <br />
                          Pebblestone Clay
                      </b-col>
                      <b-col align="center"
                          id="GutterColor44"
                          @click="updateColorGutter('44 - Classic Cream')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/44.jpg"
                            class="colorSample"
                            alt="Classic Cream"
                            fluid
                        />
                        <br />
                          Classic Cream
                      </b-col>
                      <b-col align="center"
                          id="GutterColor48"
                          @click="updateColorGutter('48 - Woodbeige')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/48.jpg"
                            class="colorSample"
                            alt="Woodbeige"
                            fluid
                        />
                        <br />
                          Woodbeige
                      </b-col>
                      <b-col align="center"
                          id="GutterColor49"
                          @click="updateColorGutter('49 - Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/49.jpg"
                            class="colorSample"
                            alt="Bronze"
                            fluid
                        />
                        <br />
                          Bronze
                      </b-col>
                      <b-col align="center"
                          id="GutterColor43"
                          @click="updateColorGutter('43 - Adobe Tan')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/43.jpg"
                            class="colorSample"
                            alt="Adobe Tan"
                            fluid
                        />
                        <br />
                          Adobe Tan
                      </b-col>
                      <b-col align="center"
                          id="GutterColor45"
                          @click="updateColorGutter('45 - Heritage Cream')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/45.jpg"
                            class="colorSample"
                            alt="Heritage Cream"
                            fluid
                        />
                        <br />
                          Heritage Cream
                      </b-col>
                      <b-col align="center"
                          id="GutterColor35"
                          @click="updateColorGutter('35 - Weathered Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/35.jpg"
                            class="colorSample"
                            alt="Weathered Bronze"
                            fluid
                        />
                        <br />
                          Weathered Bronze
                      </b-col>
                      <b-col align="center"
                          id="GutterColor47"
                          @click="updateColorGutter('47 - Sea Blue')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/47.jpg"
                            class="colorSample"
                            alt="Sea Blue"
                            fluid
                        />
                        <br />
                          Sea Blue
                      </b-col>
                      <b-col align="center"
                          id="GutterColor50"
                          @click="updateColorGutter('50 - Peach')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/50.jpg"
                            class="colorSample"
                            alt="Peach"
                            fluid
                        />
                        <br />
                          Peach
                      </b-col>
                      <b-col align="center"
                          id="GutterColor51"
                          @click="updateColorGutter('51 - Linen')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/51.jpg"
                            class="colorSample"
                            alt="Linen"
                            fluid
                        />
                        <br />
                          Linen
                      </b-col>
                      <b-col align="center"
                          id="GutterColor56"
                          @click="updateColorGutter('56 - Eggshell')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/56.jpg"
                            class="colorSample"
                            alt="Eggshell (Steel Only)"
                            fluid
                        />
                        <br />
                          Eggshell (Steel Only)
                      </b-col>
                      <b-col align="center"
                          id="  "
                          @click="updateColorGutter('55 - Arch Bronze')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/55.jpg"
                            class="colorSample"
                            alt="Arch Bronze (Steel Only)"
                            fluid
                        />
                        <br />
                          Arch Bronze (Steel Only)
                      </b-col>
                      <b-col align="center"
                          id="GutterColor52"
                          @click="updateColorGutter('52 - Champagne')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/52.jpg"
                            class="colorSample"
                            alt="Champagne"
                            fluid
                        />
                        <br />
                          Champagne
                      </b-col>
                      <b-col align="center"
                          id="GutterColor54"
                          @click="updateColorGutter('54 - Satin')"
                          class="colorPanel">
                        <b-img-lazy
                            src="@/assets/colors/54.jpg"
                            class="colorSample"
                            alt="Satin"
                            fluid
                        />
                        <br />
                          Satin
                      </b-col>
                  </b-row>
                  <i class="pt-3">
                    Note: Colors shown are based on a printer's representation. Variations may exist.
                    <br />
                    Before making final color choices, please ask for a metal color chip sample.
                  </i>
                </b-container>
            </transition>
          </b-container>
          <b-container
              class="form-container">
            <b-container
                class="headlineContainer">
            <span
                class="headline">
              Downspouts
            </span>
              Number of Downspouts
            </b-container>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend="1st Story"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen"
                      type="number"
                      class="formInputSmall ml-0"
                      v-model="formData.details.downspouts.first"
                  />
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend="2nd Story"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen2"
                      type="number"
                      class="formInputSmall ml-0"
                      v-model="formData.details.downspouts.second"
                  />
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    prepend="3rd Story"
                    class="formInputGroup">
                  <b-form-input
                      id="guttersScreen3"
                      type="number"
                      class="formInputSmall ml-0"
                      v-model="formData.details.downspouts.third"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="6"
                  lg="4">
                <b-input-group
                    class="px-2">
                  <b-form-input
                      id="downspoutsColor"
                      type="text"
                      class="formInput mr-2"
                      v-model="formData.details.downspouts.color">
                  </b-form-input>
                  <b-input-group-append
                      class="mr-3">
                    <b-btn
                        id="showColorChoices"
                        @click="showColorChoicesDownspout()"
                        variant="info">
                  <span
                      v-if="!showColorsDownspout">
                    Show Color Choices
                  </span>
                  <span
                      v-if="showColorsDownspout">
                    Hide Color Choices
                  </span>
                </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <transition
                  name="fade"
                  mode="out-in">
                <b-container
                    v-if="showColorsDownspout">
                  <b-row
                        align-h="center"
                        id="colorOptions"
                        class="inputContainer">
                        <b-col align="center"
                            id="DownspoutColor02"
                            @click="updateColorDownspout('02 - 50 White')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/02.jpg"
                              class="colorSample"
                              alt="White"
                              fluid
                          />
                          <br />
                            White
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor04"
                            @click="updateColorDownspout('04 - Musket Brown')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/04.jpg"
                              class="colorSample"
                              alt="Musket Brown"
                              fluid
                          />
                          <br />
                            Musket Brown
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor06"
                            @click="updateColorDownspout('06 - Old Town Gray')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/06.jpg"
                              class="colorSample"
                              alt="Old Town Gray"
                              fluid
                          />
                          <br />
                            Old Town Gray
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor08"
                            @click="updateColorDownspout('08 - GS Blue')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/08.jpg"
                              class="colorSample"
                              alt="GS Blue"
                              fluid
                          />
                          <br />
                            GS Blue
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor10"
                            @click="updateColorDownspout('10 - Almond')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/10.jpg"
                              class="colorSample"
                              alt="Almond"
                              fluid
                          />
                          <br />
                            Almond
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor05"
                            @click="updateColorDownspout('05 - Royal Brown')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/05.jpg"
                              class="colorSample"
                              alt="Royal Brown"
                              fluid
                          />
                          <br />
                            Royal Brown
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor07"
                            @click="updateColorDownspout('07 - Storm Gray')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/07.jpg"
                              class="colorSample"
                              alt="Storm Gray"
                              fluid
                          />
                          <br />
                            Storm Gray
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor09"
                            @click="updateColorDownspout('09 - Tahoe Blue')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/09.jpg"
                              class="colorSample"
                              alt="Tahoe Blue"
                              fluid
                          />
                          <br />
                            Tahoe Blue
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor11"
                            @click="updateColorDownspout('11 - Ivory')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/11.jpg"
                              class="colorSample"
                              alt="Ivory"
                              fluid
                          />
                          <br />
                            Ivory
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor12"
                            @click="updateColorDownspout('12 - Raffia Beige')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/12.jpg"
                              class="colorSample"
                              alt="Raffia Beige"
                              fluid
                          />
                          <br />
                            Raffia Beige
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor14"
                            @click="updateColorDownspout('14 - Leaf Green')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/14.jpg"
                              class="colorSample"
                              alt="Leaf Green"
                              fluid
                          />
                          <br />
                            Leaf Green
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor16"
                            @click="updateColorDownspout('16 - Marine Green')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/16.jpg"
                              class="colorSample"
                              alt="Marine Green"
                              fluid
                          />
                          <br />
                            Marine Green
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor18"
                            @click="updateColorDownspout('18 - Colonial Red')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/18.jpg"
                              class="colorSample"
                              alt="Colonial Red"
                              fluid
                          />
                          <br />
                            Colonial Red
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor46"
                            @click="updateColorDownspout('46 - Colonial Gray')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/46.jpg"
                              class="colorSample"
                              alt="Colonial Grey"
                              fluid
                          />
                          <br />
                            Colonial Gray
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor15"
                            @click="updateColorDownspout('15 - Forest Green')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/15.jpg"
                              class="colorSample"
                              alt="Forest Green"
                              fluid
                          />
                          <br />
                            Forest Green
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor17"
                            @click="updateColorDownspout('17 - Spanish Green')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/14.jpg"
                              class="colorSample"
                              alt="Spanish Green"
                              fluid
                          />
                          <br />
                            Spanish Green
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor19"
                            @click="updateColorDownspout('19 - Black')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/19.jpg"
                              class="colorSample"
                              alt="Black"
                              fluid
                          />
                          <br />
                            Black
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor41"
                            @click="updateColorDownspout('41 - Buckskin Brown')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/41.jpg"
                              class="colorSample"
                              alt="Buckskin Brown"
                              fluid
                          />
                          <br />
                            Buckskin Brown
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor40"
                            @click="updateColorDownspout('40 - Beaver Brown')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/40.jpg"
                              class="colorSample"
                              alt="Beaver Brown"
                              fluid
                          />
                          <br />
                            Beaver Brown
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor42"
                            @click="updateColorDownspout('42 - Pebblestone Clay')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/42.jpg"
                              class="colorSample"
                              alt="Pebblestone Clay"
                              fluid
                          />
                          <br />
                            Pebblestone Clay
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor44"
                            @click="updateColorDownspout('44 - Classic Cream')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/44.jpg"
                              class="colorSample"
                              alt="Classic Cream"
                              fluid
                          />
                          <br />
                            Classic Cream
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor48"
                            @click="updateColorDownspout('48 - Woodbeige')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/48.jpg"
                              class="colorSample"
                              alt="Woodbeige"
                              fluid
                          />
                          <br />
                            Woodbeige
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor49"
                            @click="updateColorDownspout('49 - Bronze')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/49.jpg"
                              class="colorSample"
                              alt="Bronze"
                              fluid
                          />
                          <br />
                            Bronze
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor43"
                            @click="updateColorDownspout('43 - Adobe Tan')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/43.jpg"
                              class="colorSample"
                              alt="Adobe Tan"
                              fluid
                          />
                          <br />
                            Adobe Tan
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor45"
                            @click="updateColorDownspout('45 - Heritage Cream')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/45.jpg"
                              class="colorSample"
                              alt="Heritage Cream"
                              fluid
                          />
                          <br />
                            Heritage Cream
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor35"
                            @click="updateColorDownspout('35 - Weathered Bronze')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/35.jpg"
                              class="colorSample"
                              alt="Weathered Bronze"
                              fluid
                          />
                          <br />
                            Weathered Bronze
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor47"
                            @click="updateColorDownspout('47 - Sea Blue')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/47.jpg"
                              class="colorSample"
                              alt="Sea Blue"
                              fluid
                          />
                          <br />
                            Sea Blue
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor50"
                            @click="updateColorDownspout('50 - Peach')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/50.jpg"
                              class="colorSample"
                              alt="Peach"
                              fluid
                          />
                          <br />
                            Peach
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor51"
                            @click="updateColorDownspout('51 - Linen')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/51.jpg"
                              class="colorSample"
                              alt="Linen"
                              fluid
                          />
                          <br />
                            Linen
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor56"
                            @click="updateColorDownspout('56 - Eggshell')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/56.jpg"
                              class="colorSample"
                              alt="Eggshell (Steel Only)"
                              fluid
                          />
                          <br />
                            Eggshell (Steel Only)
                        </b-col>
                        <b-col align="center"
                            id="  "
                            @click="updateColorDownspout('55 - Arch Bronze')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/55.jpg"
                              class="colorSample"
                              alt="Arch Bronze (Steel Only)"
                              fluid
                          />
                          <br />
                            Arch Bronze (Steel Only)
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor52"
                            @click="updateColorDownspout('52 - Champagne')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/52.jpg"
                              class="colorSample"
                              alt="Champagne"
                              fluid
                          />
                          <br />
                            Champagne
                        </b-col>
                        <b-col align="center"
                            id="DownspoutColor54"
                            @click="updateColorDownspout('54 - Satin')"
                            class="colorPanel">
                          <b-img-lazy
                              src="@/assets/colors/54.jpg"
                              class="colorSample"
                              alt="Satin"
                              fluid
                          />
                          <br />
                            Satin
                        </b-col>
                  </b-row>
                  <i v-show="showColorsGutter" class="pt-3">
                    Note: Colors shown are based on a printer's representation. Variations may exist.
                    <br />
                    Before making final color choices, please ask for a metal color chip sample.
                  </i>
                </b-container>
              </transition>
          </b-container>

          <b-container
              id="roofInfo"
              class="form-container">
            <b-container
                class="headlineContainer">
              <span
                  class="headline">
                Details & Options
              </span>
            </b-container>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Type">
                  <b-form-radio-group
                      buttons
                      id="buildingType"
                      v-model="formData.building.type">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.jacks === 'Existing Gutters' ? 'success' : 'outline-success'"
                        value="Existing Gutters">
                      Existing Gutters
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.jacks === 'New Install' ? 'success' : 'outline-success'"
                        value="New Install">
                      New Install
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                v-if="formData.building.type === 'Existing Gutters'"
                align-h="center"
                class="my-2">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Remove Existing Gutters">
                  <b-form-radio-group
                      buttons
                      id="RemoveExisting"
                      class="formInput"
                      v-model="formData.building.type">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.removeExisting === 'Remove Gutters' ? 'success' : 'outline-success'"
                        value="Existing Gutters">
                      Remove Existing
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.removeExisting === 'Keep Existing' ? 'success' : 'outline-success'"
                        value="Keep Existing">
                      Keep Existing
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Reuse Downspouts">
                  <b-form-radio-group
                      buttons
                      id="reuseDS"
                      class="formInput"
                      v-model="formData.building.reuseDownspout">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.reuseDownspout === 'Reuse Downspouts' ? 'success' : 'outline-success'"
                        value="Existing Gutters">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.reuseDownspout === 'Do Not Reuse Downspouts' ? 'success' : 'outline-success'"
                        value="Do Not Reuse Downspouts">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Roof Pitch">
                  <b-form-input
                      id="pitchEntry"
                      class="formInput"
                      v-model="formData.building.pitch"
                  />
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Longest Run">
                  <b-form-input
                      id="Longest Run"
                      class="formInput"
                      v-model="formData.building.longestRun"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                  prepend="Resheeting?">
                  <b-form-radio-group
                      buttons
                      id="resheet"
                      class="formInput"
                      v-model="formData.building.resheet">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.resheet === 'Resheeting' ? 'success' : 'outline-success'"
                        value="Resheeting">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.resheet === 'No Resheeting' ? 'success' : 'outline-success'"
                        value="No Resheeting">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                  prepend="Bare">
                  <b-form-radio-group
                      buttons
                      id="bare"
                      class="formInput"
                      v-model="formData.building.bare">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.bare === 'Bare' ? 'success' : 'outline-success'"
                        value="Bare">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.bare === 'Not Bare' ? 'success' : 'outline-success'"
                        value="Not Bare">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  sm="12"
                  md="auto">
                <b-input-group
                    prepend="Tuck">
                  <b-form-radio-group
                      buttons
                      id="tuck"
                      class="formInput"
                      v-model="formData.building.tuck">
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.tuck === 'Tuck' ? 'success' : 'outline-success'"
                        value="Tuck">
                      Yes
                    </b-form-radio>
                    <b-form-radio
                        class="mx-1"
                        :button-variant="formData.building.tuck === 'Do Not Tuck' ? 'success' : 'outline-success'"
                        value="Do Not Tuck">
                      No
                    </b-form-radio>
                  </b-form-radio-group>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  align="center">
              <b-input-group
                  prepend="Special Instructions"
                  class="full-size">
                <b-textarea id="specialInstructionsInput"
                    v-model="formData.specialInstructions"
                    :rows="4"
                    :max-rows="8"
                />
              </b-input-group>
            </b-col>
          </b-row>
          </b-container>
          <b-container
              id="signatureBox"
              class="form-container">
            <b-container
                class="headlineContainer"
                v-if="!complete">
              <span
                  class="headline">
                Sign
              </span>
            </b-container>
            <b-form-row>
              <b-col
                  align="center"
                  class="inputContainer"
                  v-if="!complete">
                <span
                    class="form-labels py-3">
                  By typing your name into this field, you are signing this order form and contract electronically.
                </span>
                <b-form-input
                    id="signature"
                    type="text"
                    placeholder="Enter your name..."
                    class="name-input"
                    v-model="formData.signature">
                </b-form-input>
                <span
                    class="form-labels">
                  Sign this to designate that you understand and agree with all stipulations as outlined in our terms and conditions, which can be viewed <a href="http://valleyroofremoval.com/terms/" _target="blank" style="color: goldenrod">here</a>, and that the roof is ready for gutter installation.
                </span>
              </b-col>
              <b-col
                  align="center"
                  class="inputContainer"
                  v-else>
                <span
                    v-if="showSpinner">
                  <b-spinner />
                  Your request is uploading. Please wait.
                </span>
                <span
                    v-else>
                    Your request has been received. We will contact you once we have reviewed the details.
                </span>
              </b-col>
            </b-form-row>
            <div
                class="error-label"
                v-if="!$v.formData.signature.required
                      && $v.formData.signature.$dirty">
            You must enter a digital signature.
            </div>
            <b-container
                align="center"
                class="mt-2">
              <b-btn
                @click="submitThis(formData)"
                variant="primary"
                size="lg"
                :disabled="complete || $v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalid">
                <b-spinner
                    v-if="showSpinner" />
                Submit
              </b-btn>
              <p />
              <span v-show="$v.formData.signature.$invalid
                  || $v.formData.contractor.name.$invalid
                  || $v.formData.job.address.$invalid
                  || $v.formData.contractor.email.$invalids">
                Before submitting, please enter the following information:
                <br />
              </span>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.contractor.name.$invalid">
                • Contractor's Name
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.contractor.email.$invalid">
                • Contractor's Email Address
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.job.address.$invalid">
                • Project Address
                <br />
              </b>
              <b style="font-size: 1.2em"
                  v-show="$v.formData.signature.$invalid">
                • Please sign the form
                <br />
              </b>
            </b-container>
          </b-container>
        </b-form>
      </div>
      <!-- <div id="thankYou"
          v-show="complete">
        <b-container id="thankYouContainer"
            class="form-container text-center">
          <b-form-row>
            <b-col align="center"
                class="headlineContainer">
              <span
                  class="headline">
                Thank you
              </span>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col
                align="center"
                v-if="formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request or we can email it to {{formData.contractor.email}}.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mr-2 ml-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                    <b-btn id="emailReceipt"
                        variant="primary"
                        class="ml-2 mr-auto"
                        @click="emailPdf('customer')"
                        :disabled="emailSent ? true : false">
                      {{emailSent === false ? 'Email' : 'Email Sent'}}
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
            <b-col
                align="center"
                v-if="!formData.contractor.email">
              <span
                  class="form-labels">
                Your request has been received. We will contact you once we have reviewed the details.
                <br />You may download a copy of your request by clicking below.
              </span>
              <b-row
                  h-align="center"
                  class="pt-2">
                <b-col
                    align="center">
                  <b-btn-toolbar id="receiptOptions">
                    <b-btn id="downloadReceipt"
                        variant="primary"
                        class="mx-auto"
                        @click="downloadPdf()">
                      Download
                    </b-btn>
                  </b-btn-toolbar>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div> -->
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// import ImageModal from '@/components/data/ImageUpload';
import { s3 } from '@/store/aws';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'GutterForm',
  mixins: [
    validationMixin,
  ],
  // components: {
  //   ImageModal,
  // },
  title() {
    return `Gutter Order Form | ${this.$t('project.brand')}`;
  },
  data() {
    return {
      showColorsGutter: false,
      showColorsDownspout: false,
      active: 0,
      complete: false,
      changesMade: false,
      message: '',
      previewImage: null,
      errorMsg: null,
      fileName: null,
      emailSent: false,
      imageLoader: false,
      tearOffOptions: [
        { text: 'House', value: 'House' },
        { text: 'Patio', value: 'Patio' },
        { text: 'Attached Garage', value: 'Attached Garage' },
        { text: 'Detached Garage', value: 'Detached Garage' },
      ],
      formData: {
        building: {
          type: null,
          removeExisting: null,
          pitch: null,
          longestRun: null,
          resheet: null,
          bare: null,
          tuck: null,
          reuseDownspout: null,
        },
        diagram: null,
        contractor: {
          name: null,
          license: null,
          phone: null,
          billingStreet: null,
          billingCityStateZip: null,
          email: null,
        },
        dateRequested: null,
        homeowner: {
          name: null,
          phone: null,
        },
        job: {
          address: null,
          city: null,
          power: null,
          dogs: null,
        },
        details: {
          gutters: {
            color: null,
            five: null,
            seven: null,
            og: null,
            screen: null,
            screenBrand: null,
          },
          downspouts: {
            color: null,
            first: null,
            second: null,
            third: null,
          },
        },
        signature: null,
        specialInstructions: null,
        dateSigned: moment().format('Do MMMM YYYY'),
      },
      // formData: {
      //   building: {
      //     tearOff: [],
      //     jacks: null,
      //     gutters: null,
      //     guttersFt: null,
      //     antenna: null,
      //     solarPanels: null,
      //     dormerVents: null,
      //     turbines: null,
      //     cathedral: null,
      //   },
      //   diagram: null,
      //   contractor: {
      //     name: null,
      //     license: null,
      //     email: null,
      //     phone: null,
      //     billingStreet: null,
      //     billingCityStateZip: null,
      //   },
      //   dateRequested: null,
      //   homeowner: {
      //     name: null,
      //     phone: null,
      //   },
      //   job: {
      //     address: null,
      //     crossStreet: null,
      //     city: null,
      //     groundDrop: null,
      //     lowTrees: null,
      //     dogs: null,
      //     carried: null,
      //     narrowDriveway: null,
      //     access: null,
      //     acQty: null,
      //     skylightsQty: null,
      //   },
      //   details: {
      //     squares: null,
      //     type: null,
      //     layers: null,
      //     stories: null,
      //     pitch: null,
      //   },
      //   signature: null,
      //   specialInstructions: null,
      //   partialTearoff: null,
      //   tarpFlowers: null,
      //   dateSigned: moment().format('Do MMMM YYYY'),
      // },
      emailFormData: new FormData(),
      customerFormData: new FormData(),
      email: {
        to: null,
        file: null,
        file2: null,
        fileName: null,
        subjectLine: 'Raingutters Request',
        message: null,
        from: 'valleydemo@hotmail.com',
        replyTo: null,
        serviceName: 'valleyroofremoval.com',
      },
    };
  },
  computed: {
    readyToCleanup() {
      return this.$store.state.cleanup;
    },
    completed() {
      return this.$store.state.sendFileSuccess;
    },
    showSpinner() {
      return this.$store.state.showSpinner;
    },
    uploadedDiagram() {
      return document.getElementById('inputFileToLoad');
    }
  },
  created() {
    // Catch reloads/page closures
    const that = this;
    window.addEventListener('beforeunload', (e) => {
      if (that.changesMade) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.changesMade) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(this.$t('unsavedChangesConfirm'));
      if (answer) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    formData: {
      handler() {
        if (this.changesMade === false) {
          this.changesMade = true;
        }
      },
      deep: true,
    },
    uploadedDiagram(newVal) {
      console.log('this', newVal);
    },
    readyToCleanup(newVal) {
      if (newVal) {
        if (this.formData.diagram) {
          this.deleteImage(this.formData.diagram);
        }
      }
    },
  },
  methods: {
    showColorChoices() {
      if (this.showColorsGutter) this.showColorsGutter = false;
      else this.showColorsGutter = true;
    },
    showColorChoicesDownspout() {
      if (this.showColorsDownspout) this.showColorsDownspout = false;
      else this.showColorsDownspout = true;
    },
    updateColorGutter(colorKey) {
      this.formData.details.gutters.color = colorKey;
      this.showColorsGutter = false;
    },
    updateColorDownspout(colorKey) {
      this.formData.details.downspouts.color = colorKey;
      this.showColorsDownspout = false;
    },
    addImage() {
      const file = document.getElementById('inputFileToLoad').files[0];
      if(file.size > 9285760){
        alert("File is too big! Please select an image under 9mb");
      } else {
        // let diagram;
        // const toBase64 = file => new Promise((resolve, reject) => {
        //       const reader = new FileReader();
        //       reader.readAsDataURL(file);
        //       reader.onload = () => resolve(reader.result);
        //       reader.onerror = reject;
        //     });
        // toBase64(file).then((res) => {
        //   console.log('yesssss', res);
        //   this.email.file2 = res;
        //   // diagram = res;
        // });
        this.formData.diagram = file;
        this.formData.filename2 = file.name;
        this.displayUpload();
      }
    },
    displayUpload() {
      if (this.formData.diagram) {
        this.previewImage = URL.createObjectURL(this.formData.diagram);
      }
    },
    leavingPage() {
      console.log('leavin');
      // eslint-disable-next-line no-alert
      window.confirm(this.$t('unsavedChangesConfirm'));
    },
    imageError() {
      this.errorMsg = this.$t('upload.ErrorOversize');
      this.imageLoader = false;
      this.formData.diagram = null;
    },
    clearImage(src) {
      this.deleteImage(src);
      this.formData.diagram = null;
    },
    cleanPhone(numberString) {
      // eslint-disable-next-line prefer-template
      const cleaned = ('' + numberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },
    cancelUpload() {
      this.imageLoader = false;
    },
    useImage(data) {
      const img = {
        src: data.data.src,
        title: '',
        size: 'lg',
      };
      this.formData.diagram = img.src;
      this.imageLoader = false;
    },
    deleteImage(url) {
      const key = url.substring(url.lastIndexOf('/') + 1);
      s3.deleteObject({ Key: key }, (err, data) => {
        if (err) {
          this.message = `There was an error deleting your photo ${err.message}`;
        }
        this.message = 'Image deleted';
        this.interval = function run() {
          this.message = null;
        }.bind((this), 3000);
        console.log(this.message);
        return console.log('Success:', data);
      });
    },
    startLoader() {
      this.imageLoader = true;
      this.errorMsg = null;
    },
    async submitThis() {
      await this.emailPdf('client').then(this.changesMade = false);
    },
    makeTearoffItems(items) {
      const allItems = {
        columns: [],
        columnGap: 5,
        margin: [0, 0, 0, 10],
      };
      for (let i = 0; i < items.length; i += 1) {
        const item = {
          text: items[i],
          width: '15%',
        };
        allItems.columns.push(item);
      }
      return allItems;
    },
    makePdf() {
      let projectName = '';
      if (this.formData.job.address) {
        projectName = this.formData.job.address.replace(/[, ]+/g, ' ');
      } else {
        projectName = this.formData.contractor.name + this.formData.dateRequested;
      }
      const document = {
        pageMargins: [30, 85, 30, 30],
        header: [
          {
            text: 'VALLEY DEMO & RAINGUTTER',
            fontSize: 15,
            alignment: 'center',
            margin: [0, 20, 0, 0],
          },
          {
            text: '8996 Fruitridge Rd, Bldg 5, Sacramento, CA 95826',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 5],
          },
          {
            text: 'Phone: (916) 455-1690 Fax: (916)452-3571',
            fontSize: 10,
            alignment: 'center',
          },
          {
            text: 'Email: valleydemo@hotmail.com',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 40],
          },
        ],
        footer(currentPage, pageCount) {
          if (pageCount > 1) {
            return [{
              text: `Order form for ${projectName} | Page ${currentPage.toString()} of ${pageCount}`,
              alignment: 'center',
            }];
          }
          return {};
        },
        content: [
          {
            text: [
              {
                text: 'Requested Installation Date:   ',
                bold: true,
              },
              {
                text: moment(this.formData.dateRequested).format('l'),
                bold: true,
                fontSize: 13,
              },
            ],
            margin: [0, 15, 0, 10],
            alignment: 'right',
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor:  ',
                    bold: true,
                  },
                  `${this.formData.contractor.name}`,
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  this.formData.contractor.phone ? `${this.cleanPhone(this.formData.contractor.phone)}` : '',
                ],
                width: 'auto',
              },
            ],
          },
          {
            text: [
              {
                text: 'Job Location:  ',
                bold: true,
              },
              `${this.formData.job.address}, ${this.formData.job.city ? this.formData.job.city : ''}`,
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Homeowner\'s Name:  ',
                    bold: true,
                  },
                  this.formData.homeowner.name ? `${this.formData.homeowner.name}` : '',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Phone:   ',
                    bold: true,
                  },
                  this.formData.homeowner.phone ? `${this.cleanPhone(this.formData.homeowner.phone)}` : '',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Contractor\'s E-mail:  ',
                    bold: true,
                  },
                  `${this.formData.contractor.email}`,
                ],
              },
              {
                text: [
                  {
                    text: 'Contractor\'s License:  ',
                    bold: true,
                  },
                  this.formData.contractor.license ? `${this.formData.contractor.license}` : 'Not provided',
                ],
              },
            ],
            margin: [0, 5],
          },
          {
            text: [
              {
                text: 'Contractor\'s Billing Address:  ',
                bold: true,
              },
              `${this.formData.contractor.billingStreet ? this.formData.contractor.billingStreet : ''}, ${this.formData.contractor.billingCityStateZip ? this.formData.contractor.billingCityStateZip : ''}`,
            ],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          this.formData.diagram !== null ? {
            text: [
              {
                text: 'Diagram:  ',
                bold: true,
              },
              {
                text: 'Diagram attached',
                decoration: 'underline',
              },
            ],
            margin: [0, 5, 0, 0],
          } : {
            text: {
              text: 'No Diagram Provided  ',
              bold: true,
            },
            margin: [0, 5, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Gutters',
                    bold: true,
                    fontSize: 13,
                  },
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: 'Downspouts',
                bold: true,
                fontSize: 13,
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 10],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Color:  ',
                    bold: true,
                  },
                  this.formData.details.gutters.color !== null ? `${this.formData.details.gutters.color}` : 'Not selected',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Color:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.color !== null ? `${this.formData.details.downspouts.color}` : 'Not selected',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: '5":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.five !== null ? `${this.formData.details.gutters.five}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '1st Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.first !== null ? `${this.formData.details.downspouts.first}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: '7":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.seven !== null ? `${this.formData.details.gutters.seven}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '2nd Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.second !== null ? `${this.formData.details.downspouts.second}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'OGEE 5":  ',
                    bold: true,
                  },
                  this.formData.details.gutters.og !== null ? `${this.formData.details.gutters.og}` : 'None',
                ],
                alignment: 'left',
                width: '50%',
              },
              {
                text: [
                  {
                    text: '3rd Story Qty:  ',
                    bold: true,
                  },
                  this.formData.details.downspouts.third !== null ? `${this.formData.details.downspouts.third}` : 'None',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              {
                text: 'Gutter Screen:  ',
                bold: true,
              },
              this.formData.details.gutters.screen !== null ? `${this.formData.details.gutters.screen}` : 'None',
            ],
            margin: [0, 3],
          },
          this.formData.details.gutters.screenBrand !== null ? {
            text: [
              {
                text: 'Gutter Screen Brand:  ',
                bold: true,
              },
              `${this.formData.details.gutters.screenBrand}`,
            ],
            margin: [0, 3],
          } : {},
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Type of Roof:  ',
                    bold: true,
                  },
                  this.formData.building.type !== null ? `${this.formData.building.type}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Remove Existing Gutters?  ',
                    bold: true,
                  },
                  this.formData.building.removeExisting !== null ? `${this.formData.building.removeExisting}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Roof Pitch:  ',
                    bold: true,
                  },
                  this.formData.building.pitch !== null ? `${this.formData.building.pitch}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Longest Run:  ',
                    bold: true,
                  },
                  this.formData.building.longestRun !== null ? `${this.formData.building.longestRun}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Resheeting?  ',
                    bold: true,
                  },
                  this.formData.building.resheet !== null ? `${this.formData.building.resheet}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Bare:  ',
                    bold: true,
                  },
                  this.formData.building.bare !== null ? `${this.formData.building.bare}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Tuck:  ',
                    bold: true,
                  },
                  this.formData.building.tuck !== null ? `${this.formData.building.tuck}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Reuse Downspouts?  ',
                    bold: true,
                  },
                  this.formData.building.reuseDownspout !== null ? `${this.formData.building.reuseDownspout}` : 'Not Specified',
                ],
                width: 'auto',
              },
            ],
            margin: [0, 5],
          },
          {
            columns: [
              {
                text: [
                  {
                    text: 'Power Available?  ',
                    bold: true,
                  },
                  this.formData.job.power !== null ? `${this.formData.job.power}` : 'Not Specified',
                ],
                width: '50%',
              },
              {
                text: [
                  {
                    text: 'Dogs?  ',
                    bold: true,
                  },
                  this.formData.job.dogs !== null ? `${this.formData.job.dogs}` : 'Not Specified',
                ],
                width: 'auto',
                alignment: 'right',
              },
            ],
            margin: [0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 535,
                y2: 5,
                lineWidth: 0.5,
              },
            ],
          },
          {
            text: 'Special Instructions:',
            bold: true,
          },
          this.formData.specialInstructions ? {
            text: `${this.formData.specialInstructions}`,
            margin: [0, 5, 0, 15],
          } : {
            text: 'None',
            margin: [0, 5, 0, 15],
          },
          '**** One year leak warranty',
          '**** There will be a service charge for all service calls for clogged gutters or downspouts',
          {
            text: [
              'Digitally signed by  ',
              {
                text: `${this.formData.signature}`,
                bold: true,
              },
              '  on  ',
              {
                text: `${this.formData.dateSigned}`,
                bold: true,
              },
            ],
            margin: [50, 15],
          },
        ],
        // eslint-disable-next-line
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.headlineLevel === 1
            && nodesOnNextPage.length > 0) {
            return true;
          }
          return false;
        },
      };
      return document;
    },
    nameFile() {
      if (this.formData.job.address) {
        this.fileName = this.formData.job.address.replace(/\./g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\ /g, '_');
        // eslint-disable-next-line no-useless-escape
        this.fileName = this.fileName.replace(/\,/g, '_');
        this.fileName = `${this.fileName}.pdf`;
      } else {
        this.fileName = `${this.formData.contractor.name}.pdf`;
      }
    },
    downloadPdf() {
      this.nameFile(this.formData);
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).download(this.fileName);
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
            resolve(fr.result);
        }
        fr.readAsDataURL(file);
    });
    },
    async emailPdf() {
      this.nameFile(this.formData);
      const fn = this.fileName;
      const document = this.makePdf(this.formData);
      const file = pdfMake.createPdf(document);
      // this.email.file2 = await this.getBase64(this.formData.diagram);
      // console.log(this.getBase64(this.formData.diagram));
      if (this.formData.diagram) {
        await this.getBase64(this.formData.diagram).then((res) => {
          this.emailFormData.append('filename2', this.formData.filename2);
          this.emailFormData.append('diagram', res.split(',')[1]);
        });
      }
      await file.getBase64((data) => {
        this.emailFormData.append('filename', fn);
        this.emailFormData.append('file', data);
        this.emailFormData.set('service', 'valleyoroofremoval.com');
        this.emailFormData.set('subjectLine', `Gutter Installation for ${this.formData.job.address}`);
        this.emailFormData.set('from', 'valleydemo@hotmail.com');
        this.emailFormData.set('fromName', 'valleyroofremoval.com');
        const name = this.formData.contractor.name.split(' ');
        this.emailFormData.set('adminTo', 'valleydemo@hotmail.com');
        this.emailFormData.set('adminFrom', 'david@windsong.io');
        this.emailFormData.set('customerTo', this.formData.contractor.email);
        this.emailFormData.set('adminReplyTo', this.formData.contractor.email),
        this.emailFormData.set('customerReplyTo', 'valleydemo@hotmail.com'),
        this.emailFormData.set('customerMessage', `Hello ${name[0]},

          You recently requested a quote on valleyroofremoval.com.

          Project: ${this.formData.job.address}

          Attached is a copy of this request.

          We will contact you once we have reviewed the information you provided.

          Thank you,

          Valley Demo & Raingutter / Valley Roof Removal

          (916) 455-1690  |  valleydemo@hotmail.com
          \n
          `);
        this.emailFormData.set('adminMessage', `Hello,

          A new gutter request has been received on valleyroofremoval.com.

          Project: ${this.formData.job.address}

          Attached is a copy of this request
          \n
          `);
        // if (this.formData.diagram) {
        //   this.emailFormData.append('diagram', this.formData.diagram);
        //   this.emailFormData.append('filename2', this.formData.filename2);
        // }
        this.emailSent = true;
        // Admin Email
        // this.emailFormData.set('to', 'valleydemo@hotmail.com');
        // this.emailFormData.set('name', this.formData.contractor.email);
        // this.emailFormData.set('replyTo', this.formData.contractor.email);
        this.sendFileAdmin();
        this.changesMade = false;
        this.complete = true;
      });
    },
    // sendFile() {
    //   this.$store.dispatch('sendFile', this.emailFormData);
    // },
    sendFileAdmin() {
      // console.log(this.emailFormData);
      this.$store.dispatch('sendFileAdmin', this.emailFormData);
    },
    previewPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).open();
    },
    printPdf() {
      const document = this.makePdf(this.formData);
      pdfMake.createPdf(document).print();
    },
  },
  validations: {
    formData: {
      building: {
        type: {
          required,
        },
        pitch: {
          required,
        },
        longestRun: {
          required,
        },
        resheet: {
          required,
        },
        bare: {
          required,
        },
        tuck: {
          required,
        },
      },
      contractor: {
        email: {
          required,
        },
        name: {
          required,
        },
        license: {
          required,
        },
        phone: {
          required,
        },
        billingStreet: {
          required,
        },
        billingCityStateZip: {
          required,
        },
      },
      dateRequested: {
        required,
      },
      homeowner: {
        name: {
          required,
        },
        phone: {
          required,
        },
      },
      job: {
        address: {
          required,
        },
        crossStreet: {
          required,
        },
        city: {
          required,
        },
        carried: {
          required,
        },
        groundDrop: {
          required,
        },
        lowTrees: {
          required,
        },
        access: {
          required,
        },
        dogs: {
          required,
        },
        narrowDriveway: {
          required,
        },
        acQty: {
          required,
        },
        skylightsQty: {
          required,
        },
      },
      details: {
        squares: {
          required,
        },
        type: {
          required,
        },
        layers: {
          required,
        },
        stories: {
          required,
        },
        pitch: {
          required,
        },
      },
      signature: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
  #header {
    color: white;
    font-size: 0.5em;
  }
  #brand {
    font-size: 4em;
    font-weight: 600;
    background: cornflowerblue;
    color: white;
    border-radius: 5px;
  }
  .headline {
    font-size: 1.5em;
    font-weight: 800;
  }
  .main-container {
    width: 100vw;
  }
  .form-container {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 2vh;
    padding: 0.5vmax;
  }
  .input-group {
    margin: 5px;
    background: white;
  }
  .full-size {
    width: 100%
  }

  .input-group-text {
    color: white;
    font-weight: 600;
    background: slategrey;
  }
  .name-input {
    width: 500px !important;
  }
  .custom-control-input {
    width: 1.5rem;
  }
  // .i-micro {
  //   width: 225px;
  // }
  // .i-small {
  //   width: 360px;
  // }
  // .i-medium {
  //   width: 450px;
  // }
  .outline-primary {
    background-color: none !important;
    background: none !important;
  }
</style>