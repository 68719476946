/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  ADD_COLLECTION,
  ADD_COLLECTION_SUCCESS,
  ADD_LINKED_RECORD_SUCCESS,
  ADD_TUNING,
  ADD_RECORD,
  ADD_RECORD_SUCCESS,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_INVOICE,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_SUCCESS_MULTI,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_WORKDAY,
  ADDITIONAL_RECORDS_PROCESSING,
  ALL_CUSTOMERS,
  ALL_CUSTOMERS_SUCCESS,
  ALL_INVOICES,
  ALL_INVOICES_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_LOGO,
  CLICK_AWAY,
  CLOCKED_IN,
  CLOCKED_OUT,
  CUSTOMER_BY_ID,
  CUSTOMER_BY_ID_SUCCESS,
  DBVERSION_CLEAR,
  DBVERSION_INC,
  DBVERSION_MISMATCH,
  FILE_UPLOADED,
  FILE_UPLOAD_CLEAR,
  GET_COLLECTION,
  GET_COLLECTION_SUCCESS,
  GET_RECORDS_SUCCESS,
  GET_WORKDAYS_SUCCESS,
  HIDE_SPINNER,
  INITIAL_LOAD,
  INITIAL_LOAD_SUCCESS,
  INVOICE_BY_ID,
  INVOICE_BY_ID_SUCCESS,
  INVOICE_PRINTDATA,
  INVOICE_PRINTDATA_SUCCESS,
  INVOICED_ON_ADD,
  INVOICED_ON_REMOVE,
  INVOICED_ON_SUCCESS,
  ITEM_REFRESH,
  ITEM_REFRESH_DONE,
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_PREFERENCES,
  LOAD_PREFERENCES_SUCCESS,
  MARK_AS_PROCESSED,
  REMOVE_COLLECTION,
  REMOVE_COLLECTION_SUCCESS,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_SUCCESS,
  REMOVE_INVOICE,
  REMOVE_INVOICE_SUCCESS,
  REMOVE_RECORD,
  REMOVE_RECORD_SUCCESS,
  REMOVE_RECORDS,
  REMOVE_RECORDS_SUCCESS,
  REMOVE_TIMECARD_ENTRY,
  REMOVE_TIMECARD_ENTRY_SUCCESS,
  REMOVE_WORKDAY,
  SET_PREFERENCES,
  SET_PREFERENCES_SORT,
  SHOW_ITEM,
  SHOW_ITEM_CLEAR,
  SHOW_SPINNER,
  SHOW_ERROR,
  SHOW_INFO_TOAST,
  SIGNED_IN,
  SIGNED_OUT,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_TIMECARD,
  SUBMIT_TIMECARD_SUCCESS,
  TIMECARD_HISTORY,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICES,
  UPDATE_INVOICES_SUCCESS,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_QTY,
  UPDATE_QTY_SUCCESS,
  UPDATE_WORKDAY,
  ZIP_REQUEST,
  ZIP_REQUEST_DONE,
  ZIP_RESULTS,
  ZIP_RESULTS_ERROR,
} from './mutation-types';

import { s3 } from '@/store/aws';
import {
  // adminApi,
  // adminApiOffline,
  publicApi,
  userApi,
  logApi,
  publicApiOffline,
  userApiOffline,
  logApiOffline,
  offline,
  uspsApiKey,
} from '../../secrets.json';

const auth = function makeAuth(state) {
  if (state.user && !offline) {
    const jwt = state.user
      .getSignInUserSession()
      .getIdToken()
      .getJwtToken();
    const config = {
      headers: {
        authorization: jwt,
        dbversion: state.dbversion,
        sub: state.user.attributes.sub,
        'Content-Type': 'multipart/form-data',
      },
    };
    return config;
  }
  if (offline) {
    const config = {
      headers: {
        authorization: 'test',
        dbversion: state.dbversion,
        sub: 'test',
      },
    };
    return config;
  }
  return null;
};

export const dataActions = {
  addCustomer({ commit, state }, payload) {
    commit(ADD_CUSTOMER);
    axios.post(`${offline ? userApiOffline : userApi}/customers`, payload, auth(state)).then((response) => {
      commit(ADD_CUSTOMER_SUCCESS, response.data.newData);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: payload._id,
        msg: 'Customer record saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'adding the customer record',
          cmd: { run: 'addCustomer', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  addInvoice({ commit, state }, payload) {
    commit(ADD_INVOICE);
    axios.post(`${offline ? userApiOffline : userApi}/invoices`, payload, auth(state)).then((response) => {
      if (Array.isArray(response.data.newData)) {
        commit(ADD_INVOICE_SUCCESS_MULTI, response.data.newData);
      } else {
        commit(ADD_INVOICE_SUCCESS, response.data.newData);
      }
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: payload._id,
        msg: 'Invoice Saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
      commit(ITEM_REFRESH);
    })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'adding the document',
          cmd: { run: 'addInvoice', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  addRecord({ commit, state }, incData) {
    const payload = incData;
    if (state.additionalActions.length) {
      payload.additionalActions = state.additionalActions;
    }
    commit(ADD_RECORD);
    axios.post(`${offline ? userApiOffline : userApi}/records`, payload, auth(state))
      .then((response) => {
        if (response.data.additionalRecords) {
          commit(ADDITIONAL_RECORDS_PROCESSING, response.data.additionalRecords);
        }
        commit(ADD_RECORD_SUCCESS, { record: response.data.newData, collection: payload.collection });
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Record Saved',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the document',
          cmd: { run: 'addRecord', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  addRecordViaLink({ commit, state }, incData) {
    const payload = incData;
    console.log('this one', payload);
    // if (state.additionalActions.length) {
    //   console.log('pushing the additionalActions!');
    //   payload.additionalActions = state.additionalActions;
    // }
    axios.post(`${offline ? userApiOffline : userApi}/records`, payload, auth(state))
      .then((response) => {
        if (response.data.additionalRecords) {
          commit(ADDITIONAL_RECORDS_PROCESSING, response.data.additionalRecords);
        }
        commit(ADD_LINKED_RECORD_SUCCESS, { requestId: payload.requestId, record: response.data.newData, collection: response.data.newData._collection });
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Record Saved',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the document',
          cmd: { run: 'addRecordVieLink', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  addTuning({ commit }, instrument) {
    commit(ADD_TUNING, instrument);
  },
  allCustomers({ commit, state }) {
    commit(ALL_CUSTOMERS);
    axios.get(`${offline ? userApiOffline : userApi}/customers`, auth(state))
      .then((response) => {
        commit(ALL_CUSTOMERS_SUCCESS, response.data);
      })
      .catch((err) => {
        const errInfo = {
          _id: 'allCustomers',
          action: 'getting customer data',
          cmd: { run: 'allCustomers', data: null },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  allInvoices({ commit, state }) {
    commit(ALL_INVOICES);
    axios.get(`${offline ? userApiOffline : userApi}/invoices`, auth(state)).then((response) => {
      commit(ALL_INVOICES_SUCCESS, response.data);
      commit(ITEM_REFRESH);
      if (response.data.newVersion) {
        commit(DBVERSION_INC, response.data.newVersion);
      }
    })
      .catch((err) => {
        const errInfo = {
          action: 'connecting',
          _id: 'allInvoices',
          cmd: { run: 'allInvoices', data: null },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  customerById({ commit, state }, payload) {
    commit(CUSTOMER_BY_ID);
    axios.get(`${offline ? userApiOffline : userApi}/customers/${payload}`, auth(state)).then((response) => {
      commit(CUSTOMER_BY_ID_SUCCESS, response.data);
    })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'getting customer data',
          cmd: { run: 'customerById', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  initialLoad({ commit, state }) {
    const config = auth(state);
    commit(INITIAL_LOAD, config.headers.sub);
    axios.get(`${offline ? userApiOffline : userApi}/initial-load/`, config).then((res) => {
      if (!res.data.versionMatch) {
        commit(ALL_CUSTOMERS_SUCCESS, res.data.customers);
        commit(ALL_INVOICES_SUCCESS, res.data.docs);
        commit(LOAD_ACCOUNT_SUCCESS, res.data.account);
        commit(LOAD_PREFERENCES_SUCCESS, res.data.preferences);
        commit(GET_COLLECTION_SUCCESS, res.data.collections);
        commit(GET_RECORDS_SUCCESS, res.data.records);
        commit(GET_WORKDAYS_SUCCESS, res.data.workDays);
        commit(TIMECARD_HISTORY, res.data.workDaysTimecards);
        // commit(DBVERSION_INC, res.data.dbversion);
      }
      commit(INITIAL_LOAD_SUCCESS);
      commit(ITEM_REFRESH);
    }).catch((err) => {
      console.log(err);
      const errInfo = {
        _id: 'initialLoadFailure',
        action: 'loading program data.',
        cmd: { run: 'initialLoad' },
        error: err.message,
      };
      commit(SHOW_ERROR, errInfo);
    });
  },
  invoicedOn({ commit, state }, payload) {
    commit(INVOICED_ON_ADD, payload);
    const index = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
    const record = state.data.records[payload.collection][index];
    axios
      .put(`${offline ? userApiOffline : userApi}/records/${payload.collection}/${payload.record}`, { record }, auth(state))
      .then((response) => {
        commit(INVOICED_ON_SUCCESS);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Link receipt has been added',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the link receipt',
          cmd: { run: 'invoicedOn', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  invoicedOnRemove({ commit, state }, payload) {
    commit(INVOICED_ON_REMOVE, payload);
    const index = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
    const record = state.data.records[payload.collection][index];
    axios
      .put(`${offline ? userApiOffline : userApi}/records/${payload.collection}/${payload.record}`, { record }, auth(state))
      .then((response) => {
        commit(UPDATE_QTY_SUCCESS);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Link receipt has been added',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the link receipt',
          cmd: { run: 'invoicedOn', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  invoicePrintData({ commit, state }, payload) {
    commit(INVOICE_PRINTDATA, payload);
    axios.get(`${offline ? userApiOffline : userApi}/invoices/${payload}`, auth(state))
      .then((response) => {
        commit(INVOICE_PRINTDATA_SUCCESS, response.data);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'getting document data',
          cmd: { run: 'invoicePrintData', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  itemRefresh({ commit }) {
    commit(ITEM_REFRESH);
  },
  itemRefreshDone({ commit }) {
    commit(ITEM_REFRESH_DONE);
  },
  fileUploaded({ commit }, payload) {
    commit(FILE_UPLOADED, payload);
  },
  fileUploadCancel({ commit, state }) {
    console.log('need to delete processed file on server');
    if (!state.fileUploadToDelete) {
      console.log('checking for file');
      const uploadWatcher = setInterval(() => {
        console.log('looking...');
        if (state.fileUploadToDelete) {
          console.log('found!');
          s3.deleteObject({
            Key: state.fileUploadToDelete,
          }, (err, data) => {
            if (err) {
              console.log('failed', err.message);
            } else {
              console.log('file deleted', data);
              commit(FILE_UPLOAD_CLEAR);
            }
          });
          clearInterval(uploadWatcher);
        }
      }, 500);
      // eslint-disable-next-line no-unused-expressions
      uploadWatcher;
    } else {
      s3.deleteObject({
        Key: state.fileUploadToDelete,
      }, (err, data) => {
        if (err) {
          console.log('failed', err.message);
        } else {
          console.log('file deleted', data);
          commit(FILE_UPLOAD_CLEAR);
        }
      });
    }
  },
  // Martino West Logic
  updateRates({ commit, state }, incData) {
    const payload = incData;
    commit(SHOW_SPINNER);
    axios.post(`${offline ? userApiOffline : userApi}/update-rates`, payload, auth(state))
      .then(() => {
        commit(HIDE_SPINNER);
        console.log('done');
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the document',
          cmd: { run: 'updateRates', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  mwLoadProcessed({ commit, state }, payload) {
    commit(SHOW_SPINNER);
    axios.get(`${offline ? userApiOffline : userApi}/mw-load/${payload.collection}/${payload.start}/${payload.end}`, auth(state)).then((res) => {
      commit('MW_PROCESSED', { records: res.data[payload.collection], collection: payload.collection });
      commit(HIDE_SPINNER);
    }).catch((err) => {
      const errInfo = {
        _id: payload._id,
        action: 'loading processed',
        cmd: { run: 'mwLoadProcessed', data: payload },
        error: err.message,
      };
      commit(SHOW_ERROR, errInfo);
    });
  },
  markAsProcessed({ commit, state }, payload) {
    axios
    .put(`${offline ? userApiOffline : userApi}/records/${payload.collection}`, payload.processed, auth(state))
    .then((res) => {
      console.log('marking records as processed');
      const toastData = {
        _id: payload._id,
        msg: 'Records processed',
      };
      commit(SHOW_INFO_TOAST, toastData);
      commit(MARK_AS_PROCESSED, payload);
      commit(DBVERSION_INC, res.newVersion);
      commit(ITEM_REFRESH);
    })
    .catch((err) => {
      const errInfo = {
        _id: payload._id,
        action: 'updating related documents',
        cmd: { run: 'markAsProcessed', data: payload },
        error: err.message,
      };
      commit(SHOW_ERROR, errInfo);
    });
  },
  removeInvoice({ commit, state }, payload) {
    commit(REMOVE_INVOICE);
    console.log('deleting invoice');
    axios
      .delete(`${offline ? userApiOffline : userApi}/invoices/${payload}`, auth(state))
      .then((res) => {
        const toastData = {
          _id: payload._id,
          msg: 'Invoice has been removed',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(REMOVE_INVOICE_SUCCESS, { removed: payload });
        commit(DBVERSION_INC, res.newVersion);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'deleting the document',
          cmd: { run: 'removeInvoice', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeInvoiceAndModifyOthers({ commit, state }, payload) {
    commit(REMOVE_INVOICE);
    console.log('deleting one invoice and modifying others');
    axios
      .delete(`${offline ? userApiOffline : userApi}/invoices/${payload.toDelete}/`, auth(state))
      .then((res) => {
        const toastData = {
          _id: payload._id,
          msg: 'Invoice has been removed',
        };
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'deleting the document',
          cmd: { run: 'removeInvoiceAndModifyOthers', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
    axios
      .put(`${offline ? userApiOffline : userApi}/invoices`, { invoices: payload.toUpdate }, auth(state))
      .then((res) => {
        console.log('running second command');
        const toastData = {
          _id: payload._id,
          msg: 'Invoice has been removed',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(REMOVE_INVOICE_SUCCESS, { removed: payload });
        commit(DBVERSION_INC, res.newVersion);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'updating related documents',
          cmd: { run: 'removeInvoiceAndModifyOthers', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeCustomer({ commit, state }, payload) {
    commit(REMOVE_CUSTOMER);
    axios
      .delete(`${offline ? userApiOffline : userApi}/customers/${payload}`, auth(state))
      .then((res) => {
        const toastData = {
          _id: payload._id,
          msg: 'Customer record removed',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(REMOVE_CUSTOMER_SUCCESS, { removed: payload });
        commit(DBVERSION_INC, res);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'removing customer',
          cmd: { run: 'removeCustomer', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeRecord({ commit, state }, payload) {
    commit(REMOVE_RECORD);
    axios
      .delete(`${offline ? userApiOffline : userApi}/records/${payload.collection}/${payload.record}`, auth(state))
      .then((response) => {
        const toastData = {
          _id: payload._id,
          msg: 'Record removed',
        };
        if (response.data.additionalRecords) {
          commit(ADDITIONAL_RECORDS_PROCESSING, response.data.additionalRecords);
        }
        commit(SHOW_INFO_TOAST, toastData);
        commit(REMOVE_RECORD_SUCCESS, payload);
        commit(DBVERSION_INC, response.data.newVersion);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'removing record',
          cmd: { run: 'removeRecord', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeRecords({ commit, state }, payload) {
    // Remove multiple records

    // if (linkedField && linksChosen) {
    //   remove from linksChosen
    // }

    commit(REMOVE_RECORDS);
    const config = auth(state);
    config.headers = { ...config.headers, deletereq: payload.records };
    console.log('deleting records');
    axios.delete(`${offline ? userApiOffline : userApi}/records/${payload.collection}`, config)
      .then((response) => {
        if (response.data.additionalRecords) {
          commit(ADDITIONAL_RECORDS_PROCESSING, response.data.additionalRecords);
        }
        commit(DBVERSION_INC, response.data.newVersion);
        console.log('deleted records');
        commit(REMOVE_RECORDS_SUCCESS, payload);
        const toastData = {
          _id: payload._id,
          msg: 'Records deleted',
        };
        commit(SHOW_INFO_TOAST, toastData);
        if (response.data.mismatch) {
          commit(DBVERSION_MISMATCH);
        }
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'removing records',
          cmd: { run: 'removeRecords', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  sendFile({ commit, state }, payload) {
    commit(SUBMIT_FORM);
    axios.post(`${offline ? publicApiOffline : publicApi}/valleydemo/customer`, payload, auth(state))
      .then(() => {
        commit(SUBMIT_FORM_SUCCESS);
        const toastData = {
          _id: payload._id,
          msg: 'Email Sent Successfully!',
        };
        commit(SHOW_INFO_TOAST, toastData);
        // console.log('deleting file from s3 bucket');
        // s3.deleteObject({
        //   Key: state.fileUploadToDelete,
        // }, (err, data) => {
        //   if (err) {
        //     console.log('failed', err.message);
        //   } else {
        //     console.log('file deleted', data);
        //     commit(FILE_UPLOAD_CLEAR);
        //   }
        // });
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'sending the message',
          cmd: payload,
          error: `${err.message} -- Your message was not sent!`,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  sendFileAdmin({ commit, state }, payload) {
    commit(SUBMIT_FORM);
    axios.post(`${offline ? publicApiOffline : publicApi}/valleydemo/admin`, payload, auth(state))
      .then((res) => {
        commit(SUBMIT_FORM_SUCCESS);
        const toastData = {
          _id: payload._id,
          msg: 'Email Sent Successfully!',
        };
        commit(SHOW_INFO_TOAST, toastData);
        console.log(res);
        // console.log('deleting file from s3 bucket');
        // s3.deleteObject({
        //   Key: state.fileUploadToDelete,
        // }, (err, data) => {
        //   if (err) {
        //     console.log('failed', err.message);
        //   } else {
        //     console.log('file deleted', data);
        //     commit(FILE_UPLOAD_CLEAR);
        //   }
        // });
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'sending the message',
          cmd: payload,
          error: `${err.message} -- Your message was not sent!`,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  updateCustomer({ commit, state }, payload) {
    commit(UPDATE_CUSTOMER);
    const toastData = {
      _id: payload._id,
      msg: 'Updated customer record',
    };
    axios
      .put(`${offline ? userApiOffline : userApi}/customers/${payload._id}`, payload, auth(state))
      .then((res) => {
        commit(SHOW_INFO_TOAST, toastData);
        commit(UPDATE_CUSTOMER_SUCCESS);
        commit(DBVERSION_INC, res.data.newVersion);
      })
      .catch((err) => {
        toastData.action = 'updating the customer';
        toastData.error = err.message;
        toastData.cmd = { run: 'updateCustomer', data: payload };
        commit(SHOW_ERROR, toastData);
      });
  },
  updateInvoice({ commit, state }, payload) {
    commit(UPDATE_INVOICE, payload._id);
    const needRefresh = payload.needRefresh;
    axios
      .put(`${offline ? userApiOffline : userApi}/invoices/${payload._id}`, payload, auth(state))
      .then((response) => {
        commit(UPDATE_INVOICE_SUCCESS);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Invoice has been updated',
        };
        if (needRefresh) {
          console.log('refreshing list');
          commit(ITEM_REFRESH);
        }
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'updating the document',
          cmd: { run: 'updateInvoice', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  updateMultipleInvoices({ commit, state }, payload) {
    commit(UPDATE_INVOICES);
    const needRefresh = payload.needRefresh;
    axios
      .put(`${offline ? userApiOffline : userApi}/invoices`, payload, auth(state))
      .then((response) => {
        console.log(response);
        commit(UPDATE_INVOICES_SUCCESS);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Invoice has been updated',
        };
        if (needRefresh) {
          console.log('refreshing list');
          commit(ITEM_REFRESH);
        }
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'updating the documents',
          cmd: { run: 'updateMultipleInvoices', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  updateRecord({ commit, state }, incoming) {
    const payload = incoming;
    if (state.additionalActions.length) {
      payload.additionalActions = state.additionalActions;
    }
    commit(UPDATE_RECORD, payload._id);
    axios
      .put(`${offline ? userApiOffline : userApi}/records/${payload.collection}/${payload.record._id}`, payload, auth(state))
      .then((response) => {
        if (response.data.additionalRecords) {
          commit(ADDITIONAL_RECORDS_PROCESSING, response.data.additionalRecords);
        }
        commit(UPDATE_RECORD_SUCCESS, payload);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Record has been updated',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'updating the record',
          cmd: { run: 'updateRecord', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  updateQty({ commit, state }, payload) {
    if (payload.addLink) {
      commit(INVOICED_ON_ADD, payload);
    }
    if (payload.removeLink) {
      commit(INVOICED_ON_REMOVE, payload);
    }
    commit(UPDATE_QTY, payload);
    const index = state.data.records[payload.collection].findIndex(i => i._id === payload.record);
    const record = state.data.records[payload.collection][index];
    axios
      .put(`${offline ? userApiOffline : userApi}/records/${payload.collection}/${payload.record}`, { record }, auth(state))
      .then((response) => {
        if (payload.addLink || payload.removeLink) {
          commit(INVOICED_ON_SUCCESS);
        }
        commit(UPDATE_QTY_SUCCESS);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Linked Qty has been updated',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'updating the linked record Qty',
          cmd: { run: 'updateQty', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
};

export const workDayActions = {
  addWorkDay({ commit, state }, payload) {
    // Save new workday
    const workDay = payload;
    delete workDay._id;
    axios.post(`${offline ? userApiOffline : userApi}/workdays/`, workDay, auth(state)).then((response) => {
      commit(ADD_WORKDAY, response.data.workDay);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'WorkDay data saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'saving shift data',
          cmd: { run: 'addWorkDay', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  clockIn({ commit, state }, payload) {
    axios.post(`${offline ? userApiOffline : userApi}/timeclock/`, payload, auth(state)).then((response) => {
      commit(CLOCKED_IN, response.data);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'Clocked in',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'saving clock in',
          cmd: { run: 'clockIn', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  clockOut({ commit, state }, payload) {
    axios.put(`${offline ? userApiOffline : userApi}/timeclock/`, payload, auth(state)).then((response) => {
      commit(CLOCKED_OUT, response.data);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'Clocked out',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: 'none',
          action: 'clocking out',
          cmd: { run: 'clockOut', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeTimecardEntry({ commit, state }, payload) {
    commit(REMOVE_TIMECARD_ENTRY);
    axios.delete(`${offline ? userApiOffline : userApi}/timeclock/${payload}`, auth(state)).then((response) => {
      commit(REMOVE_TIMECARD_ENTRY_SUCCESS, payload);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'Clocked out',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'clocking out',
          cmd: { run: 'removeTimecardEntry', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeWorkDay({ commit, state }, id) {
    // Save existing workday
    commit(REMOVE_WORKDAY, id);
    axios.delete(`${offline ? userApiOffline : userApi}/workdays/${id}`, auth(state)).then((response) => {
      // commit(UPDATE_ACCOUNT_SUCCESS);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'WorkDay removed',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'saving shift data',
          cmd: { run: 'removeWorkDay', data: id },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  submitTimecard({ commit, state }, payload) {
    commit(SUBMIT_TIMECARD, payload);
    axios.post(`${offline ? userApiOffline : userApi}/timecard/`, payload, auth(state)).then((response) => {
      commit(SUBMIT_TIMECARD_SUCCESS);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'Timecard saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'saving timecard',
          cmd: { run: 'submitTimecard', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  timecardHistory({ commit, state }) {
    const config = auth(state);
    axios.get(`${offline ? userApiOffline : userApi}/timecard/${config.headers.sub}`, config).then((res) => {
      if (!res.data.versionMatch) {
        commit(TIMECARD_HISTORY, res.data.employeeLogins);
      }
      commit(ITEM_REFRESH);
    }).catch((err) => {
      console.log(err);
      const errInfo = {
        _id: 'error',
        action: 'gettingTimecardHistory.',
        cmd: { run: 'timecardHistory' },
        error: err.message,
      };
      commit(SHOW_ERROR, errInfo);
    });
  },
  updateTimecardEntry({ commit, state }, payload) {
    axios.put(`${offline ? userApiOffline : userApi}/timeclock/`, payload, auth(state)).then((response) => {
      commit(CLOCKED_OUT, response.data);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'Clocked out',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: 'none',
          action: 'clocking out',
          cmd: { run: 'updateTimecardEntry', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  updateWorkDay({ commit, state }, payload) {
    commit(UPDATE_WORKDAY, payload);
    axios.put(`${offline ? userApiOffline : userApi}/workdays/${payload._id}`, payload, auth(state)).then((response) => {
      // commit(UPDATE_ACCOUNT_SUCCESS);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: 'none',
        msg: 'WorkDay data saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: 'none',
          action: 'saving shift data',
          cmd: { run: 'updateWorkDay', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
};

export const utilityActions = {
  accountUpdate({ commit, state }, payload) {
    commit(UPDATE_ACCOUNT, payload);
    axios.put(`${offline ? userApiOffline : userApi}/account`, state.account, auth(state)).then((response) => {
      commit(UPDATE_ACCOUNT_SUCCESS);
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: payload._id,
        msg: 'Account settings saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'updating the collection',
          cmd: { run: 'accountUpdate', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
    console.log('ACCOUNT UPDATE', payload);
  },
  addUser({ commit, state }, payload) {
    commit(ADD_COLLECTION);
    axios.post(`${offline ? userApiOffline : userApi}/collections`, payload, auth(state)).then((response) => {
      commit(ADD_COLLECTION_SUCCESS, { newColl: response.data.newColl, databases: response.data.newData.databases });
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: payload._id,
        msg: 'Collection record saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'adding the collection',
          cmd: { run: 'addUser', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  addCollection({ commit, state }, payload) {
    commit(ADD_COLLECTION);
    axios.post(`${offline ? userApiOffline : userApi}/collections`, payload, auth(state)).then((response) => {
      console.log('done');
      commit(ADD_COLLECTION_SUCCESS, { newColl: response.data.newColl, databases: response.data.newData.databases });
      commit(DBVERSION_INC, response.data.newVersion);
      const toastData = {
        _id: payload._id,
        msg: 'Collection record saved',
      };
      commit(SHOW_INFO_TOAST, toastData);
    })
      .catch((err) => {
        console.log('failed', err);
        const errInfo = {
          _id: payload._id,
          action: 'adding the collection',
          cmd: { run: 'addCollection', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  clearLogo({ commit }) {
    commit(CLEAR_LOGO);
  },
  clickAway({ commit }) {
    commit(CLICK_AWAY);
  },
  comingSoon({ commit }) {
    const toastData = {
      _id: 1234,
      msg: 'Coming Soon!',
    };
    commit(SHOW_INFO_TOAST, toastData);
  },
  contactForm({ commit }, payload) {
    commit(SUBMIT_FORM);
    axios.post(`${offline ? publicApiOffline : publicApi}/util/contactForm`, payload);
  },
  getCollections({ commit, state }) {
    commit(GET_COLLECTION);
    axios.get(`${offline ? userApiOffline : userApi}/collections`, auth(state))
      .then((res) => {
        commit(GET_COLLECTION_SUCCESS, res.data);
        if (res.data.newVersion) {
          commit(DBVERSION_INC, res.data.newVersion);
        }
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: 'cantLoadPrefs',
          action: 'getting collections',
          cmd: { run: 'getCollections' },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  hideSpinner({ commit }) {
    commit(HIDE_SPINNER);
  },
  preferenceLoad({ commit, state }) {
    commit(LOAD_PREFERENCES);
    axios.get(`${offline ? userApiOffline : userApi}/preferences/${state.user.attributes.sub}`, auth(state))
      .then((res) => {
        const prefs = res.data;
        commit(LOAD_PREFERENCES_SUCCESS, res.data);
        if (res.data.newVersion) {
          commit(DBVERSION_INC, res.data.newVersion);
        }
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: 'cantLoadPrefs',
          action: 'getting user preferences',
          cmd: { run: 'preferenceLoad' },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  preferenceSet({ commit, state }, payload) {
    commit(SET_PREFERENCES, payload);
    axios
      .put(`${offline ? userApiOffline : userApi}/preferences/${payload._id}`, payload, auth(state))
      .then((res) => {
        commit(DBVERSION_INC, res.newVersion);
        console.log('saved!');
        const toastData = {
          _id: payload._id,
          msg: 'Preferences saved!',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'saving preferences',
          cmd: { run: 'preferenceSet', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  preferenceSetSort({ commit, state }, payload) {
    commit(SET_PREFERENCES_SORT, payload);
    console.log('saving sort preference');
    const payloadToSend = state.preferences;
    payloadToSend.defaultSort = payload;
    axios
      .put(`${offline ? userApiOffline : userApi}/preferences/${payloadToSend._id}`, payloadToSend, auth(state))
      .then((res) => {
        commit(DBVERSION_INC, res.newVersion);
        console.log('saved!');
        const toastData = {
          _id: payload._id,
          msg: 'Preferences saved!',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'saving preferences',
          cmd: { run: 'preferenceSet', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  removeCollection({ commit, state }, payload) {
    commit(REMOVE_COLLECTION, payload);
    axios
      .delete(`${offline ? userApiOffline : userApi}/collections/${payload}`, auth(state))
      .then((res) => {
        const toastData = {
          _id: payload._id,
          msg: 'Collection record removed',
        };
        commit(SHOW_INFO_TOAST, toastData);
        commit(REMOVE_COLLECTION_SUCCESS, res.data.databases);
        commit(DBVERSION_INC, res.data.dbversion);
        commit(ITEM_REFRESH);
      })
      .catch((err) => {
        const errInfo = {
          _id: payload._id,
          action: 'removing collection',
          cmd: { run: 'removeCollection', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  restartApp({ commit }) {
    commit(DBVERSION_CLEAR);
  },
  sendLogToAdmin({ commit }, record) {
    axios.post(`${offline ? logApiOffline : logApi}/error`, record);
    commit(CLEAR_ERRORS);
  },
  showItem({ commit }, item) {
    commit(SHOW_ITEM, item);
  },
  showItemClear({ commit }) {
    commit(SHOW_ITEM_CLEAR);
  },
  showSpinner({ commit }) {
    commit(SHOW_SPINNER);
  },
  signedIn({ commit }, user) {
    commit(SIGNED_IN, user);
  },
  signedOut({ commit }) {
    commit(SIGNED_OUT);
  },
  updateCollection({ commit, state }, incId) {
    commit(UPDATE_COLLECTION);
    const payload = state.data.collections.filter(c => c._id === incId)[0];
    axios
      .put(`${offline ? userApiOffline : userApi}/collections`, payload, auth(state))
      .then((response) => {
        commit(UPDATE_COLLECTION_SUCCESS, response.data.databases);
        commit(DBVERSION_INC, response.data.newVersion);
        const toastData = {
          _id: payload._id,
          msg: 'Collection has been updated',
        };
        commit(SHOW_INFO_TOAST, toastData);
      })
      .catch((err) => {
        console.log(err);
        const errInfo = {
          _id: payload._id,
          action: 'updating the collection',
          cmd: { run: 'updateCollection', data: payload },
          error: err.message,
        };
        commit(SHOW_ERROR, errInfo);
      });
  },
  zipLookup({ commit }, req) {
    // XML formatting for USPS API
    const request = `
      <CityStateLookupRequest USERID="${uspsApiKey}">
      <ZipCode ID='0'>
      <Zip5>${req.zip}</Zip5>
      </ZipCode>
      </CityStateLookupRequest>
    `;
    commit(ZIP_REQUEST, req);
    axios.get(`https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=${request}`)
      .then((res) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(res.data, 'text/xml');
        const err = xml.children[0].children[0].children[0].tagName === 'Error';
        if (!err) {
          commit(ZIP_RESULTS, {
            city: xml.children[0].children[0].children[1].innerHTML,
            state: xml.children[0].children[0].children[2].innerHTML,
            zip: req.zip,
            reqId: req.id,
          });
        } else {
          commit(ZIP_RESULTS_ERROR, xml.children[0].children[0].children[0].children[2].innerHTML);
        }
      }).catch(err => console.log('ERR', err));
  },
  zipLookupClear({ commit }) {
    commit(ZIP_REQUEST_DONE);
  },
};
