/* eslint-disable max-len */
export const projectUrl = 'http://windsong.io/';

const messages = {
  en: {
    project: {
      url: 'http://localhost:8080',
      urlShort: 'valleyroofremoval.com',
      urlAdmin: 'dashboard',
      brand: 'valleyroofremoval.com',
      brandContact: '',
      brandSubheader: '',
      // brandContactAddressStreet: '2699 Harkness St',
      // brandContactAddressCityState: 'Georgetown, CA 95634',
      // brandContactPhone: '(530) 333-0191',
      // brandContactPhoneEmail: '(530) 333-0191    vince@verdugoroofing.com',
      // brandEmailDisplay: 'Vince Verdugo Roofing <vince@verdugoroofing.com>',
      // brandEmail: 'vince@verdugoroofing.com',
      // brandEmailReplyTo: 'verdugo.roofing@gmail.com',
      // brandEmailNotifications: 'verdugo.roofing@gmail.com', // Notifies for things like contact requests and CCs on reports sent
      // brandNavbar: {
      //   hrefLink: 'tel:707-798-0447',
      //   text: 'eZ',
      //   textBottom: 'Project Demo',
      //   routerLink: '/',
      // },
      defaultAddressType: 'usaFormat',
      calendarLocale: 'en-US',
      timeLocale: 'en',
      defaultTitle: 'MartinoWest',
      banner: {
        top: 'eZ',
        bottom: 'Project Demo',
      },
      navButtons: [
        {
          to: '/tools/invoices',
          label: 'Invoices',
        },
        {
          to: '/tools/customers',
          label: 'Customers',
        },
        {
          to: '/tools/items',
          label: 'Items',
        },
        /* {
          to: '/contact',
          label: 'Contact Us',
        }, */
      ],
      header: 'Since 2004...',
      footerName: 'windsong.io',
      footerInfo: '<a style="color:inherit; text-decoration:inherit" href="mailto:david@windsong.io">Contact</a>',
      techSupport: 'david@windsong.io',
      currencySymbol: '$',
    },
    add: 'Add',
    addAddress: 'Add Second Address',
    address: 'Address',
    addressProject: 'Project Address',
    addressCity: 'City',
    addressState: 'State',
    addressZip: 'Zip',
    addressBilling: 'Billing Address',
    addressBillingRemove: 'Remove this address',
    addressDifferent: 'Use a different billing address?',
    addressEditLabel: 'Edit address label',
    adminCollections: 'Collection Managementment',
    adminCustomerAdd: 'New Customer',
    adminCustomerBillingContact: 'Attention',
    adminCustomerContactDetails: 'Contact Details for this Customer',
    adminCustomerNotes: 'Customer Account Notes',
    adminCustomerOptions: 'Options for',
    adminCustomerRecurringLineItem: 'Recurring Line Items for this Customer',
    adminCustomerShowContact: 'Contact Details',
    adminCustomerShowDetails: 'Account Summary',
    adminCustomerShowNotes: 'Notes',
    adminCustomerShowOptions: 'Options',
    adminCustomerShowRecurring: 'Recurring Charges',
    adminCustomerSince: 'Customer since',
    adminInvoice: 'All Invoices',
    areYouSure: 'Are you sure?',
    aria: {
      addressSecondLine: 'Second line of address',
      addressSecondLineBilling: 'Second line of billing address',
      addressEditLabel: 'Change address label',
      addressDeleteBillingAddress: 'Remove Second address',
      alertWorkDay: 'Alert emplopyees of shift details',
      cancel: 'Cancel',
      cancelAddWorkDay: 'Cancel changes to work day. Go back.',
      clearAllInvoiceFilters: 'Clear all fitlers, returns to the default view.',
      clearCustomerFilter: 'Clear customer filter. View invoices by all customers',
      clearTime: 'Clear time & date entry',
      confirm: 'Confirm',
      copyDataLink: 'Copy the url for this Record.',
      customerSearch: 'Search customer records',
      delete: 'Delete',
      deleteField: 'Delete this field from the collection',
      deleteSegment: 'Remove availability segment',
      deleteWorkDay: 'Remove this work day',
      deleteWorkDayConfirm: 'Confirm Deletion of this work day',
      doneEditing: 'Done making changes',
      download: 'Download file',
      duplicate: 'Duplicate this record',
      edit: 'Edit',
      editWorkDay: 'Edit this work day',
      editSegment: 'Edit availability segment',
      email: 'Send Email',
      fileActions: 'PDF File Actions',
      fileEmail: 'Email File',
      filePrint: 'Print File',
      filePreview: 'Preview File',
      flagInvoice: 'Flag this invoice',
      flagInvoiceFilter: 'Show only flagged invoices',
      footerEnable: 'Enable footer',
      footerDisable: 'Disable footer',
      headerDisable: 'Disable header',
      headerEnable: 'Enable header',
      hideControls: 'Hide Controls',
      hideSelectedDates: 'Hide Selected Dates',
      invoiceType: 'This is the type of record being created, such as Invoice, Estimate, etc.',
      lineItemAdd: 'Add Line Item',
      lineItemAdded: 'Successfully added line item',
      lineItemLocked: 'Can\'t add new line item while status is paid, sent, or past due',
      memoDisable: 'Disable memo',
      memoEnable: 'Enable memo',
      moveDown: 'Move item down',
      moveUp: 'Move item up',
      preview: 'Preview',
      previewClose: 'Close preview',
      removeLink: 'Remove this link',
      selected: 'Selected',
      selectedFalse: 'Not Selected',
      showFutureShifts: 'Show/Hide Future Shifts',
      showInvoice: 'Show invoice',
      showLineItems: 'Show charges',
      showNoteToCustomer: 'Show note to customer',
      showPayments: 'Show payments',
      showOutstanding: 'Show outstanding items',
      showSelectedDates: 'Show selected dates',
      showSettings: 'Show user and program settings',
      showShiftHistory: 'Show/Hide Shift History',
      subheaderDisable: 'Disable subheader',
      subheaderEnable: 'Enable subheader',
      swapSort: 'Reverse sorting order',
      view: 'View item',
      weekBack: 'Move to week before',
      weekForward: 'Move to week after',
    },
    autosaveInProgress: 'Saving changes, please wait...',
    call: 'Call',
    cancel: 'Cancel',
    cancelDateInput: 'Cancel Date Input',
    clear: 'Clear',
    close: 'Close',
    comingSoon: 'Coming soon!',
    collections: {
      _availability: {
        addSegment: 'Add Segment',
        alwaysAvailableTrue: 'Employee is <b>always</b> available, except dates listed below',
        alwaysAvailableFalse: 'Employee is <b>never</b> available, except dates listed below',
      },
      _employees: {
        availability: 'Availability',
        createLogin: 'Create Login',
        resetPassword: 'Reset Password',
        signedUp: 'Signed up',
        tempPass: 'Temporary Password',
        userAccount: 'User Account',
        username: 'Username',
      },
      _employeesLabel: 'WorkDay Employees',
      _employeesFields: {
        employeeId: 'Employee Id',
        active: 'Active',
        availability: 'Availability',
        address: 'Address',
        email: 'Email',
        loginInfo: 'Login Info',
        notes: 'Notes',
        name: 'Name',
        phone: 'Phone',
        shiftsWorked: 'Timecard History',
      },
      _peopleLabel: 'Scheduling People',
      _peopleFields: [
        'Active',
        'Color',
        'Description',
        'Name',
      ],
      _webDbFields: [
        'Active',
        'Code',
        'Rate',
        'Description',
      ],
      _webDbInputFields: [
        'Prospect',
        'Total Payroll',
        'Class Codes',
        'Business Name',
        'Contact',
        'Phone',
        'Email',
        'Xmod',
        'Blended Rate',
        'Blended Rate After Mod',
        'Price',
        'Price After Mod',
        'Processed',
        'Incomplete'
      ],
      _schedulerActive: [
        'Active',
        'Inactive',
      ],
      _webDbActive: [
        'Active',
        'Inactive',
      ],
      _webDbLabel: 'Web DB',
      _webDbInputLabel: 'Website Submissions',
      _servicesLabel: 'Scheduling Services',
      _servicesFields: [
        'Active',
        'Color',
        'Description',
        'Length of Service',
        'Price',
        'Name of Service',
      ],
      addCollection: 'New Collection',
      addField: 'New Field',
      addFieldOptions: 'What kind of Field?',
      addSelectOption: 'Add Option',
      address: {
        add: 'Add',
        expandedByDefault: 'Shows By Default',
        expandedOption: 'Option To Add',
        formatUs: 'U.S. default',
        formatUSA: 'U.S.',
        formatInt: 'Intl.',
        formatInternational: 'Intl. default',
        label: 'Address type',
        labels: {
          addSecondStreet: 'Add second line',
          city: 'City',
          country: 'Country',
          filterCityState: 'Filter by City, State',
          filterCountry: 'Filter by Country',
          filterCityCountry: 'Filter by City, Country',
          filterInt: 'International addresses',
          filterPostal: 'Filter by Postal',
          filterState: 'Filter by State',
          filterUS: 'U.S. addresses',
          postal: 'Postal Code',
          removeSecondStreet: 'Remove',
          state: 'State',
          street: 'Street',
          streetSecond: 'Street #2',
          zip: 'Zip',
        },
        labelSingle: 'Address',
        labelSingleLabel: 'Address type (when it\'s the only address)',
        labelSinglePlaceholder: 'Address Label (optional)',
        labelMulti: 'Shipping Address',
        labelMultiLabel: 'Address type (when multiple addresses exist)',
        labelMultiAlt: 'Billing Address',
        labelMultiPlaceholder: 'Label',
        linkedChoice: {
          whole: 'Entire Address',
          first: 'First Line',
          cityState: 'City & State',
          state: 'State',
          zip: 'Zip/Postal Code',
          cityCountry: 'City, Country',
          country: 'Country',
        },
        remove: 'Remove Address',
      },
      allowNew: 'Allow new entries?',
      allowNewDisabled: 'Disabled',
      allowNewOptional: 'Optional',
      allowNewRequired: 'Required',
      billing: 'Billing',
      byDefault: 'by default',
      collection: 'Collection',
      defaultValue: '(Optional) Default Value',
      defaultValueOnPlaceholder: '\'On\' label (optional)',
      defaultValueOffPlaceholder: '\'Off\' label (optional)',
      defaultValueOn: 'Yes',
      defaultValueOff: 'No',
      deleteConfirm: 'Do you really want to delete this collection? Data will be lost! <h4>This action cannot be undone.</h4><p /><b>If you are sure,</b> type the name of the collection below, and press the the delete button.',
      deletePlaceholder: 'Type the collection name here',
      deleteRequest: 'Delete Collection',
      editCollections: 'Edit Collections',
      fields: 'Fields',
      fieldsToUse: 'Fields to use',
      fieldType: 'Type',
      hasEmail: 'Has Email',
      hasNoEmail: 'No Email',
      hasPhone: 'Has Phone',
      hasNoPhone: 'Has No Phone',
      idCounterPrefix: 'Prefix',
      justAdded: 'Your updated Collection(s) will be ready after a quick restart.',
      justAddedSec: 'Sec.',
      linkedCollection: 'Linked Collection',
      linkedInOtherColl: ['This record is linked in', 'Collections', 'Collection'],
      linkedOn: 'Linked',
      name: 'Collection Name',
      newName: 'Untitled Collection',
      newField: 'New Field',
      notePreviewLength: 'previews',
      notePreviewOptions: {
        sm: 'Short previews',
        md: 'Medium previews',
        lg: 'Long previews',
      },
      notesToShow: 'Note previews shown',
      phoneTypes: {
        label: 'By default, number is...',
        noFormat: 'User-formatted',
        noForm: 'Intl.',
        usaFormat: 'Auto-formatted (U.S. only)',
        usForm: 'U.S.',
      },
      quickEntry: 'Quick Entry Mode',
      removeCollection: 'Delete Collection',
      removeFieldConfirmation: 'Are you sure you want to delete this field? Data may be lost!',
      removeFieldConfirm: 'Delete',
      removeFieldCancel: 'Keep',
      saveNew: 'Saving new entries',
      selectOne: 'Select One',
      selectMulti: 'Select Multiple',
      showLinked: 'Display selected links in',
      showLinkedWhich: 'Which Fields?',
      status: {
        add: 'Add Status',
        labels: {
          hasStatus: 'Has Status',
          hasUrgent: 'Urgent Status',
          noStatus: 'Items without a Status',
        },
        placeholder: 'Enter status',
        urgent: 'Urgent',
        update: 'Update Status',
      },
      toggleOn: 'Yes',
      toggleOff: 'No',
      types: {
        _id: {
          human: 'Sequential numbering',
          guid: 'Globally-unique IDs',
          label: 'Record ID method',
          noId: 'Don\'t show item IDs',
        },
        Address: 'Address',
        Availability: 'Availability',
        Color: 'Color',
        ColorBadge: 'ColorBadge',
        Email: 'Email',
        IdCounter: 'ID',
        Inventory: 'Inventory Counter',
        Link: 'Linked Data',
        LoginInfo: 'Login Info',
        MultiSelect: 'Select Multiple',
        Notes: 'Notes',
        Number: 'Number',
        Phone: 'Phone',
        Price: 'Price',
        SelectOne: 'Select Single',
        Status: 'Status',
        String: 'Text',
        Textarea: 'Text (Long)',
        Tags: 'Tags',
        Timecard: 'Timecard',
        Toggle: 'Toggle',
        addressDesc: 'Address form. USA & International formats.',
        colorDesc: 'Color selection',
        colorBadgeDesc: 'Background & text color selection',
        colorBadgeTypes: ['Background', 'Text'],
        emailDesc: 'A field for email addresses.',
        idCounterDesc: 'Creates an unchanging ID number unique to this collection. You may set an optional prefix.',
        inventoryDesc: 'Adding items to Invoices that use this field can decrease the counter.',
        linkDesc: 'Display data from a different Collection.',
        multiSelectDesc: 'Multiple custom fields. Select any/all.',
        notesDesc: 'A place to keep track of notes about each Record.',
        numberDesc: 'A number field that can be used for any purpose.',
        phoneDesc: 'U.S. or Intl. format. Auto-formatting supported for U.S. numbers.',
        priceDesc: 'A field for entering a price, which can be used to create Invoice Charges.',
        selectOneDesc: 'Multiple custom fields. Select one option per Record.',
        statusDesc: 'A field to quickly enter the latest status of the Record. Statuses can be flagged as urgent.',
        stringDesc: 'A plain text field.',
        tagsDesc: 'A text field for entering small bits of information about the Record. Pressing Enter will confirm the tag. Enter as many tags as you need. They are searchable and filterable.',
        toggleDesc: 'An either-or switch. Labels can be provided for both on and off states.',
        textareaDesc: 'A plain (but large) text field. Allows for Speech To Voice when using Google Chrome.',
      },
    },
    contactUs: 'Contact Us',
    countries: {
      usa: 'USA',
    },
    currencySymbol: '$',
    customer: {
      added: 'Customer record added',
      addedInvoice: 'Added new document',
      clearedPref: 'Deleted',
      clearedPref2: 'customer preference setting',
      clearedPref2a: 'customer preference settings',
      clearedPrefs: 'Cleared customer preferences',
      memoEnable: 'Enable memo',
      noName: 'Unnamed Customer',
      update: {
        addRecurringItem: 'Added recurring billing item',
        billingAddressRemoved: 'Removed billing address',
        preferences: 'Updated customer preferences',
        prefix: 'Changed default document prefix to',
        removedRecurringItem: 'Removed recurring billing item',
      },
      updated: 'Updated customer details',
      updatedPrefs: 'Updated customer preferences',
    },
    date: 'Date',
    dateAdded: 'Added',
    dateBeginning: 'Start',
    dateEnding: 'End',
    delete: 'Delete',
    deleteCancel: 'Cancel Deletion',
    deleteConfirm: 'Confirm Delete',
    deleteCustomer: 'Delete Customer',
    deleteNote: 'Delete Note',
    details: 'Details',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    emailDisabled: 'Can\'t email this file',
    emailHeader: 'Sending File',
    emailMessage: 'Message',
    emailSendTo: 'Send to',
    emailSubject: 'Subject Line',
    emailYour: 'Your Email',
    end: 'End',
    error: {
      fatal: 'This application is having some trouble. You should relaunch and try again. If problem persists, please check your Internet connection.',
      restartButton: 'Relaunch Now',
      retry: 'Retry',
      speechRecognitionBrowser: 'This feature is only available on Google Chrome',
    },
    file: 'File',
    fileAttached: 'File attached:',
    fileDownload: 'Download',
    fileEmail: 'Email',
    filePreview: 'Preview',
    filePrint: 'Print',
    filed: 'Date Filed',
    filter: {
      add: 'Add Filter',
      apply: 'Apply Filter',
      clear: 'Clear Filters',
      show: 'Show Filters',
    },
    find: 'Find',
    from: 'From',
    goBack: 'Go Back',
    goBackSave: ['Save and Go Back', 'to'],
    goHome: 'Home',
    hasBeenUpdated: 'Customer record has been updated.',
    helpMessage: 'If you are experiencing any issues, or have any questions or comments, we would love to hear from you. Please email david@windsong.io, and the developer will respond as promptly as possible.',
    hide: 'Hide',
    hideDetails: 'Hide Details',
    hours: 'hours',
    in: 'in',
    invoice: {
      accountBalance: 'Outstanding Balance',
      adding: 'New Document Details',
      amount: 'Amount',
      attn: 'Attention',
      bal: 'Due',
      created: 'Created document',
      customerSelect: 'Select a Customer',
      customerDontSave: 'One-Time Customer',
      customerNew: 'New Customer',
      customerSave: 'Save Customer',
      customerNoId: 'Unnamed Customer',
      date: 'Date',
      dateCreated: 'Created',
      dateDue: 'Due',
      dateExpires: 'Expires',
      dateInvoice: 'Date of Invoice',
      dateInvoiceSent: 'Invoice Sent on',
      dateInvoiceDue: 'Invoice Due',
      dateOfDoc: 'Document date',
      dateDueHeader: 'Due Date',
      dateProposal: 'Date of Proposal',
      dateProposalExpires: 'Proposal Expires on',
      dateReset: 'Clear Date Records',
      dateResetNotes: 'Reset sent date',
      dateResetNotes2: '& reset due date',
      dateResetProposalNotes: 'Reset proposal expiration date',
      dateSent: 'Sent',
      dateSentInvoice: 'Sent Invoice',
      dateSentProposal: 'Proposal sent on',
      dateUpdated: 'Updated',
      day: 'day',
      daySame: 'Same day',
      days: 'days',
      daysAgo: 'days ago',
      defaultTerms: 'Reset',
      defaultInvoice: 'Statement',
      defaultProposal: 'Proposal',
      defaultType: 'Record',
      delete: 'Delete',
      deleteConfirm: 'Confirm',
      deleteCustomerWarning: 'Really delete this customer record? This action cannot be undone, and will also delete all documents assigned to this customer.',
      desc: 'Description',
      discounts: 'Credits and Adjustments',
      documentInvoice: 'Invoice',
      documentProposal: 'Proposal',
      documentNumber: 'Document #',
      documentNumberCantChange: 'Number is set by Master Numbering option. Disable to use a custom format for this customer.',
      documentNumberCantChangeDoc: 'Number is set by Master Numbering option. Disable to use a custom format for this document.',
      documentDeletedNote: 'was deleted',
      due: 'Due',
      dueOn: 'Due on',
      dueToday: 'Due Today',
      dueYesterday: 'Due Yesterday',
      duplicate: 'Duplicate',
      duplicationNote: 'duplicated from',
      email: {
        subject: 'Your latest invoice',
        greeting: 'Hello',
        body: '\n\nAttached is an invoice for recent work performed.\nLet me know if you need anything else or have any questions.\n\nSincerely,\n\nDavid Verdugo\nWeb & Software Developer\n(707) 798-0447\ndavid@windsong.io',
      },
      error: 'Error. Please try again.',
      expires: 'Expires',
      fax: 'Fax',
      fileDeliver: '📤 Send File',
      filter: {
        all: 'All',
        allCustomers: 'All Customers',
        allCustomersOption: 'Clear Customer Filter',
        allTime: 'All Time',
        by: 'Show',
        day: '1 day',
        day7: '7 days',
        day30: '30 days',
        dayQuarter: '90 days',
        daysFilterLabel: 'Date Range',
        dayCustom: 'Custom Range',
        open: 'Open',
        paid: 'Paid',
        pastDue: 'Past Due',
        pastDay: 'Past Day',
        pastDays: 'Past',
        sent: 'Sent',
      },
      footer: 'Footer',
      hasBeenSent: 'Status set to Sent.',
      hasBeenSentProposal: 'Status of Proposal set to Sent.',
      hasBeenSentMethod: 'Delivery method:',
      hasBeenSentEmail: 'Message & file sent to',
      hasBeenSentMessageCopy: 'Message:',
      header: 'Header',
      hideSystemNotes: 'Hide system notes',
      inactive: 'No active invoices',
      includeAll: 'Include All',
      included: 'Included',
      includeDecline: 'Not Included',
      includeInactive: 'Include',
      includeOptions: 'See Details',
      includeOptionsHide: 'Hide Details',
      invoices: 'Invoices',
      lastActive: 'Last activity',
      lineItems: 'Charges',
      lineItemAdd: 'Add Charge',
      lineItemDesc: 'Description of Charge',
      linked: {
        addLinked: 'Add Linked Charge',
        confirm: 'Add Charge',
        customPrice: 'Other...',
        customQty: 'Do not sync',
        customText: 'Other...',
        linked: 'Linked Data',
        linkedFrom: 'Data from',
        linkedOn: 'Linked on',
        priceOption: 'Price',
        qtyOption: 'Qty.',
        qtySync: ['Sync Qty', 'Update the linked record\'s', 'when updating the Qty field, deleting this line item, or deleting this invoice.', 'If Qty Sync is enabled, whatever number you enter in the Qty field will reduce the linked item\'s inventory by that amount. If you delete the invoice or this line item while Sync Qty is enabled, the linked record\'s inventory will increase by the current Qty.'],
        refresh: 'Refresh Data',
        remove: 'Remove Link',
        removeHeader: 'Remove Data Link',
        removeText: [
          'This will remove the link between this invoice and the record in',
          'The current Description and Price will not be changed, but the record this data came from will not show that it was included in this invoice.',
          'Are you sure?',
        ],
        seeLinked: 'See Linked Record',
        selectColl: 'Select a Collection',
        showLabel: 'Include ',
        syncAvailable: 'available',
        syncQty: 'Sync with',
        syncQtyRemoval: ['Return', 'to', 'in'],
        textOption: 'Text option',
        toBeReturned: ['will be returned to', 'in'],
      },
      locked: 'Locked',
      memo: 'Memo',
      nextDocumentNumber: 'Upcoming document number',
      new: 'New Invoice',
      newInProgress: 'Creating New',
      noDue: 'No Balance Due',
      noPaymentDesc: 'Payment made',
      note: 'Notes',
      noteAdd: 'Add Note',
      notes: '📝 Notes',
      noteShowToCustomer: 'Add to Document',
      number: 'Record #',
      numberOnScreen: 'Invoices in view: ',
      outstanding: 'Due',
      outstandingDue: 'Balance Due',
      outstandingItems: 'Outstanding',
      outstandingItemsHeader: 'Outstanding Charges',
      options: '⚙️ Options',
      page: 'Page',
      paid: 'Paid',
      paidOn: 'Paid on',
      pastDue: 'Outstanding',
      payment: 'Payment',
      paymentAdd: 'Add Payment',
      paymentAppliedFrom: 'Payment applied from overpayment on',
      paymentAppliedTo: 'Overpayment applied to',
      paymentAppliedNote: [
        'Overpayment of',
        'from',
        'to',
      ],
      payments: 'Payments',
      paymentAmount: 'Amount',
      paymentDescription: 'Description of payment',
      paymentDetails: 'Details',
      paymentType: 'Type',
      phone: 'Phone',
      preview: 'Document Preview',
      prefix: 'Account Prefix',
      priceEach: 'Price',
      processing: 'Processing File',
      project: 'Project',
      recurringLineItemsDesc: 'These items will appear on all newly-created documents for this customer, useful for ongoing service agreements and monthly fees.',
      qty: 'Qty',
      save: 'Publish',
      search: {
        all: 'All',
        allSelected: 'All Customers',
        clear: 'Clear',
        recent: 'Recent',
        recentSelected: 'Recent Customers',
        results: 'Search Results',
        resultsEmpty: 'No Results!',
        resultsMain: 'Top Matches',
        resultsOther: 'Other Results',
        top: 'Top',
        topSelected: 'Top Customers',
      },
      showNoteToCustomer: 'Show to customer',
      sort: {
        by: 'Sorting By',
        createdDate: 'Date',
        customerName: 'Customer Name',
        due: 'Balance Due',
        dueDate: 'Due Date',
        number: 'Document Number',
        total: 'Total Charges',
      },
      status: {
        headerLong: 'Recent Activity',
        setTo: 'Status set to',
        setToByUser: 'by user',
      },
      statusOptions: {
        open: 'Open',
        sent: 'Sent',
        paid: 'Paid',
        pastDue: 'Past Due',
        hold: 'Hold',
        proposal: 'Proposal',
        proposalSent: 'Proposal Sent',
      },
      subheader: 'Subheader',
      subtotal: 'Subtotal',
      systemGenerated: '(SYSTEM)',
      systemPayment: 'System',
      taxable: 'Taxable',
      taxDisabled: 'Existing documents will not be affected.',
      taxLabel: '* taxable',
      title: 'Title',
      total: 'Total',
      totalDue: 'Due',
      totalCharges: 'Total Charges',
      totalOutstanding: 'Outstanding Balance',
      totalInvoice: 'Balance Due',
      totalPayments: 'Total Payments',
      totalTax: 'Sales Tax',
      type: 'Type',
      unlockToChange: 'Set Status to Open to make changes.',
      viewTotal: 'Total Charges',
      viewProposed: 'Active Proposals',
      viewPayments: 'Payments',
    },
    keep: 'Keep',
    lastUpdate: 'Updated',
    logIn: 'Log In',
    linkedTo: 'in',
    mainMenu: {
      header: 'Collections',
      pageTitle: 'Main Menu',
    },
    minutes: 'Minutes',
    max: 'Max.',
    maximum: 'Maximum',
    maxPrice: 'Max. price',
    min: 'Min.',
    minimum: 'Minimum',
    minPrice: 'Min. price',
    message: 'Message',
    optional: 'Optional...',
    of: 'of',
    name: 'Name',
    navText: {
      billing: 'Billing',
      collectionAdmin: 'Database Manager',
      workDay: 'Worksite Scheduler',
      timecards: 'Timecards',
    },
    new: 'New',
    next: 'Next',
    note: {
      add: 'Add Note',
      addedBy: 'Added by',
      adding: 'Adding Note',
      new: 'New note',
      note: 'Note',
      notes: 'Notes',
      notesBy: 'Notes by',
      notesByAll: 'Everyone\'s notes',
    },
    ok: 'Ok',
    phone: 'Phone',
    prefs: {
      addressLabel: 'Primary Address label',
      addressSecondaryLabel: 'Secondary Address label',
      alignment: 'Alignment',
      appearsOnLeftTrueLabel: 'Appears on left side',
      appearsOnLeftFalseLabel: 'Appears on right side',
      autoLock: 'Lock items when Status is not Open',
      autoSelectAllEmployees: 'Select all employees when creating a new shift',
      autoSelectDay: 'Select the next available work day when creating a new shift',
      autoStartTime: 'Default Start time',
      autoEndTime: 'Default End time',
      buttonInvoiceMode: 'Invoice Mode',
      buttonProposalMode: 'Proposal Mode',
      changeAppliesToNewDocuments: 'Changing this setting will only affect newly-created documents.',
      chargesHeader: 'Label for \'Charges\'',
      color: 'Color Scheme - Invoices',
      colorProposal: 'Color Scheme - Proposal',
      colorAccent: 'Accent Text',
      colorAccentChosen: 'Accent Text',
      colorBackground: 'Banner Color',
      colorCustom: 'Customize Colors',
      colorCustomHide: 'Hide color options',
      colorCustomLabel: 'Custom',
      colorImpact: 'Header Text',
      colorImpactChosen: 'Header Text',
      colorLabel: 'Color scheme',
      colorProfileCustom: 'Color profile',
      colorProfileCustomLabel: 'Custom',
      colorText: 'Banner Text',
      colorTextChosen: 'Banner',
      customerInfoAppearsOnLeft: 'Customer Name alignment',
      date: 'Date',
      dateLineItemOption: 'Show Dates on charges',
      dateLineItemOptionHelp: '',
      datePaymentOption: 'Show Dates on payments',
      datePaymentOptionHelp: '',
      datesDue: 'Invoice Due Date',
      dateProposalExpires: 'Proposal Expiration Date',
      dateWillBeDue: 'If sent today, due date will be',
      dateWouldBeDue: 'If sent today, due date would be',
      dateWouldBeDue2: 'If created today, due date would default to',
      dateWillExpire: 'If sent today, the expiration date will be',
      dateWouldExpire: 'If sent today, the expiration date would be',
      dateWouldExpire2: 'If created today, the expiration date would default to',
      defaultEmailFrom: 'Sender Name',
      defaultEmailHeader: 'Default Email settings',
      defaultEmailReplyTo: 'Reply-to Address',
      defaultEmailMessage: 'Message body',
      defaultEmailSubjectLine: 'Subject',
      defaultFooter: 'Default footer',
      defaultFooterDoc: 'Footer',
      defaultFooterHelp: '',
      defaultHeader: 'Default Header',
      defaultHeaderDoc: 'Header',
      defaultHeaderHelp: '',
      defaultMemo: 'Default memo',
      defaultMemoDoc: 'Memo',
      defaultMemoHelp: '',
      defaultLabel: 'Default Prefix',
      defaultLabelCust: 'Customer Prefix',
      defaultLabelInvoice: 'Default Invoice Label',
      defaultLabelInvoiceDoc: 'Invoice ',
      defaultLabelInvoiceExample: 'Invoice',
      defaultLabelProposal: 'Default Proposal Label',
      defaultLabelProposalDoc: 'Proposal ',
      defaultLabelProposalExample: 'Proposal',
      defaultPrefix: 'Default Prefix',
      defaultSort: 'Default sorting method',
      defaultSubheader: 'Default Subheader',
      defaultTaxRate: 'Default tax rate for new documents',
      defaultTaxRateDoc: 'Tax rate',
      defaultTaxRateHelp: '',
      defaultTerms: 'Default payment terms:',
      defaultTermsDoc: 'Payment Terms',
      defaultTermsProposal: 'Default Expiration Date:',
      defaultTermsProposalDoc: 'Expiration Date',
      defaultTermsHelp: '',
      defaultTypeHelp: '',
      // displayDiscounts: 'Display discounts',
      // displayDiscountsHelp: '',
      displayTitle: 'Include \'Title\' field in documents',
      displayTitleHelp: '',
      documentIdHeader: 'Document Identification',
      documentNumbering: 'Document Numbering',
      documentNumber: 'Upcoming Document Number',
      documentNumberHeader: 'Document #',
      documentNumberLength: 'Length of number',
      dueDateEnabled: 'Enable Due Date',
      dueDateIsAutomatic: 'Set when Invoice is Sent',
      dueDateIsManual: 'Set manually',
      dueDateIsAutomaticProposal: 'Set when Proposal is Sent',
      dueDateIsManualProposal: 'Set manually',
      enableHeader: 'Show header',
      enableFooter: 'Show footer',
      enableMemo: 'Show memo',
      enableSubheader: 'Show subheader',
      header: {
        customer: 'Default Options for',
        customerSub: 'These values apply to all documents assigned to this customer profile. The options selected here override the general system options for all of this customer\'s documents.',
        document: 'Document Settings',
        documentSub: 'These values apply to only this document:',
        formatting: 'Formatting Options',
        system: 'System Defaults',
        systemSub: 'These values apply to all documents by default, unless the option has been changed inside the customer profile or document settings.',
        systemTab: 'Program Settings',
        systemTabSubheader: 'These settings let you customize how the program operates.',
        user: 'Your Details',
        userSubheader: 'This information will be used in the \'from\' portion of the document.',
        workDay: 'WorkDay Settings',
        workDaySubheader: 'These preferences control default behavior for your WorkDay app.',
      },
      headerAppearsOnLeft: 'Header & Subheader',
      labelFalse: 'No',
      labelTrue: 'Yes',
      logo: {
        add: 'Add logo',
        deleting: 'Deleting..',
        error: 'An error occurred while uploading',
        errorSize: 'This file is too large. Max file size: 3mb',
        header: 'Company Logo',
        remove: 'Delete logo',
        uploading: 'Uploading...',
      },
      markAsSent: 'Change status to Sent when:',
      markAsSentDownload: 'Downloaded',
      markAsSentDownloadLong: 'Auto-update Status when file is Downloaded',
      markAsSentEmail: 'Emailed',
      markAsSentEmailLong: 'Auto-update Status when file is Emailed',
      markAsSentPrint: 'Printed',
      markAsSentPrintLong: 'Auto-update Status when file is Printed',
      masterNumbering: 'Master Numbering',
      miscOptions: 'Miscellaneous Options',
      noteHeader: 'Label for \'Notes\'',
      paymentsHeader: 'Label for \'Payments\'',
      paymentMemo: 'Payment Instructions',
      paymentMemoProposal: 'Proposal Retainer Options',
      payrollEmail: 'Email to Send Payroll Reports',
      payrollEmailMessage: 'Message',
      payrollEmailReplyTo: 'Reply-to Email',
      payrollEmailSubjectLine: 'Subject Line',
      perPage: 'Number of documents per page:',
      proposalExpirationDateEnabled: 'Enable Expiration Date',
      proposalRetainerLabel: 'Retainer is',
      proposalRetainerPrefixLabel: 'Prefix Label',
      proposalRetainerPercentage: 'Percentage',
      proposalRetainerPercentagePlaceholder: '% of total',
      proposalRetainerSetAmount: 'Set Amount',
      proposalRetainerSetAmountPlaceholder: '0.00',
      proposalSignatureHeader: 'Proposal Signature',
      proposalSignatureEnable: 'Add signature section to Proposals',
      qtyOption: 'Show \'Qty.\' field for charges',
      resetButton: 'Reset all settings',
      resetConfirm: 'Delete Preferences',
      resetHeader: 'Revert Settings to System Defaults',
      resetSubheader: 'Below are all the options set for this customer. Select the options you would like to remove, and they will be reverted to system defaults for this customer.',
      resetDeleteConfirm: 'This will clear all preferences & settings for this customer profile. <p class="m-3"/><b />Are you sure? </b>',
      shiftSignInEarly: 'Allow early clock-ins',
      shiftSignInNoShift: 'Allow unscheduled clock-ins',
      shiftSignInMinutesAhead: 'minutes before shift begins',
      shiftSignInAdvance: 'Allow clock-ins for the time of shift',
      shiftSignInNotify: 'Enter a phone number to be notified when employees clock in',
      shiftSignInRetroactive: 'Allow retroactive clock-ins',
      shiftTimekeepingIncrement: 'Timekeeping Increment',
      showDateLabels: 'Display labels next to dates (\'Created\', \'Updated\', \'Sent\')',
      showNoteDate: 'Show Dates on notes',
      showEmployeeId: 'Display employee IDs next to names',
      showPaymentsMemo: 'Display payment instructions',
      showPaymentsMemoProposal: 'Display retainer information',
      showToastOnSuccessfulSave: 'Show notifications when documents are auto-saved',
      showToastOnSuccessfulSaveHelp: '',
      showTimes: 'Display document creation time',
      showTimesHelp: '',
      statusAutoUpdate: 'Status Updates',
      statusAutoUpdateLong: 'Automatically update document Status',
      userName: 'Name',
      userAddress: 'Address',
      userAddressSecondLine: 'Second Line',
      userAddressSecondAdd: 'Add second line',
      userAddressSecondClear: 'Remove line',
      userCity: 'City',
      userState: 'State',
      userZip: 'Zip',
      userEmail: 'Email',
      userFax: 'Fax',
      userLogo: 'Logo',
      userLogoCustom: 'Custom',
      userPhone: 'Phone',
      tab: {
        account: 'Account',
        actions: 'File Actions',
        colorPreset: 'Preset Color Scheme',
        colorCustom: 'Custom Colors',
        default: 'Document',
        header: 'Settings',
        help: 'Help',
        reset: 'Reset',
        system: 'System',
        user: 'Contact Details',
        workDay: 'WorkDay',
      },
      tax: 'Taxes',
      taxRate: 'Tax rate',
      taxOption: 'Enable taxable items on new documents',
      taxOptionDoc: 'Enable tax on this document',
      taxOptionHelp: '',
      thisCustomer: 'this Customer',
      weekStartsOn: 'Payroll Week Begins On',
      workDay: {
        payrollOptions: 'Payroll Options',
        shiftDefaults: 'Shift Creation Default Settings',
        signInOptions: 'Employee Sign-in Permissions',
      },
      zipAutofill: 'Autofill City & State when Zip code is entered',
    },
    preview: 'Preview',
    price: 'Price',
    print: 'Print',
    privacyPolicy: 'Privacy Policy',
    processing: 'Processing...',
    records: {
      adding: 'Adding to',
      addressDisplayOptions: [
        'Whole Address',
        'First Line',
        'City & State',
        'State',
        'Zip',
        'Country',
        'City & Country',
      ],
      changeAddress: 'Update',
      download: 'Download Results',
      downloadReady: 'Download Ready',
      deleteConfirm: [
        'Are you sure you want to delete these',
        'items?',
      ],
      deleteConfirmButton: 'Confirm',
      deleteWarning: 'This action cannot be undone!',
      filter: {
        add: 'Add Filter',
        by: 'Filter By...',
        isBlank: 'No Data',
        isNotBlank: 'Has Data',
        filterResults: 'Filter Results',
        filteredResults: 'Filtered Results',
        options: 'Options',
      },
      invoicedIn: 'Invoiced In',
      invoicedView: 'View Invoice',
      options: {
        dateAdded: 'Date Added',
        dateUpdated: 'Date Updated',
        display: 'Display',
        download: 'Download',
        fields: 'Table Fields',
        perPage: 'Items Per Page',
        perPageWarning: 'Tip: If the application is running slowly, reduce the number of items per page. Less than 50 is recommended.',
      },
      record: 'record',
      records: 'records',
      recordsSelected: 'selected',
      pullReceiptHeader: 'Recent Uses',
      pullReceiptButton: [
        'Hide',
        'Show Recent Uses',
      ],
      pullReceiptView: 'View Invoice',
      pullReceiptDeleted: 'Deleted',
      quickEntry: 'Quick Entry Mode',
      tableOptions: 'Table Options',
      updating: 'Updating',
    },
    recordNumber: 'Record ID',
    refNumber: 'Ref. #',
    scheduler: {
      _timePlaceholder: 'Time',
      addException: 'Add Exception',
      allSameOptions: [
        'All Same',
        'Different',
      ],
      available: 'Available',
      availableAllDay: 'All Day',
      availableBasedOnTime: 'Specific Times',
      availabilityStart: 'Start',
      availabilityEnd: 'End',
      clearSelection: 'Clear',
      confirmDates: 'Next',
      datesSelected: 'dates selected',
      dayOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      generalAvailabilityHeader: 'General Availability',
      notAvailable: 'Not Available',
      regularSchedule: 'Regular Availability',
      segment: {
        add: 'Add Segment',
        confirm: 'Confirm Segment',
      },
      select: 'Select By',
      selectDate: [
        'Select a Date',
        'Select Dates',
      ],
      selectBy: {
        year: 'Year',
        week: 'Week',
        month: 'Month',
      },
      selectSingle: 'Single',
      selectMulti: 'Multiple',
      selectRange: 'Range',
    },
    save: 'Save',
    search: 'Search',
    searchFor: 'Search for...',
    selected: 'Selected',
    selectedAlready: 'currently selected',
    selectedClear: 'Clear Selected',
    selectedDelete: 'Delete Selected',
    sendMsg: 'Send Request',
    send: 'Send',
    settingsShow: 'Settings',
    show: 'Show',
    showDetails: 'Show Details',
    start: 'Start',
    sys: {
      clickDate: 'Click to change date',
      buttonGroup: 'Button Group - Back and Save',
      confirm: 'Confirm',
      confirmDelete: 'Confirm deletion',
      linkQuickEditNotAvailable: 'Can\'t be changed in Quick Edit',
      loading: 'Loading',
      logout: 'Log Out',
      restartApp: 'Restart App',
      noName: 'Setup Needed',
      settings: 'System Settings',
      success: 'Success!',
      wait: 'Please wait...',
    },
    tagNew: 'New tag - Press enter to add',
    tempId: 'Temp. Data',
    to: 'To',
    tooltips: {
      cantChangeLinkedColl: 'Select the Collection containing the linked data you want to display in this Collection',
      cantChangeLinkedColl2: 'Once selected, the Collection cannot be changed.',
      idMethod: 'Select how Record numbers are displayed:',
      idMethodHeader: 'Record Numbering',
      humanId: 'Each Record is assigned a number, starting with 1. \nYou may add an optional prefix. For example: if your prefix is ITM, your first record would be numbered ITM-1.',
      guid: 'The IDs are lengthy and difficult to read, but are unique. \nRegardless of which display option you choose, these IDs are what this software uses to store and retrieve records.',
      labelInvoice: '(optional) Appears on invoices before the document number. Example:',
      labelProposal: '(optional) Appears on proposals before the document number. Example:',
      linkCopied: 'Copy link to clipboard.',
      linkedItemRefresh: [
        'If the record in',
        'has been changed, this will update the Price and Description accordingly.',
      ],
      masterNumbering: '<p>This option determines whether or not all customers share the same numbering system.</p><p>For example, if your current document number is \'doc-001\', and Master Numbering is enabled, the next invoice will be \'doc-002\', regardless of the customer.</p><p>If Master Numbering is disabled, then the next document will be \'doc-002\' <b><i>for that specific customer</i></b>, while other customer profiles will maintain their own numbering system.</p> ',
      noId: 'Record numbering for this collection will be mostly hidden. \nRecommended for collections of data with very few potential duplicates (such as two entries with the same name).',
      quickEntry: 'Quick Entry Mode',
      quickEntrySave: 'When active, clicking the Edit button for a record brings up Quick Entry mode for that record. Enter (or Shift + Enter) saves the record.',
      quickEntrySave2: 'If you are updating a record, clicking the Edit button of a different record will save changes to the first record as well.',
    },
    unsavedChangesConfirm: 'Are you sure you want to leave this page? Your unsaved changes will be lost!',
    use: 'Use',
    useHere: 'Use Here Only',
    userDetails: 'User Details',
    voiceCaptureActive: 'Listening for Audio...',
    workDay: {
      addWorkDay: 'Add Work Day',
      addTimecardEntry: 'Add Entry',
      addTimecardEntryFor: 'Adding Timecard Entry for',
      addTimecardEntrySave: 'Save',
      alertFailed: 'Failed',
      anytimeLogin: 'Unscheduled clock in',
      autoPunchLunch: 'Report Lunch Break',
      buttonChangeDates: 'Change Dates',
      buttonConfirm: 'Confirm',
      buttonGoBack: 'Go Back',
      cellServiceHeader: 'Cell Reception',
      cellServiceNo: 'No',
      cellServiceYes: 'Yes',
      chooseDate: 'Select a Date',
      chooseEmployee: 'Select an Employee',
      clearProjectSelection: 'Clear Project',
      clockInConflict: 'Conflicts with earlier clock-in',
      clockedIn: 'Clocked In',
      clockedInAdvanced: 'Button clicked at',
      clockedOut: 'Clocked Out',
      clockInNotes: 'Add shift note',
      confirmAndSubmit: 'Submit Timecard',
      dateStart: 'Starting',
      dateEnd: 'Ending',
      daysOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      employees: 'Employees',
      employeeNotes: 'Employee notes',
      headers: {
        listView: 'Upcoming Shifts',
      },
      hoursDoubletime: 'Doubletime',
      hoursRegular: 'Regular',
      hoursOvertime: 'Overtime',
      hoursTotal: 'Total Hours',
      logShow: 'Show log',
      logHide: 'Hide log',
      lunchReporetd: 'minute lunch reported',
      manualEntry: 'New Address',
      manualEntryCancel: 'Find Existing Project',
      message: {
        intro: 'Work shift scheduled.\n',
        introToday: 'TODAY\'s shift:',
        introMulti: 'Work shifts scheduled.\n',
        date: 'DATE: ',
        dates: 'DATES: \n',
        startTime: '\n\nSTART: ',
        endTime: '\nEND: ',
        project: '\n\nProject: ',
        shiftNote: '\n\nNOTE: ',
        location: '\nAddress: ',
        noCellService: '\n\nNO CELL SERVICE at job site. Please clock-in before you arrive.',
      },
      noLunch: 'No meal break recorded',
      noShift: 'Unscheduled',
      noShiftsToday: 'No shifts scheduled today',
      note: 'Note',
      payPeriodBiweekly: 'Bi-weekly',
      payPeriodWeekly: 'Weekly',
      periodTotal: 'Period Total:',
      projectsList: 'Select a Project',
      projectNew: 'Enter Address',
      projectName: 'Name',
      projectTitle: 'Project Title',
      retroactive: 'Timecard Entry',
      runPayroll: 'Generate Payroll Report',
      search: 'Search',
      searchingProjects: 'Searching Projects',
      secondsLeftToCancel: 'Cancel Sign-in',
      selectEmployee: 'Select an Employee',
      shiftEndTime: 'End Time',
      shiftNotes: 'Shift Notes',
      shiftProject: 'Project',
      shiftEndsAt: 'End',
      shiftStartsAt: 'Start',
      shiftStartTime: 'Start Time',
      tables: {
        byEmployee: 'By Employee',
        recent: 'All Clock-ins',
      },
      timecardButton: [
        'Sign In',
        'Sign Out',
        'now',
        'at',
      ],
      timecardLog: {
        adminClockIn: 'Timecard entry made by admin',
        adminUpdate: 'Admin changed entry.',
        clearClockIn: 'Clock in time removed',
        clearClockOut: 'Clock out time removed',
        shiftEndOriginal: 'Original End:',
        shiftStartOriginal: 'Original Start:',
        shiftEndUpdated: 'Updated End:',
        shiftStartUpdated: 'Updated Start:',
      },
      timesheetsHeader: 'Timesheets',
      title: 'WorkDay',
      titles: {
        empAdmin: 'Employee Admin',
        empSettings: 'Employer Settings',
        employeeTimesheets: 'Timesheet ',
        timeCard: 'Timecard',
        timeCardOptions: 'Timecard Options',
        timeSheetsAdmin: 'Timecards',
        workDayAdmin: 'Work Day',
        workDayDash: 'Work Day',
      },
      viewOlder: 'Older',
      viewNewer: 'Newer',
      weekBack: 'Earlier',
      weekEnding: 'Ending',
      weekForward: 'Later',
      weekOf: 'Week of',
      workDayAdd: 'Adding New Work Day',
      workDayEdit: 'Editing Work Day',
      yourShiftsToday: 'Today\'s shifts',
      yourShiftsFuture: 'future shifts',
      yourShiftHistory: 'Timecard History',
    },
  },
};

export default messages;
