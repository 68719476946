<template>
  <b-tabs
      fill
      pills
      vertical
      v-model="activeTab"
      class="preferences__tabs"
      content-class="preferences__tabs__content">
    <b-tab
        id="invoiceDefaults"
        key="default"
        :title="$t('prefs.tab.default')"
        v-if="enable.default">
      <b-row
          class="form__header">
        <b-col
            cols="auto">
          <h4
              v-text="headerText"
          />
          <small
              v-text="headerSubtext"
          />
        </b-col>
      </b-row>
      <b-row
          v-if="enable.document"
          align-h="end">
        <b-col
            id="deleteAndDuplicateInvoice"
            cols="auto">
          <b-input-group
              class="form__container">
            <b-input-group-prepend
                class="form__label"
                v-text="$t('invoice.delete')"
            />
            <transition
                name="fadeQuick"
                mode="out-in">
              <b-btn
                  key="deleteButton"
                  :id="`delete-${model._id}`"
                  v-if="!deleteInvoiceRequest"
                  @click.stop="deleteInvoiceRequest = true"
                  variant="danger"
                  class="invoice__fileBadge__options__button__delete">
                <b-icon
                    icon="trash"
                    :aria-label="$t('aria.delete')"
                    size="lg"
                    color="white"
                />
                <loader-overlay
                    v-if="$store.state.showSpinner"
                    type="bars"
                    color="white"
                    :h="25"
                    :w="25"
                    :fullPage="false"
                />
              </b-btn>
              <b-btn-group
                  v-else
                  key="deleteRequest"
                  class="my-auto">
                <b-btn
                    :id="`deleteCancel-${model._id}`"
                    @click.stop="deleteInvoiceCancel()"
                    size="sm"
                    variant="secondary"
                    v-text="$t('cancel')"
                    class="invoice__fileBadge__buttons__delete__cancel"
                />
                <b-btn
                    :id="`deleteInvoiceConfirm-${model._id}`"
                    @click.stop="deleteInvoice(model._id)"
                    size="sm"
                    variant="warning"
                    v-text="$t('invoice.deleteConfirm')"
                    class="invoice__fileBadge__buttons__delete__confirm"
                />
              </b-btn-group>
            </transition>
          </b-input-group>
        </b-col>
        <b-col
            cols="auto">
          <b-input-group
              v-if="!deleteInvoiceRequest"
              class="form__container">
            <b-input-group-prepend
                class="form__label"
                v-text="$t('invoice.duplicate')"
            />
            <b-btn
                :id="`duplicate-${model._id}`"
                @click.stop="duplicateInvoice(model)"
                variant="secondary"
                class="invoice__fileBadge__options__button__duplicate">
              <b-icon
                  icon="box-arrow-up"
                  :aria-label="$t('aria.duplicate')"
                  size="lg"
                  color="white"
              />
              <loader-overlay
                  v-if="$store.state.showSpinner"
                  type="bars"
                  color="white"
                  :h="25"
                  :w="25"
                  :fullPage="false"
              />
            </b-btn>
          </b-input-group>
        </b-col>
      </b-row>
      <hr />
      <div
          class="form__container">
        <label
            for="documentNumbering"
            v-text="$t('prefs.documentIdHeader')"
            class="form__header"
        />
        <b-row
            id="documentNumbering">
          <b-col
              align="left"
              class="my-auto">
            <b-row
                align-h="center">
              <b-col
                  cols="*"
                  align="center">
                <b-btn-group
                    class="text-center my-auto">
                  <b-btn
                      id="invoiceSettingsButton"
                      @click="proposalMode = !proposalMode"
                      :variant="!proposalMode ? 'info' : 'info-outline'"
                      v-text="$t('prefs.buttonInvoiceMode')"
                      class="ml-auto mr-2"
                  />
                  <b-btn
                      id="proposalSettingsButton"
                      @click="proposalMode = !proposalMode"
                      :variant="proposalMode ? 'info' : 'info-outline'"
                      v-text="$t('prefs.buttonProposalMode')"
                      class="mr-auto ml-2"
                  />
                </b-btn-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
              align="right"
              class="m-2">
            <b-form-checkbox
                v-model="preferences.masterNumbering"
                name="enableDueDate"
                @input="saveSettings('masterNumbering', preferences.masterNumbering)"
                switch
                class="form__checkbox"
                size="lg">
              {{$t('prefs.masterNumbering')}}
              <b-icon-question-circle-fill
                  id="refreshDataTooltip"
                  font-scale="0.8"
              />
            </b-form-checkbox>
            <b-tooltip
                target="refreshDataTooltip">
              <span
                  v-html="$t('tooltips.masterNumbering')"
              />
            </b-tooltip>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              align="center">
            <!-- display the next document number to be used -->
            <span
                v-if="!enable.document"
                class="form__header">
              {{$t('invoice.nextDocumentNumber')}}:
              <b
                  class="preferences__invoice__numberDisplay">
                {{invoiceNumber}}
              </b>
            </span>
            <!-- or, display the existing document number -->
            <span
                v-else
                class="form__header">
              <b
                  class="ml-1">
                {{displayDocumentNumberWithPrefix(model, preferences)}}
              </b>
            </span>
          </b-col>
        </b-row>
        <b-row
              id="docNumberRow"
              v-if="!preferences.masterNumbering || enable.account"
              align-h="center">
          <b-col
              id="documentIdCol"
              align="center"
              cols="auto">
            <div
                v-if="!enable.document"
                id="documentNumbering">
              <b-input-group
                  id="prefixInputGroup">
                <b-input-group-prepend
                    for="prefixInput"
                    v-text="prefixLabel"
                    class="form__label"
                />
                <b-form-input
                    id="prefixInput"
                    type="text"
                    v-model="preferences.defaultPrefix"
                    @input="saveSettings('defaultPrefix', preferences.defaultPrefix)"
                    class="form__input medium"
                />
              </b-input-group>
              <b-input-group
                  class="my-2">
                <b-input-group-prepend
                    for="documentNumberLength"
                    v-text="$t('prefs.documentNumberLength')"
                    class="form__label"
                />
                <b-form-spinbutton
                    id="documentNumberLength"
                    type="number"
                    wrap
                    min="1"
                    max="10"
                    v-model.number="preferences.documentNumberLength"
                    @input="saveSettings('documentNumberLength', preferences.documentNumberLength)"
                    debounce="500"
                    class="form__input smallish"
                />
              </b-input-group>
              <b-input-group
                  class="my-2">
                <b-input-group-prepend
                    for="documentNumberLength"
                    v-text="$t('prefs.documentNumber')"
                    class="form__label"
                />
                <b-form-input
                    id="documentNumbering"
                    type="number"
                    step="1"
                    max="9999999999"
                    v-model.number="preferences.documentNumber"
                    class="form__input smallish ml-3"
                    @input="saveSettings('documentNumber', preferences.documentNumber)"
                />
              </b-input-group>
            </div>
            <div
                id="documentNumberAndTitle"
                v-else>
              <b-input-group
                  id="changeInvoiceNumber"
                  v-if="enable.document">
                <b-input-group-prepend
                    for="editInvoiceNumberInput"
                    class="form__label"
                    v-text="documentNumberPrefix"
                />
                <b-form-input
                    id="editInvoiceNumber"
                    v-model="model.number"
                    @input="updateModel()"
                    class="form__input large"
                />
              </b-input-group>
              <b-input-group
                  id="changeDocumentTitle"
                  class="my-2">
                <b-input-group-prepend
                    for="documentTitleInput"
                    class="form__label"
                    v-text="$t('invoice.title')"
                />
                <b-form-input
                    id="documentTitleInput"
                    v-model="model.title"
                    :placeholder="$t('optional')"
                    @input="updateModel()"
                    class="form__input xl"
                />
              </b-input-group>
            </div>
            <hr />
            <b-input-group
                id="invoicePrefixContainer"
                v-if="!proposalMode">
              <b-input-group-prepend
                  for="defaultLabelInvoiceInput"
                  class="form__label">
                <span>
                  {{!enable.document ? $t('prefs.defaultLabelInvoice') : $t('prefs.defaultLabelInvoiceDoc')}}
                  <b-icon-question-circle-fill
                      id="invoiceLabelToolTip"
                      font-scale="0.8"
                  />
                </span>
              </b-input-group-prepend>
              <b-form-input
                  id="defaultLabelInvoiceInput"
                  type="text"
                  v-model="preferences.defaultLabelInvoice"
                  @input="saveSettings('defaultLabelInvoice', preferences.defaultLabelInvoice)"
                  :placeholder="$t('optional')"
                  class="form__input medium"
              />
            </b-input-group>
            <b-input-group
                id="proposalPrefixContainer"
                v-else>
              <b-input-group-prepend
                  for="defaultLabelProposalInput"
                  class="form__label">
                <span>
                  {{!enable.document ? $t('prefs.defaultLabelProposal') : $t('prefs.defaultLabelProposalDoc')}}
                  <b-icon-question-circle-fill
                      id="proposalLabelToolTip"
                      font-scale="0.8"
                  />
                </span>
              </b-input-group-prepend>
              <b-form-input
                  id="defaultLabelProposalInput"
                  type="text"
                  v-model="preferences.defaultLabelProposal"
                  @input="saveSettings('defaultLabelProposal', preferences.defaultLabelProposal)"
                  :placeholder="$t('optional')"
                  class="form__input medium"
              />
            </b-input-group>
            <b-tooltip
                v-if="!proposalMode"
                target="invoiceLabelToolTip">
              <span
                  v-text="$t('tooltips.labelInvoice')"
              />
              <br />
              <span
                  v-text="preferences.defaultLabelInvoice
                    && !isBlank(preferences.defaultLabelInvoice)
                    ? preferences.defaultLabelInvoice : $t('prefs.defaultLabelInvoiceExample')"
                  class="font-weight-bold"
              />
              <span
                  v-text="model && model.number ? `#${model.number}` : `#${invoiceNumber}`"
              />
            </b-tooltip>
            <b-tooltip
                v-else
                target="proposalLabelToolTip">
              <span
                  v-text="$t('tooltips.labelProposal')"
              />
              <br />
              <span
                  v-text="preferences.defaultLabelProposal
                    && !isBlank(preferences.defaultLabelProposal)
                    ? preferences.defaultLabelProposal : $t('prefs.defaultLabelProposalExample')"
                  class="font-weight-bold"
              />
              <span
                  v-text="model && model.number ? `#${model.number}` : `#${invoiceNumber}`"
              />
            </b-tooltip>
          </b-col>
        </b-row>
        <b-row
            v-else>
          <b-col
              id="documentIdCantBeChanged"
              align="center"
              cols="auto"
              class="mx-auto">
            <small
                v-text="enable.document ? $t('invoice.documentNumberCantChangeDoc') : $t('invoice.documentNumberCantChange')"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Due Date Options for Invoices -->
      <div
          v-if="!proposalMode"
          class="form__container">
        <label
            for="datePreferenceContainer"
            v-text="$t('prefs.datesDue')"
            class="form__header"
        />
        <b-row
            id="dateOptionsRow"
            align-h="center">
          <b-col
              cols="auto">
            <div
                id="datePreferencesContainer">
              <b-row>
                <b-col>
                  <b-form-checkbox
                      v-model="preferences.dueDateEnabled"
                      name="enableDueDate"
                      @input="saveSettings('dueDateEnabled', preferences.dueDateEnabled)"
                      switch
                      class="form__checkbox"
                      size="lg">
                    {{$t('prefs.dueDateEnabled')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row
                  v-if="preferences.dueDateEnabled">
                <b-col>
                  <b-form-radio-group
                      id="automaticOrManualDueDate"
                      :options="typeOfDueDate"
                      stacked
                      v-model="preferences.dueDateIsAutomatic"
                      @input="saveSettings('dueDateIsAutomatic', preferences.dueDateIsAutomatic)"
                      class="m-2 ml-3 mb-1"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <transition
              mode="out-in"
              name="fadeQuick">
            <b-col
                cols="auto"
                v-if="preferences.dueDateEnabled">
              <transition
                  mode="out-in"
                  name="fadeQuick">
                <div
                    key="paymentTerms">
                  <b-input-group
                      id="defaultTermsInputGroup"
                      v-if="(enable.document && preferences.dueDateIsAutomatic) || !enable.document">
                    <b-input-group-prepend
                        v-text="!enable.document ? $t('prefs.defaultTerms') : $t('prefs.defaultTermsDoc')"
                        class="form__label"
                    />
                    <b-form-input
                        id="defaultTermsInput"
                        type="number"
                        :min="0"
                        v-model.number="terms"
                        class="form__input smallInput ml-2"
                    />
                    <b-input-group-append
                        class="my-auto ml-2"
                        v-text="$t('invoice.days')"
                    />
                  </b-input-group>
                  <div
                      v-else-if="enable.document">
                    <b-input-group
                        id="dueDateOption"
                        v-if="!changeDateDue">
                      <b-input-group-prepend
                          v-text="$t('invoice.date')"
                          class="invoice__fileBadge__options__label mx-2"
                      />
                      <b-btn
                          id="displayDateOfDocument"
                          v-if="!changeDate"
                          v-text="displayDate(dateDocument)"
                          class="invoice__fileBadge__options__button"
                          @click="changeDateButton"
                      />
                      <b-form-input
                          id="changeDueDateInput"
                          v-if="changeDate"
                          v-model="model.date.invoice.dated"
                          type="date"
                          @change="checkInvoiceDate()"
                          @click.stop
                          class="invoice__fileBadge__options__input__small"
                      />
                      <b-input-group-append
                          v-if="changeDate"
                          v-text="$t('cancel')"
                          @click="changeDate = null"
                          style="cursor: pointer"
                          class="text-italic ml-2 my-auto small"
                      />
                    </b-input-group>
                    <b-input-group
                        id="dueDateOption"
                        v-if="!changeDate">
                      <b-input-group-prepend
                        v-text="$t('invoice.dateDue')"
                        class="invoice__fileBadge__options__label"
                      />
                      <b-btn
                          id="displayDueDate"
                          v-if="!changeDateDue"
                          v-text="displayDate(dueDate)"
                          class="invoice__fileBadge__options__button"
                          @click="changeDateDueButton"
                      />
                      <b-form-input
                          id="changeDueDateInput"
                          v-if="changeDateDue"
                          v-model="model.date.invoice.due"
                          type="date"
                          @change="checkInvoiceDateDue()"
                          @click.stop
                          class="invoice__fileBadge__options__input__small"
                      />
                      <b-input-group-append
                          v-if="changeDateDue"
                          v-text="$t('invoice.defaultTerms')"
                          @click="changeDateDueReset()"
                          style="cursor: pointer"
                          class="text-italic ml-3 my-auto small"
                      />
                    </b-input-group>
                  </div>
                  <small
                      v-if="preferences.dueDateIsAutomatic">
                    {{$t('prefs.dateWillBeDue')}} <b>{{dueDateWouldBe}}</b>
                  </small>
                  <small
                    v-else-if="!enable.document">
                  {{$t('prefs.dateWouldBeDue2')}} <b>{{dueDateWouldBe}} </b>
                </small>
                </div>
              </transition>
            </b-col>
            <b-col
                cols="auto"
                v-else-if="enable.document"> <!-- Change date when due date is disabled -->
              <b-input-group
                  id="dueDateOption">
                <b-input-group-prepend
                    v-text="$t('invoice.date')"
                    class="invoice__fileBadge__options__label mx-2"
                />
                <b-btn
                    id="displayDate"
                    v-if="!changeDate"
                    v-text="displayDate(dateDocument)"
                    class="invoice__fileBadge__options__button"
                    @click="changeDateButton"
                />
                <b-form-input
                    id="changeDueDateInput"
                    v-if="changeDate"
                    v-model="model.date.invoice.dated"
                    type="date"
                    @change="checkInvoiceDate()"
                    @click.stop
                    class="invoice__fileBadge__options__input__small"
                />
                <b-input-group-append
                    v-if="changeDate"
                    v-text="$t('cancel')"
                    @click="changeDate = null"
                    style="cursor: pointer"
                    class="text-italic ml-2 my-auto small"
                />
              </b-input-group>
            </b-col>
          </transition>
        </b-row>
      </div>
      <!-- Due Date Options for Proposals -->
      <div
          v-else
          class="form__container">
        <label
            for="datePreferenceContainer"
            v-text="$t('prefs.dateProposalExpires')"
            class="form__header"
        />
        <b-row
            id="dateOptionsRow"
            align-h="center">
          <b-col
              cols="auto">
            <div
                id="datePreferencesContainer">
              <b-row>
                <b-col>
                  <b-form-checkbox
                      v-model="preferences.proposalExpirationDateEnabled"
                      name="enableExpirationDate"
                      @input="saveSettings('proposalExpirationDateEnabled', preferences.proposalExpirationDateEnabled)"
                      switch
                      class="form__checkbox"
                      size="lg">
                    {{$t('prefs.proposalExpirationDateEnabled')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row
                  v-if="preferences.proposalExpirationDateEnabled">
                <b-col>
                  <b-form-radio-group
                      id="automaticOrManualExpirationDate"
                      :options="typeOfExpirationDate"
                      stacked
                      v-model="preferences.proposalExpirationDateIsAutomatic"
                      @input="saveSettings('proposalExpirationDateIsAutomatic', preferences.proposalExpirationDateIsAutomatic)"
                      class="m-2 ml-3 mb-1"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <transition
              mode="out-in"
              name="fadeQuick">
            <b-col
                cols="auto"
                v-if="preferences.proposalExpirationDateEnabled">
              <transition
                  mode="out-in"
                  name="fadeQuick">
                <div
                    key="paymentTerms">
                  <b-input-group
                      id="defaultTermsInputGroup"
                      v-if="(enable.document && preferences.proposalExpirationDateIsAutomatic) || !enable.document">
                    <b-input-group-prepend
                        v-text="!enable.document ? $t('prefs.defaultTermsProposal') : $t('prefs.defaultTermsProposalDoc')"
                        class="form__label"
                    />
                    <b-form-input
                        id="defaultTermsInput"
                        type="number"
                        :min="0"
                        v-model.number="termsProposal"
                        class="form__input smallInput ml-2"
                    />
                    <b-input-group-append
                        class="my-auto ml-2"
                        v-text="$t('invoice.days')"
                    />
                  </b-input-group>
                  <div
                      v-else-if="enable.document">
                    <b-input-group
                        id="dueDateOption"
                        v-if="!changeDateProposalExpires">
                      <b-input-group-prepend
                          v-text="$t('invoice.date')"
                          class="invoice__fileBadge__options__label mx-2"
                      />
                      <b-btn
                          id="displayDate"
                          v-if="!changeDateProposal"
                          v-text="displayDate(model.date.proposal.dated)"
                          class="invoice__fileBadge__options__button"
                          @click="changeDateButton"
                      />
                      <b-form-input
                          id="changeDueDateInput"
                          v-if="changeDate"
                          v-model="model.date.proposal.dated"
                          type="date"
                          @change="checkProposalDate()"
                          @click.stop
                          class="invoice__fileBadge__options__input__small"
                      />
                      <b-input-group-append
                          v-if="changeDate"
                          v-text="$t('cancel')"
                          @click="changeDateProposal = null"
                          style="cursor: pointer"
                          class="text-italic ml-2 my-auto small"
                      />
                    </b-input-group>
                    <b-input-group
                        id="dueDateOption"
                        v-if="!changeDateProposal">
                      <b-input-group-prepend
                        v-text="$t('invoice.dateExpires')"
                        class="invoice__fileBadge__options__label"
                      />
                      <b-btn
                          id="displayDueDate"
                          v-if="!changeDateProposalExpires"
                          v-text="displayDate(expirationDate)"
                          class="invoice__fileBadge__options__button"
                          @click="changeDateExpiresButton"
                      />
                      <b-form-input
                          id="changeDueDateInput"
                          v-if="changeDateProposalExpires"
                          v-model="model.date.proposal.expires"
                          type="date"
                          @change="checkProposalExpirationDate()"
                          @click.stop
                          class="invoice__fileBadge__options__input__small"
                      />
                      <b-input-group-append
                          v-if="changeDateProposalExpires"
                          v-text="$t('invoice.defaultTerms')"
                          @click="changeDateProposalExpiresReset()"
                          style="cursor: pointer"
                          class="text-italic ml-3 my-auto small"
                      />
                    </b-input-group>
                  </div>
                  <small
                      v-if="preferences.proposalExpirationDateIsAutomatic">
                    {{$t('prefs.dateWillExpire')}} <b>{{proposalExpirationDateWouldBe}}</b>
                  </small>
                  <small
                    v-else-if="!enable.document">
                  {{$t('prefs.dateWouldExpire2')}} <b>{{proposalExpirationDateWouldBe}} </b>
                </small>
                </div>
              </transition>
            </b-col>
            <b-col
                cols="auto"
                v-else-if="enable.document"> <!-- Change date when expiration date is disabled -->
              <b-input-group
                  id="dueDateOption">
                <b-input-group-prepend
                    v-text="$t('invoice.date')"
                    class="invoice__fileBadge__options__label mx-2"
                />
                <b-btn
                    id="displayDateOfProposal"
                    v-if="!changeDateProposal"
                    v-text="displayDate(dateProposal)"
                    class="invoice__fileBadge__options__button"
                    @click="changeDateProposalButton"
                />
                <b-form-input
                    id="changeDueDateInput"
                    v-if="changeDateProposal"
                    v-model="model.date.proposal.dated"
                    type="date"
                    @change="checkProposalDate()"
                    @click.stop
                    class="invoice__fileBadge__options__input__small"
                />
                <b-input-group-append
                    v-if="changeDateProposal"
                    v-text="$t('cancel')"
                    @click="changeDateProposal = null"
                    style="cursor: pointer"
                    class="text-italic ml-2 my-auto small"
                />
              </b-input-group>
            </b-col>
          </transition>
        </b-row>
      </div>
      <!-- Date record for existing documents -->
      <b-row
            v-if="enable.document && (datesRecordProposal || datesRecord)"
            class="form__container">
          <b-col
              cols="auto">
            <div
                id="dateInfo">
              <span
                  v-text="`${$t('invoice.dateCreated')}: ${dateCreated}`"
              />
              <br />
              <span
                  v-html="datesRecordProposal"
                  v-if="datesRecordProposal"
              />
              <span
                  v-html="datesRecord"
                  v-if="datesRecord"
              />
              <b-btn
                  v-text="$t('invoice.dateReset')"
                  @click="clearDateRecord()"
                  variant="warning"
                  class="mt-3 mx-auto"
              />
            </div>
          </b-col>
      </b-row>
      <hr />
      <!-- Document Formatting Options -->
      <div
          class="form__container">
        <label
            for="headerAndSubheader"
            v-text="$t('prefs.header.formatting')"
            class="form__header"
        />
        <div
            v-if="!proposalMode">
          <b-row
              id="headerAndSubheader"
              align-v="end">
            <b-col
                cols="auto">
              <label
                  for="headerInput"
                  v-text="!enable.document ? $t('prefs.defaultHeader') : $t('prefs.defaultHeaderDoc')"
                  class="form__label">
              </label>
              <b-input-group>
                <b-form-checkbox
                    v-model="preferences.enableHeader"
                    name="enableHeaderSwitch"
                    :aria-label="preferences.enableHeader ? $t('aria.headerDisable') : $t('aria.headerEnable')"
                    @input="saveSettings('enableHeader', preferences.enableHeader)"
                    class="form__checkbox"
                    switch
                    size="lg"
                  />
                <b-form-input
                    id="headerInput"
                    type="text"
                    :disabled="preferences.enableHeader ? false : true"
                    v-model="defaultHeader"
                    @input="saveSettings('defaultHeader', preferences.defaultHeader)"
                    class="form__input large"
                />
              </b-input-group>
              <hr />
              <label
                  for="subheaderInput"
                  v-text="$t('prefs.defaultSubheader')"
                  class="form__label"
              />
              <b-input-group>
                <b-form-checkbox
                    v-model="preferences.enableSubheader"
                    name="enableSubheaderSwitch"
                    :aria-label="preferences.enableSubheader ? $t('aria.subheaderDisable') : $t('aria.subheaderEnable')"
                    @input="saveSettings('enableSubheader', preferences.enableSubheader)"
                    class="form__checkbox"
                    switch
                    size="lg"
                  />
                <b-form-input
                    id="subheaderInput"
                    type="text"
                    :disabled="preferences.enableSubheader ? false : true"
                    v-model="defaultSubheader"
                    @input="saveSettings('defaultSubheader', preferences.defaultSubheader)"
                    class="form__input large"
                  />
              </b-input-group>
            </b-col>
            <b-col
                id="alignmentOptions"
                cols="auto">
              <label
                  for="alignmentOptions"
                  v-text="$t('prefs.alignment')"
                  class="form__label"
              />
              <div>
                <label
                    for="headerAppearsOnLeftSelection"
                    v-text="$t('prefs.headerAppearsOnLeft')"
                    class="mx-2"
                />
                <b-form-group
                    id="headerAlignmentRadio"
                    class="my-auto mx-3">
                  <b-form-radio-group
                      id="headerAppearsOnLeftSelection"
                      v-model="preferences.headerAppearsOnLeft"
                      @input="saveSettings('headerAppearsOnLeft', preferences.headerAppearsOnLeft)"
                      :options="leftOrRight"
                      class="mt-0"
                  />
                </b-form-group>
                <label
                    for="customerInfoAppearsOnLeftSelection"
                    v-text="$t('prefs.customerInfoAppearsOnLeft')"
                    class="mt-3 mx-2"
                />
                <b-form-group
                    id="customerInfoAlignmentRadio"
                    class="my-auto mx-3">
                  <b-form-radio-group
                      id="customerInfoAppearsOnLeftSelection"
                      v-model="preferences.customerInfoAppearsOnLeft"
                      @input="saveSettings('customerInfoAppearsOnLeft', preferences.customerInfoAppearsOnLeft)"
                      :options="leftOrRight"
                      class="mt-0"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <hr />
          <label
              for="memoInput"
              v-text="!enable.document ? $t('prefs.defaultMemo') : $t('prefs.defaultMemoDoc')"
              class="form__label"
          />
          <b-input-group>
            <b-form-checkbox
                v-model="preferences.enableMemo"
                name="enableMemoSwitch"
                :aria-label="preferences.enableMemo ? $t('aria.memoDisable') : $t('aria.memoEnable')"
                @input="saveSettings('enableMemo', preferences.enableMemo)"
                class="form__checkbox"
                switch
                size="lg"
            />
            <b-form-textarea
                id="memoInput"
                type="text"
                v-model="defaultMemo"
                :disabled="preferences.enableMemo ? false : true"
                @input="saveSettings('defaultMemo', preferences.defaultMemo)"
                class="form__input fullWidth"
            />
          </b-input-group>
          <hr />
          <label
              for="defaultFooterInput"
              v-text="!enable.document ? $t('prefs.defaultFooter') : $t('prefs.defaultFooterDoc')"
              class="form__label"
          />
          <b-input-group>
            <b-form-checkbox
                v-model="preferences.enableFooter"
                name="enableFooterSwitch"
                :aria-label="preferences.enableFooter ? $t('aria.footerDisable') : $t('aria.footerEnable')"
                @input="saveSettings('enableFooter', preferences.enableFooter)"
                class="form__checkbox"
                switch
                size="lg"
            />
            <b-form-input
                id="defaultFooterInput"
                type="text"
                v-model="defaultFooter"
                :disabled="preferences.enableFooter ? false : true"
                @input="saveSettings('defaultFooter', preferences.defaultFooter)"
                class="preferences__input"
            />
          </b-input-group>
          <b-row
              align-h="center"
              class="my-3">
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="chargesLabelInput"
                  v-text="$t('prefs.chargesHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="chargesLabelInput"
                  type="text"
                  v-model="preferences.chargesHeader"
                  :placeholder="$t('invoice.lineItems')"
                  @input="saveSettings('chargesHeader', preferences.chargesHeader)"
                  class="form__input medium"
              />
            </b-col>
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="paymentsLabelInput"
                  v-text="$t('prefs.paymentsHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="paymentsLabelInput"
                  type="text"
                  v-model="preferences.paymentsHeader"
                  :placeholder="$t('invoice.payments')"
                  @input="saveSettings('paymentsHeader', preferences.paymentsHeader)"
                  class="form__input medium"
              />
            </b-col>
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="noteLabelInput"
                  v-text="$t('prefs.noteHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="noteLabelInput"
                  type="text"
                  v-model="preferences.noteHeader"
                  :placeholder="$t('invoice.note')"
                  @input="saveSettings('noteHeader', preferences.noteHeader)"
                  class="form__input medium"
              />
            </b-col>
          </b-row>
        </div>
        <!-- Proposal Mode Headers -->
        <div
            v-else>
          <b-row
              id="headerAndSubheader"
              align-v="end">
            <b-col
                cols="auto">
              <label
                  for="headerInput"
                  v-text="!enable.document ? $t('prefs.defaultHeader') : $t('prefs.defaultHeaderDoc')"
                  class="form__label">
              </label>
              <b-input-group>
                <b-form-checkbox
                    v-model="preferences.enableHeaderProposal"
                    name="enableHeaderSwitch"
                    :aria-label="preferences.enableHeaderProposal ? $t('aria.headerDisable') : $t('aria.headerEnable')"
                    @input="saveSettings('enableHeaderProposal', preferences.enableHeaderProposal)"
                    class="form__checkbox"
                    switch
                    size="lg"
                  />
                <b-form-input
                    id="headerInput"
                    type="text"
                    :disabled="preferences.enableHeaderProposal ? false : true"
                    v-model="preferences.defaultHeaderProposal"
                    @input="saveSettings('defaultHeaderProposal', preferences.defaultHeaderProposal)"
                    class="form__input large"
                />
              </b-input-group>
              <hr />
              <label
                  for="subheaderInput"
                  v-text="$t('prefs.defaultSubheader')"
                  class="form__label"
              />
              <b-input-group>
                <b-form-checkbox
                    v-model="preferences.enableSubheaderProposal"
                    name="enableSubheaderSwitch"
                    :aria-label="preferences.enableSubheaderProposal ? $t('aria.subheaderDisable') : $t('aria.subheaderEnable')"
                    @input="saveSettings('enableSubheaderProposal', preferences.enableSubheaderProposal)"
                    class="form__checkbox"
                    switch
                    size="lg"
                  />
                <b-form-input
                    id="subheaderInput"
                    type="text"
                    :disabled="preferences.enableSubheaderProposal ? false : true"
                    v-model="preferences.defaultSubheaderProposal"
                    @input="saveSettings('defaultSubheaderProposal', preferences.defaultSubheaderProposal)"
                    class="form__input large"
                  />
              </b-input-group>
            </b-col>
            <b-col
                id="alignmentOptions"
                cols="auto">
              <label
                  for="alignmentOptions"
                  v-text="$t('prefs.alignment')"
                  class="form__label"
              />
              <div>
                <label
                    for="headerAppearsOnLeftSelection"
                    v-text="$t('prefs.headerAppearsOnLeft')"
                    class="mx-2"
                />
                <b-form-group
                    id="headerAlignmentRadio"
                    class="my-auto mx-3">
                  <b-form-radio-group
                      id="headerAppearsOnLeftSelection"
                      v-model="preferences.headerAppearsOnLeft"
                      @input="saveSettings('headerAppearsOnLeft', preferences.headerAppearsOnLeft)"
                      :options="leftOrRight"
                      class="mt-0"
                  />
                </b-form-group>
                <label
                    for="customerInfoAppearsOnLeftSelection"
                    v-text="$t('prefs.customerInfoAppearsOnLeft')"
                    class="mt-3 mx-2"
                />
                <b-form-group
                    id="customerInfoAlignmentRadio"
                    class="my-auto mx-3">
                  <b-form-radio-group
                      id="customerInfoAppearsOnLeftSelection"
                      v-model="preferences.customerInfoAppearsOnLeft"
                      @input="saveSettings('customerInfoAppearsOnLeft', preferences.customerInfoAppearsOnLeft)"
                      :options="leftOrRight"
                      class="mt-0"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <hr />
          <label
              for="memoInput"
              v-text="!enable.document ? $t('prefs.defaultMemo') : $t('prefs.defaultMemoDoc')"
              class="form__label"
          />
          <b-input-group>
            <b-form-checkbox
                v-model="preferences.enableMemoProposal"
                name="enableMemoSwitch"
                :aria-label="preferences.enableMemoProposal ? $t('aria.memoDisable') : $t('aria.memoEnable')"
                @input="saveSettings('enableMemoProposal', preferences.enableMemoProposal)"
                class="form__checkbox"
                switch
                size="lg"
              />
            <b-form-textarea
                id="memoInput"
                type="text"
                v-model="preferences.defaultMemoProposal"
                :disabled="preferences.enableMemoProposal ? false : true"
                @input="saveSettings('defaultMemoProposal', preferences.defaultMemoProposal)"
                class="form__input fullWidth"
            />
          </b-input-group>
          <hr />
          <label
              for="defaultFooterInput"
              v-text="!enable.document ? $t('prefs.defaultFooter') : $t('prefs.defaultFooterDoc')"
              class="form__label"
          />
          <b-input-group>
            <b-form-checkbox
                v-model="preferences.enableFooterProposal"
                name="enableFooterSwitch"
                :aria-label="preferences.enableFooterProposal ? $t('aria.footerDisable') : $t('aria.footerEnable')"
                @input="saveSettings('enableFooterProposal', preferences.enableFooterProposal)"
                class="form__checkbox"
                switch
                size="lg"
            />
            <b-form-input
                id="defaultFooterInput"
                type="text"
                v-model="preferences.defaultFooterProposal"
                :disabled="preferences.enableFooterProposal ? false : true"
                @input="saveSettings('defaultFooterProposal', preferences.defaultFooterProposal)"
                class="preferences__input"
            />
          </b-input-group>
          <b-row
              align-h="center"
              class="my-3">
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="chargesLabelInput"
                  v-text="$t('prefs.chargesHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="chargesLabelInput"
                  type="text"
                  v-model="preferences.chargesHeaderProposal"
                  :placeholder="$t('invoice.lineItems')"
                  @input="saveSettings('chargesHeaderProposal', preferences.chargesHeaderProposal)"
                  class="form__input medium"
              />
            </b-col>
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="paymentsLabelInput"
                  v-text="$t('prefs.paymentsHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="paymentsLabelInput"
                  type="text"
                  v-model="preferences.paymentsHeaderProposal"
                  :placeholder="$t('invoice.payments')"
                  @input="saveSettings('paymentsHeaderProposal', preferences.paymentsHeaderProposal)"
                  class="form__input medium"
              />
            </b-col>
            <b-col
                align="center"
                cols="*"
                class="mx-3">
              <label
                  for="noteLabelInput"
                  v-text="$t('prefs.noteHeader')"
                  class="form__label"
              />
              <b-form-input
                  id="noteLabelInput"
                  type="text"
                  v-model="preferences.noteHeaderProposal"
                  :placeholder="$t('invoice.note')"
                  @input="saveSettings('noteHeaderProposal', preferences.noteHeaderProposal)"
                  class="form__input medium"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
      <!-- Color options -->
      <div
          class="form__container">
        <label
            for="colorScheme"
            v-text="!proposalMode ? $t('prefs.color') : $t('prefs.colorProposal')"
            class="form__header"
        />
        <b-row
            id="colorScheme"
            align-h="center"
            class="preferences__colorScheme">
          <b-col
              class="m-auto"
              align="center">
            <b-button
                v-if="!proposalMode"
                size="lg"
                :style="`background-color:${preferences.colorBackground}; color: ${preferences.colorText}`"
                v-text="customColors ? $t('prefs.colorTextChosen') : preferences.colorLabel"
                class="px-5"
            />
            <b-button
                v-else
                size="lg"
                :style="`background-color:${preferences.colorBackgroundProposal}; color: ${preferences.colorTextProposal}`"
                v-text="customColors ? $t('prefs.colorTextChosen') : preferences.colorLabelProposal"
                class="px-5"
            />
          </b-col>
          <b-col
              class="m-auto"
              align="center">
            <span
                :style="`color: ${!proposalMode ? preferences.colorImpact : preferences.colorImpactProposal}; font-size: 1.5rem; margin-right: 10px;`"
                v-text="$t('prefs.colorImpactChosen')"
            />
          </b-col>
          <b-col
              class="m-auto"
              align="center">
            <span
                :style="`color: ${!proposalMode ? preferences.colorAccent : preferences.colorAccentProposal}`"
                v-text="$t('prefs.colorAccentChosen')"
                class="mx-2"
            />
          </b-col>
        </b-row>
        <hr />
        <transition
            name="fade"
            mode="out-in">
          <div
              v-if="customColors">
            <b-tabs
                id="customColorTabs">
              <b-tab
                  key="presetColors"
                  :title="$t('prefs.tab.colorPreset')">
                <b-row>
                  <b-col>
                    <b-btn
                        v-for="(color, index) in colorChoices"
                        :key="index"
                        @click="changeColor(color)"
                        :style="`background-color: ${color.background}; color: ${color.text}`"
                        class="px-4 m-2"
                        v-text="color.label"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                  key="customColors"
                  :title="$t('prefs.tab.colorCustom')">
                <div
                    class="my-auto">
                  <b-row
                      align-h="center">
                    <b-col>
                      <b-input-group
                          class="form__container my-auto">
                        <b-input-group-prepend
                            for="backgroundColorSelection"
                            v-text="$t('prefs.colorBackground')"
                            class="form__label"
                        />
                        <b-form-input
                            v-if="!proposalMode"
                            id="backgroundColorSelection"
                            type="color"
                            debounce="500"
                            v-model="preferences.colorBackground"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorBackground', preferences.colorBackground)"
                        />
                        <b-form-input
                            v-else
                            id="backgroundColorSelection"
                            type="color"
                            debounce="500"
                            v-model="preferences.colorBackgroundProposal"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorBackgroundProposal', preferences.colorBackgroundProposal)"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-input-group
                          class="form__container">
                        <b-input-group-prepend
                            for="textColorSelection"
                            v-text="$t('prefs.colorText')"
                            class="form__label"
                        />
                        <b-form-input
                            v-if="!proposalMode"
                            id="textColorSelection"
                            type="color"
                            v-model="preferences.colorText"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorText', preferences.colorText)"
                        />
                        <b-form-input
                            v-else
                            id="textColorSelection"
                            type="color"
                            v-model="preferences.colorTextProposal"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorTextProposal', preferences.colorTextProposal)"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-input-group
                          class="form__container">
                        <b-input-group-prepend
                            for="impactColorSelection"
                            v-text="$t('prefs.colorImpact')"
                            class="form__label"
                        />
                        <b-form-input
                            v-if="!proposalMode"
                            id="impactColorSelection"
                            type="color"
                            v-model="preferences.colorImpact"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorImpact', preferences.colorImpact)"
                        />
                        <b-form-input
                            v-else
                            id="impactColorSelection"
                            type="color"
                            v-model="preferences.colorImpactProposal"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorImpactProposal', preferences.colorImpactProposal)"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-input-group
                          class="form__container">
                        <b-input-group-prepend
                            for="accentColorSelection"
                            v-text="$t('prefs.colorAccent')"
                            class="form__label"
                        />
                        <b-form-input
                            v-if="!proposalMode"
                            id="accentColorSelection"
                            type="color"
                            v-model="preferences.colorAccent"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorAccent', preferences.colorAccent)"
                        />
                        <b-form-input
                            v-else
                            id="accentColorSelection"
                            type="color"
                            v-model="preferences.colorAccentProposal"
                            class="preferences__colorScheme__selector"
                            @input="saveColorSettings('colorAccentProposal', preferences.colorAccentProposal)"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </transition>
        <b-row>
          <b-col
              align="center">
            <b-btn
              id="colorChoice"
              v-text="!customColors ? $t('prefs.colorCustom') : $t('prefs.colorCustomHide')"
              variant="secondary"
              class="mx-auto px-4 my-2"
              @click="customizeColors()">
          </b-btn>
        </b-col>
      </b-row>
      </div>
      <hr />
      <!-- Payment Instructions / Proposal Retainer -->
      <div
          id="paymentInstructionsContainer"
          class="form__container">
        <label
            for="paymentInstructionsInput"
            v-text="proposalHeaderText"
            class="form__header"
        />
        <!-- Invoice mode - Payment Instructions -->
        <div
            v-if="!proposalMode">
          <b-form-checkbox
              v-model="preferences.showPaymentsMemo"
              name="showPaymentsMemo"
              @input="saveSettings('showPaymentsMemo', preferences.showPaymentsMemo)"
              switch
              size="lg"
              class="form__checkbox">
            {{$t('prefs.showPaymentsMemo')}}
          </b-form-checkbox>
          <transition
              mode="out-in"
              name="fade">
            <div
                v-if="preferences.showPaymentsMemo">
              <b-form-input
                  id="paymentInstructionsHeaderInput"
                  type="text"
                  :placeholder="$t('invoice.header')"
                  v-model="preferences.paymentMemoHeader"
                  rows="4"
                  @input="saveSettings('paymentMemoHeader', preferences.paymentMemoHeader)"
                  class="preferences__input"
              />
              <b-form-textarea
                  id="paymentInstructionsInput"
                  v-if="preferences.showPaymentsMemo"
                  type="text"
                  v-model="preferences.paymentMemo"
                  rows="4"
                  @input="saveSettings('paymentMemo', preferences.paymentMemo)"
                  class="preferences__input"
              />
            </div>
          </transition>
        </div>
        <!-- Proposal mode -- retainer box -->
        <div
            id="retainerContainer"
            v-else>
          <b-form-checkbox
              v-model="preferences.showPaymentsMemoProposal"
              name="showPaymentsMemoProposal"
              @input="saveSettings('showPaymentsMemoProposal', preferences.showPaymentsMemoProposal)"
              switch
              class="form__checkbox"
              size="lg">
            {{$t('prefs.showPaymentsMemoProposal')}}
          </b-form-checkbox>
          <transition
              mode="out-in"
              name="fade">
            <div
                v-if="preferences.showPaymentsMemoProposal"
                class="my-2">
              <b-form-input
                  id="paymentInstructionsHeaderInput"
                  type="text"
                  v-model="preferences.paymentMemoProposalHeader"
                  :placeholder="$t('invoice.header')"
                  @input="saveSettings('paymentMemoProposalHeader', preferences.paymentMemoProposalHeader)"
                  class="preferences__input"
              />
              <b-form-textarea
                  id="paymentInstructionsInput"
                  type="text"
                  v-model="preferences.paymentMemoProposal"
                  rows="4"
                  @input="saveSettings('paymentMemoProposal', preferences.paymentMemoProposal)"
                  class="preferences__input"
              />
              <b-row
                  id="retainerSettingsContainer"
                  class="my-2">
                <b-col
                    cols="*"
                    class="ml-auto mr-2">
                  <label
                      for="retainerTypeSelection"
                      v-text="$t('prefs.proposalRetainerLabel')"
                      class="form__label"
                  />
                  <b-input-group
                      id="retainerTypeSelection">
                    <b-btn
                        id="proposalPercentageButton"
                        @click="preferences.proposalRetainer.type = 'percentage'"
                        v-text="$t('prefs.proposalRetainerPercentage')"
                        :variant="preferences.proposalRetainer.type === 'percentage' ? 'primary' : 'primary-outline'"
                    />
                    <b-btn
                        id="proposalSetAmountButton"
                        @click="preferences.proposalRetainer.type = 'setAmount'"
                        v-text="$t('prefs.proposalRetainerSetAmount')"
                        :variant="preferences.proposalRetainer.type === 'setAmount' ? 'primary' : 'primary-outline'"
                    />
                  </b-input-group>
                </b-col>
                <b-col
                    cols="*"
                    class="ml-4 mr-2">
                  <label
                      for="retainerAmountInput"
                      v-text="preferences.proposalRetainer.type === 'percentage' ? $t('prefs.proposalRetainerPercentage') : $t('prefs.proposalRetainerSetAmount')"
                      class="form__label"
                  />
                  <b-input-group
                      id="retainerAmountInput"
                      v-if="preferences.proposalRetainer.type === 'percentage'"
                      class="my-auto">
                    <b-input-group-prepend
                        class="mr-1">
                      %
                    </b-input-group-prepend>
                    <b-form-input
                        id="retainerPercentageInput"
                        type="number"
                        :step="0.1"
                        v-if="preferences.proposalRetainer.type === 'percentage'"
                        v-model.number="preferences.proposalRetainer.percent"
                        :placeholder="$t('prefs.proposalRetainerPercentagePlaceholder')"
                        @input="saveSettings('proposalRetainer', preferences.proposalRetainer)"
                        class="preferences__input xsmallInput"
                    />
                  </b-input-group>
                  <b-input-group
                      id="retainerAmountInput"
                      v-if="preferences.proposalRetainer.type === 'setAmount'"
                      class="my-auto">
                    <b-input-group-prepend
                        class="mr-1">
                      $
                    </b-input-group-prepend>
                    <b-form-input
                        id="retainerSetAmountInput"
                        type="number"
                        :step="0.01"
                        v-if="preferences.proposalRetainer.type === 'setAmount'"
                        v-model.number="preferences.proposalRetainer.setAmount"
                        :placeholder="$t('prefs.proposalRetainerSetAmountPlaceholder')"
                        @input="saveSettings('proposalRetainer', preferences.proposalRetainer)"
                        class="preferences__input xsmallInput"
                    />
                  </b-input-group>
                </b-col>
                <b-col
                    cols="*"
                    class="mr-auto ml-3">
                  <label
                      for="retainerPrefixInput"
                      v-text="$t('prefs.proposalRetainerPrefixLabel')"
                      class="form__label"
                  />
                  <b-form-input
                      id="retainerPrefixInput"
                      type="text"
                      v-model="preferences.proposalRetainer.prefix"
                      @input="saveSettings('proposalRetainer', preferences.proposalRetainer)"
                      class="form__input medium"
                  />
                </b-col>
              </b-row>
            </div>
          </transition>
        </div>
      </div>


      <!-- Proposal mode - Signature Instructions -->
      <div
          id="signatureContainer"
          class="form__container">
        <label
            for="proposalSignatureOption"
            v-text="$t('prefs.proposalSignatureHeader')"
            class="form__header"
        />
        <div>
          <b-form-checkbox
              v-model="preferences.proposalSignature.enable"
              name="proposalSignatureOption"
              @input="saveSettings('proposalSignature', preferences.proposalSignature)"
              switch
              size="lg"
              class="form__checkbox">
            {{$t('prefs.proposalSignatureEnable')}}
          </b-form-checkbox>
          <transition
              mode="out-in"
              name="fade">
            <div
                v-if="preferences.proposalSignature.enable">
              <b-form-textarea
                  id="signatureInstructions"
                  v-if="preferences.proposalSignature.instructions"
                  type="text"
                  v-model="preferences.paymentMemo"
                  rows="4"
                  @input="saveSettings('paymentMemo', preferences.paymentMemo)"
                  class="preferences__input"
              />
              <b-form-input
                  id="paymentInstructionsHeaderInput"
                  type="text"
                  :placeholder="$t('invoice.header')"
                  v-model="preferences.paymentMemoHeader"
                  rows="4"
                  @input="saveSettings('paymentMemoHeader', preferences.paymentMemoHeader)"
                  class="preferences__input"
              />
            </div>
          </transition>
        </div>
      </div>

      <hr />
      <b-row>
        <b-col
            md="12"
            lg="6">
          <div
              id="taxPreferences"
              class="form__container">
            <label
                for="taxPrefrences"
                v-text="$t('prefs.tax')"
                class="form__header"
            />
            <b-form-checkbox
                v-model="preferences.taxOption"
                name="showTaxOption"
                @input="enable.document ? updateTaxOption() : saveSettings('taxOption', preferences.taxOption)"
                switch
                class="form__checkbox"
                size="lg">
              {{!enable.document ? $t('prefs.taxOption') : $t('prefs.taxOptionDoc')}}
            </b-form-checkbox>
            <transition
                name="fade"
                mode="out-in">
              <b-input-group
                  key="enabled"
                  v-if="preferences.taxOption">
                <b-input-group-prepend
                    for="defaultTaxRateInput"
                    v-text="!enable.document ? $t('prefs.defaultTaxRate') : $t('prefs.defaultTaxRateDoc')"
                    class="m-3 my-auto"
                />
                <b-form-input
                    id="defaultTaxRateInput"
                    type="number"
                    :step="0.0001"
                    v-model.number="preferences.taxRate"
                    @input="enable.document ? updateTaxRate() : saveSettings('taxRate', preferences.taxRate)"
                    class="form__input smallInput"
                />
                <b-input-group-append
                    class="my-auto"
                    v-text="'%'"
                />
              </b-input-group>
              <div
                  key="disabled"
                  v-else-if="!enable.document">
                <small
                    v-text="$t('invoice.taxDisabled')"
                    class="m-2 font-italic text-muted"
                />
              </div>
            </transition>
          </div>
        </b-col>
        <b-col
            md="12"
            lg="6">
          <div
              id="miscOptionsContainer"
              class="form__container">
            <label
                for="miscOptionsContainer"
                v-text="$t('prefs.miscOptions')"
                class="form__header"
            />
            <b-form-checkbox
                v-model="preferences.displayTitle"
                name="showTitle"
                @input="saveSettings('displayTitle', preferences.displayTitle)"
                class="form__checkbox"
                switch
                size="lg">
              {{$t('prefs.displayTitle')}}
            </b-form-checkbox>
            <b-form-checkbox
                v-model="preferences.qtyOption"
                name="showQtyField"
                class="form__checkbox"
                @input="saveSettings('qtyOption', preferences.qtyOption)"
                switch
                size="lg">
              {{$t('prefs.qtyOption')}}
            </b-form-checkbox>
            <b-form-checkbox
                v-model="preferences.dateLineItemOption"
                name="showDateOnLineItems"
                class="form__checkbox"
                @input="saveSettings('dateLineItemOption', preferences.dateLineItemOption)"
                switch
                size="lg">
              {{$t('prefs.dateLineItemOption')}}
            </b-form-checkbox>
            <b-form-checkbox
                v-model="preferences.datePaymentOption"
                name="showDateOnPayments"
                class="form__checkbox"
                @input="saveSettings('datePaymentOption', preferences.datePaymentOption)"
                switch
                size="lg">
              {{$t('prefs.datePaymentOption')}}
            </b-form-checkbox>
            <!-- <b-form-checkbox
                v-model="preferences.displayDiscounts"
                name="showDiscounts"
                class="mx-2"
                @input="saveSettings('displayDiscounts', preferences.displayDiscounts)"
                switch
                size="lg">
              {{$t('prefs.displayDiscounts')}}
            </b-form-checkbox> -->
            <b-form-checkbox
                v-model="preferences.showNoteDate"
                name="showNoteDate"
                class="form__checkbox"
                @input="saveSettings('showNoteDate', preferences.showNoteDate)"
                switch
                size="lg">
              {{$t('prefs.showNoteDate')}}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <hr
          class="my-3"
      />
    </b-tab>
    <b-tab
        id="userDetails"
        key="user"
        :title="$t('prefs.tab.user')"
        v-if="enable.user">
      <div
          class="form__header">
        <h4
            v-text="$t('prefs.header.user')"
        />
        <small
            v-text="$t('prefs.header.userSubheader')"
        />
      </div>
      <div
          class="form__container">
        <label
            for="nameInput"
            v-text="$t('prefs.userName')"
            class="form__label"
        />
        <b-form-input
            id="nameInput"
            type="text"
            v-model="preferences.user.name"
            @input="saveSettings('user', preferences.user)"
            class="form__input xl"
          />
      </div>
      <div
          class="form__container">
        <label
            for="userAddressFirstLine"
            v-text="$t('prefs.userAddress')"
            class="form__label"
        />
        <b-input-group>
          <b-form-input
              id="userAddressFirstLine"
              type="text"
              v-model="preferences.user.address.street"
              @input="saveSettings('user', preferences.user)"
              class="form__input xl"
          />
          <b-input-group-append
              v-if="!addingSecondLine && !preferences.user.address.secondLine">
            <span
                @click="addressSecondLine()"
                class="form__textButton"
                v-text="$t('prefs.userAddressSecondAdd')">
            </span>
          </b-input-group-append>
        </b-input-group>
        <transition
            name="fade"
            mode="out-in">
          <b-input-group
              v-if="addingSecondLine || preferences.user.address.secondLine">
            <b-form-input
                id="userAddressSecondLine"
                :aria-label="$t('aria.addressSecondLine')"
                type="text"
                v-model="preferences.user.address.secondLine"
                @input="saveSettings('user', preferences.user)"
                class="form__input xl"
            />
            <b-input-group-append>
              <span
                  @click="addressSecondLine()"
                  class="form__textButton"
                  v-text="$t('prefs.userAddressSecondClear')">
              </span>
            </b-input-group-append>
          </b-input-group>
        </transition>
        <b-row>
          <b-col
              cols="auto">
            <label
                for="userCity"
                class="form__label">
                {{$t('prefs.userCity')}} <b-spinner v-if="zipWatcher.id" small type="grow" />
            </label>
            <b-form-input
                id="userCity"
                type="text"
                v-model="preferences.user.address.city"
                @input="saveSettings('user', preferences.user)"
                class="form__input large"
            />
          </b-col>
          <b-col
              cols="auto">
            <label
                for="userState"
                v-text="$t('prefs.userState')"
                class="form__label"
            />
            <b-form-input
                id="userState"
                type="text"
                v-model="preferences.user.address.state"
                @input="saveSettings('user', preferences.user)"
                class="form__input stateInput__big"
            />
          </b-col>
          <b-col
              cols="auto">
            <label
                for="userAddressZip"
                v-text="$t('prefs.userZip')"
                class="form__label"
            />
            <b-form-input
                id="userAddress2"
                type="text"
                v-model="userZip"
                @input="zipLookup(userZip, 'userZip')"
                class="form__input zipInput"
            />
          </b-col>
        </b-row>
      </div>
      <div
          class="form__container">
        <label
            for="userEmail"
            v-text="$t('prefs.userEmail')"
            class="form__label"
        />
        <b-form-input
            id="userEmail"
            type="text"
            v-model="preferences.user.email"
            @input="saveSettings('user', preferences.user)"
            class="form__input large"
          />
        <b-row>
          <b-col
              id="phoneNumber">
            <label
                for="userPhone"
                v-text="$t('prefs.userPhone')"
                class="form__label"
            />
            <b-form-input
                id="userPhone"
                type="text"
                v-model="userPhone"
                @input="saveSettings('user', preferences.user)"
                class="form__input medium"
            />
        </b-col>
        <b-col
            id="faxNumber">
            <label
                for="userFax"
                v-text="$t('prefs.userFax')"
                class="form__label"
            />
            <b-form-input
                id="userFax"
                type="text"
                v-model="userFax"
                @input="saveSettings('user', preferences.user)"
                class="form__input medium"
              />
          </b-col>
        </b-row>
      </div>
      <div
          class="form__container"
          id="logoContainer">
        <label
            for="logoContainer"
            class="form__header"
            v-text="$t('prefs.logo.header')"
        />
        <b-row
            class="my-3">
          <b-col>
            <span
                v-if="showErrorMsg"
                v-text="showErrorMsg"
                style="font-size: 1.5em; color: red"
            />
            <br
                v-if="showErrorMsg"
            />
            <b-btn
                v-if="!preferences.user.logo && !addingLogo"
                @click="logoAdd()"
                v-text="$t('prefs.logo.add')"
                variant="primary"
            />
            <br />
            <input
                v-if="addingLogo && !uploadInProgress"
                id="image-upload"
                type="file"
                accept="image/*"
                @change="uploadLogo">
            <div
                v-if="uploadInProgress || deleteInProgress"
                class="mx-auto">
              <span
                  v-text="$t('prefs.logo.uploading')"
                  v-if="uploadInProgress"
                  style="font-size: 1.5em;"
              />
              <span
                  v-if="deleteInProgress"
                  v-text="$t('prefs.logo.deleting')"
                  style="font-size: 1.5em;"
              />
              <loader-overlay
                  color="black"
                  type="dots"
                  :h="50"
                  :w="120"
                  :fullPage="false"
              />
            </div>
            <div
                v-if="preferences.user.logo">
              <b-img-lazy
                  :src="preferences.user.logo"
                  style="max-width: 300px"
              />
              <span
                  style="cursor:pointer"
                  @click="logoRemove()"
                  v-text="$t('prefs.logo.remove')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-tab>
    <b-tab
        id="systemSettings"
        key="systemSettings"
        :title="$t('prefs.tab.system')"
        v-if="enable.system">
      <div
          class="form__header">
        <h4
            v-text="$t('prefs.header.systemTab')"
        />
        <small
            v-text="$t('prefs.header.systemTabSubheader')"
        />
      </div>
      <hr />
      <div
          class="form__container">
        <b-input-group
            id="perPageSelection"
            v-if="!enable.document && !enable.customer">
          <b-input-group-prepend
              v-text="$t('prefs.perPage')"
              class="my-auto mr-2"
          />
          <b-form-input
              id="documentNumberLength"
              type="number"
              :min="1"
              :max="100"
              lazy
              v-model.number="perPage"
              class="form__input smallInput"
          />
        </b-input-group>
        <hr />
        <b-form-checkbox
            v-model="preferences.showToastOnSuccessfulSave"
            name="showToastsOnSave"
            @input="saveSettings('showToastOnSuccessfulSave', preferences.showToastOnSuccessfulSave)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.showToastOnSuccessfulSave')}}
        </b-form-checkbox>
        <b-form-checkbox
            v-model="preferences.showTimes"
            name="showDocumentTimes"
            @input="saveSettings('showTimes', preferences.showTimes)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.showTimes')}}
        </b-form-checkbox>
        <b-form-checkbox
            v-model="preferences.showDateLabels"
            name="showDateLabels"
            @input="saveSettings('showDateLabels', preferences.showDateLabels)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.showDateLabels')}}
        </b-form-checkbox>
        <b-form-checkbox
            v-model="preferences.autoLock"
            name="autoLockItems"
            @input="saveSettings('autoLock', preferences.autoLock)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.autoLock')}}
        </b-form-checkbox>
        <b-form-checkbox
            v-model="preferences.zipAutofill"
            name="enableZipAutofill"
            @input="saveSettings('zipAutofill', preferences.zipAutofill)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.zipAutofill')}}
        </b-form-checkbox>
        <label
            for="primaryAddressLabel"
            v-text="$t('prefs.addressLabel')"
            class="form__label mt-3"
        />
        <b-form-input
            id="primaryAddressLabel"
            type="text"
            v-model="preferences.addressLabel"
            @input="saveSettings('addressLabel', preferences.addressLabel)"
            class="form__input large"
        />
        <label
            for="secondaryAddressLabel"
            v-text="$t('prefs.addressSecondaryLabel')"
            class="form__label"
        />
        <b-form-input
            id="secondaryAddressLabel"
            type="text"
            v-model="preferences.addressSecondaryLabel"
            @input="saveSettings('addressSecondaryLabel', preferences.addressSecondaryLabel)"
            class="form__input large"
        />
      </div>
      <hr />
      <div
          id="statusAutoUpdateContainer"
          class="form__container">
        <label
            for="statusAutoUpdateContainer"
            v-text="$t('prefs.statusAutoUpdate')"
            class="form__header"
        />
        <b-form-checkbox
            v-model="preferences.statusAutoUpdate"
            name="autoUpdateStatusGlobal"
            @input="saveSettings('statusAutoUpdate', preferences.statusAutoUpdate)"
            switch
            class="form__checkbox"
            size="lg">
          {{$t('prefs.statusAutoUpdateLong')}}
        </b-form-checkbox>
        <small
            v-text="$t('prefs.changeAppliesToNewDocuments')"
            class="m-4"
            v-if="!enable.document"
        />
        <transition
            name="fade"
            mode="out-in">
          <div
              v-if="preferences.statusAutoUpdate">
            <label
                for="deliveryPreferenceContainer"
                v-text="$t('prefs.markAsSent')"
                v-if="preferences.statusAutoUpdate"
                class="font-weight-bold mt-3 ml-2"
            />
            <div
                id="deliveryPreferenceContainer"
                v-if="preferences.statusAutoUpdate"
                class="ml-4">
              <b-form-checkbox
                  :disabled="!preferences.statusAutoUpdate"
                  v-model="preferences.markAsSentEmail"
                  name="markAsSentWhenEmailing"
                  @input="saveSettings('markAsSentEmail', preferences.markAsSentEmail)"
                  switch
                  class="form__checkbox"
                  size="lg">
                {{$t('prefs.markAsSentEmail')}}
              </b-form-checkbox>
              <b-form-checkbox
                  :disabled="!preferences.statusAutoUpdate"
                  v-model="preferences.markAsSentDownload"
                  name="markAsSentWhenDownloading"
                  @input="saveSettings('markAsSentDownload', preferences.markAsSentDownload)"
                  switch
                  class="form__checkbox"
                  size="lg">
                {{$t('prefs.markAsSentDownload')}}
              </b-form-checkbox>
              <b-form-checkbox
                  :disabled="!preferences.statusAutoUpdate"
                  v-model="preferences.markAsSentPrint"
                  name="markAsSentWhenPrinting"
                  @input="saveSettings('markAsSentPrint', preferences.markAsSentPrint)"
                  switch
                  class="form__checkbox"
                  size="lg">
                {{$t('prefs.markAsSentPrint')}}
              </b-form-checkbox>
            </div>
          </div>
        </transition>
      </div>
      <hr />
      <div
          id="defaultEmailContainer"
          class="form__container">
        <label
            for="defaultEmailContainer"
            v-text="$t('prefs.defaultEmailHeader')"
            class="form__header"
        />
        <label
            for="messageSenderInput"
            v-text="$t('prefs.defaultEmailFrom')"
            class="form__label"
        />
        <b-form-input
            id="messageSenderInput"
            type="text"
            v-model="preferences.defaultEmailFrom"
            @input="saveSettings('defaultEmailFrom', preferences.defaultEmailFrom)"
            class="form__input large"
        />
        <label
            for="replyToInput"
            v-text="$t('prefs.defaultEmailReplyTo')"
            class="form__label"
        />
        <b-form-input
            id="replyToInput"
            type="text"
            v-model="preferences.defaultEmailReplyTo"
            @input="saveSettings('defaultEmailReplyTo', preferences.defaultEmailReplyTo)"
            class="form__input large"
        />
        <label
            for="subjectLineInput"
            v-text="$t('prefs.defaultEmailSubjectLine')"
            class="form__label"
        />
        <b-form-input
            id="subjectLineInput"
            type="text"
            v-model="preferences.defaultEmailSubjectLine"
            @input="saveSettings('defaultEmailSubjectLine', preferences.defaultEmailSubjectLine)"
            class="form__input fullWidth"
        />
        <label
            for="messageInput"
            v-text="$t('prefs.defaultEmailMessage')"
            class="form__label"
        />
        <b-form-textarea
            id="messageInput"
            type="text"
            rows="8"
            v-model="preferences.defaultEmailMessage"
            @input="saveSettings('defaultEmailMessage', preferences.defaultEmailMessage)"
            class="form__input fullWidth"
        />
      </div>
    </b-tab>
    <b-tab
        id="accountDetails"
        key="account"
        :title="$t('prefs.tab.account')"
        v-if="enable.account">
      {{$t('comingSoon')}}
    </b-tab>
    <b-tab
        id="workDayPreferences"
        key="workDay"
        :title="$t('prefs.tab.workDay')"
        v-if="enable.workDay">
      <div
          class="form__header">
        <h1
            v-text="$t('prefs.header.workDay')"
        />
        <small
            v-text="$t('prefs.header.workDaySubheader')"
        />
      </div>
      <div
          id="shiftDefaults"
          class="form__container">
        <h3
            v-text="$t('prefs.workDay.shiftDefaults')"
            class="form__header__small"
        />
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              class="mx-3 mb-3">
            <label
                for="autoStartTime"
                class="workDay__input__label">
              {{$t('prefs.autoStartTime')}}
              <b-btn
                  v-if="preferences.autoStartTime"
                  id="clearStartTime"
                  size="sm"
                  @click="clearStartTime()"
                  variant="danger">
                <b-icon-trash />
              </b-btn>
            </label>
            <b-form-timepicker
                id="autoStartTime"
                :placeholder="$t('scheduler._timePlaceholder')"
                v-model="preferences.autoStartTime"
                size="lg"
                :locale="$t('project.timeLocale')"
                class="workDay__input"
            />
          </b-col>
          <b-col
              cols="*"
              align="center"
              class="mx-3 mb-3">
            <label
                for="autoEndTime"
                class="workDay__input__label">
                {{$t('prefs.autoEndTime')}}
                <b-btn
                    v-if="preferences.autoEndTime"
                    id="clearEndTime"
                    size="sm"
                    @click="clearEndTime()"
                    variant="danger">
                  <b-icon-trash />
                </b-btn>
            </label>
            <b-form-timepicker
                id="autoEndTime"
                :placeholder="$t('scheduler._timePlaceholder')"
                v-model="preferences.autoEndTime"
                size="lg"
                :locale="$t('project.timeLocale')"
                class="workDay__input"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center"
            class="my-3">
          <b-col
              align="center"
              md="10"
              sm="12"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small">
              <span
                  for="autoSelectAllEmployees"
                  v-text="$t('prefs.autoSelectAllEmployees')"
                  class="ml-2 my-auto"
              />
              <b-form-checkbox
                  id="autoSelectAllEmployees"
                  v-model="preferences.autoSelectAllEmployees"
                  name="autoSelectAllEmployees"
                  @input="saveSettings('autoSelectAllEmployees', preferences.autoSelectAllEmployees)"
                  switch
                  size="lg"
                  class="form__checkbox">
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
              align="center"
              md="10"
              sm="12"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small">
              <span
                  for="autoSelectDay"
                  v-text="$t('prefs.autoSelectDay')"
                  class="ml-2 my-auto"
              />
              <b-form-checkbox
                  id="autoSelectDay"
                  v-model="preferences.autoSelectDay"
                  name="autoSelectDay"
                  @input="saveSettings('autoSelectDay', preferences.autoSelectDay)"
                  switch
                  size="lg"
                  class="form__checkbox">
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
              align="center"
              md="10"
              sm="12"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small">
              <span
                  for="showEmployeeId"
                  v-text="$t('prefs.showEmployeeId')"
                  class="ml-2 my-auto"
              />
              <b-form-checkbox
                  id="showEmployeeId"
                  v-model="preferences.showEmployeeId"
                  name="showEmployeeId"
                  @input="saveSettings('showEmployeeId', preferences.showEmployeeId)"
                  switch
                  size="lg"
                  class="form__checkbox">
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
              align="center"
              md="10"
              sm="12"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small">
              <span
                  for="weekStartsOn"
                  v-text="$t('prefs.weekStartsOn')"
                  class="ml-2 my-auto"
              />
              <b-form-select
                  id="weekStartsOn"
                  v-model="preferences.weekStartsOn"
                  name="weekStartsOn"
                  :options="[
                    { text: $t('workDay.daysOfWeek[0]'), value: 0 },
                    { text: $t('workDay.daysOfWeek[1]'), value: 1 },
                    { text: $t('workDay.daysOfWeek[2]'), value: 2 },
                    { text: $t('workDay.daysOfWeek[3]'), value: 3 },
                    { text: $t('workDay.daysOfWeek[4]'), value: 4 },
                    { text: $t('workDay.daysOfWeek[5]'), value: 5 },
                    { text: $t('workDay.daysOfWeek[6]'), value: 6 },
                  ]"
                  @change="saveSettings('weekStartsOn', preferences.weekStartsOn)"
                  size="lg"
                  class="py-2 form__input medium">
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
          id="signInOptions"
          class="form__container">
        <h3
            v-text="$t('prefs.workDay.signInOptions')"
            class="form__header__small"
        />
        <b-row
            align-h="center"
            class="my-3">
          <b-col
              align="center"
              cols="*"
              class="mx-3 mb-3">
            <div
                class="workDay__input__container">
              <span
                  v-text="$t('prefs.shiftSignInEarly')"
                  class="workDay__input__label__small"
              />
              <b-input-group
                  id="signInEarly"
                  class="mx-auto mt-2">
                <b-form-input
                    class="form__input minuteInput mr-2 ml-auto"
                    @input="saveSettings('shiftSignInEarly', preferences.shiftSignInEarly)"
                    v-model.number="preferences.shiftSignInEarly"
                />
                <b-input-group-append
                    v-text="$t('prefs.shiftSignInMinutesAhead')"
                    class="my-auto mr-auto"
                />
              </b-input-group>
            </div>
          </b-col>
          <b-col
              cols="*"
              align="center"
              class="mx-3 mb-3">
            <div
                class="workDay__input__container">
              <span
                  v-text="$t('prefs.shiftSignInAdvance')"
                  class="workDay__input__label__small"
              />
              <b-input-group
                id="signInAdvance"
                class="mx-auto mt-2">
                <b-form-input
                    class="form__input minuteInput ml-auto mr-2"
                    @input="saveSettings('shiftSignInAdvance', preferences.shiftSignInAdvance)"
                    v-model.number="preferences.shiftSignInAdvance"
                />
                <b-input-group-append
                    class="my-auto mr-auto"
                    v-text="$t('prefs.shiftSignInMinutesAhead')"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row
            align-h="center"
            class="my-3">
          <b-col
              align="center"
              cols="*"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small">
              <span
                  for="shiftSignInNoShift"
                  v-text="$t('prefs.shiftSignInNoShift')"
                  class="ml-2 my-auto"
              />
              <b-form-checkbox
                  id="shiftSignInNoShift"
                  v-model="preferences.shiftSignInNoShift"
                  name="shiftSignInNoShift"
                  @input="saveSettings('shiftSignInNoShift', preferences.shiftSignInNoShift)"
                  switch
                  size="lg"
                  class="form__checkbox">
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col
              align="center"
              cols="*"
              class="mx-3 mb-3">
            <div
                class="workDay__input__label__small mx-auto">
              <span
                  for="shiftSignInRetroactive"
                  v-text="$t('prefs.shiftSignInRetroactive')"
                  class="ml-2 my-auto"
              />
              <b-form-checkbox
                  id="shiftSignInRetroactive"
                  v-model="preferences.shiftSignInRetroactive"
                  name="shiftSignInRetroactive"
                  @input="saveSettings('shiftSignInRetroactive', preferences.shiftSignInRetroactive)"
                  switch
                  size="lg"
                  class="form__checkbox">
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              class="mx-3 my-3 workDay__container__dark">
            <label
                for="shiftSignInNotify"
                v-text="$t('prefs.shiftSignInNotify')"
                class="form__label"
            />
            <b-form-input
                id="shiftSignInNotify"
                type="text"
                v-model="notificationPhone"
                @input="saveSettings('shiftSignInNotify', preferences.shiftSignInNotify)"
                class="form__input large"
            />
          </b-col>
        </b-row>
      </div>
      <div
          id="payrollOptions"
          class="form__container">
        <h3
            v-text="$t('prefs.workDay.payrollOptions')"
            class="form__header__small"
        />
        <b-row
            align-h="center"
            class="my-3">
          <b-col
              align="center"
              cols="*"
              class="mx-3 mb-3">
            <div
                class="workDay__input__container">
              <label
                  for="payrollSendTo"
                  v-text="$t('prefs.payrollEmail')"
                  class="form__label"
              />
              <b-form-input
                  id="payrollSendTo"
                  type="text"
                  v-model="preferences.payrollEmail"
                  @input="saveSettings('payrollEmail', preferences.payrollEmail)"
                  class="form__input large"
              />
              <label
                  for="replyToInput"
                  v-text="$t('prefs.payrollEmailReplyTo')"
                  class="form__label"
              />
              <b-form-input
                  id="replyToInput"
                  type="text"
                  v-model="preferences.payrollEmailReplyTo"
                  @input="saveSettings('defaultEmailReplyTo', preferences.payrollEmailReplyTo)"
                  class="form__input large"
              />
              <label
                  for="subjectLineInput"
                  v-text="$t('prefs.payrollEmailSubjectLine')"
                  class="form__label"
              />
              <b-form-input
                  id="subjectLineInput"
                  type="text"
                  v-model="preferences.payrollEmailSubjectLine"
                  @input="saveSettings('payrollEmailSubjectLine', preferences.payrollEmailSubjectLine)"
                  class="form__input fullWidth"
              />
              <label
                  for="messageInput"
                  v-text="$t('prefs.payrollEmailMessage')"
                  class="form__label"
              />
              <b-form-textarea
                  id="messageInput"
                  type="text"
                  rows="8"
                  v-model="preferences.payrollEmailMessage"
                  @input="saveSettings('payrollEmailMessage', preferences.payrollEmailMessage)"
                  class="form__input fullWidth"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-tab>
    <b-tab
        id="helpTab"
        key="help"
        :title="$t('prefs.tab.help')"
        v-if="enable.help">
      {{$t('helpMessage')}}
    </b-tab>
    <b-tab
        id="resetTab"
        key="reset"
        :title="$t('prefs.tab.reset')"
        class="mx-5"
        v-if="enable.customer && customerPrefsToDisplay.length">
      <b-card
        class="my-2">
        <transition
            mode="out-in"
            name="fade">
          <div
              key="header"
              v-if="!confirmReset">
            <span
              v-html="$t('prefs.resetHeader')"
              class="card__header"
            />
            <br />
            <span
                v-text="$t('prefs.resetSubheader')"
            />
          </div>
          <span
              v-else
              key="deleteConfirm"
              v-html="$t('prefs.resetDeleteConfirm')"
          />
        </transition>
        <transition
            mode="out-in"
            name="fade">
          <b-btn
              size="sm"
              key="deleteButton"
              variant="secondary"
              @click="confirmReset = true"
              v-if="!confirmReset"
              class="mx-5 my-3"
              v-text="$t('prefs.resetButton')"
          />
          <b-btn-toolbar
              id="resetConfirmation"
              key="confirmDelete"
              v-else
              class="mx-auto my-3">
            <b-btn
                variant="info"
                @click="confirmReset = false"
                v-text="$t('cancel')"
                class="mx-2"
            />
            <b-btn
                size="lg"
                variant="warning"
                @click="deleteAllSettings()"
                v-text="$t('prefs.resetConfirm')"
                class="mx-2"
            />
          </b-btn-toolbar>
        </transition>
      </b-card>
      <b-list-group
          id="extantPreferences">
        <b-list-group-item
            v-for="(pref, index) in customerPrefsToDisplay"
            :key="index"
            :class="!markedForDeletion(pref[0]) ? 'info deleteSettings' : 'warning deleteSettings'"
            @click="deleteSetting(pref[0])"
            @mouseover="prefHover(index)"
            @mouseleave="prefHover(null)"
            button>
          <transition
              name="fade"
              mode="out-in">
            <b-icon
                icon="trash"
                v-show="prefHoverItem === index || markedForDeletion(pref[0])"
            />
          </transition>
          <span
              v-text="$t(`prefs.${pref[0]}`)"
          />
          <small
              v-text="pref[1]"
              class="mx-2 grey"
          />
        </b-list-group-item>
      </b-list-group>
      <transition
          name="fade"
          mode="out-in">
        <b-row
            class="m-3">
          <b-col
              align="center">
            <b-btn-group
                id="confirmPrefResetChoices"
                v-if="customerPrefsToDelete.length">
              <b-btn
                  v-text="$t('cancel')"
                  variant="secondary"
                  @click="deleteSettingsCancel()"
                  class="mx-1"
              />
              <b-btn
                  v-text="$t('prefs.resetConfirm')"
                  variant="warning"
                  @click="deleteSettingsConfirm()"
                  class="mx-1 px-2"
              />
            </b-btn-group>
          </b-col>
        </b-row>
      </transition>
    </b-tab>
  </b-tabs>
</template>

<script>
import moment from 'moment';
import { logos } from '@/store/aws';
import totalCalculators from '@/mixins/totalCalculators';

export default {
  name: 'NavBar',
  mixins: [totalCalculators],
  props: [
    'customerDetails',
    'model',
    'enable',
  ],
  computed: {
    customerPrefsToDisplay() {
      const prefs = Object.entries(this.customerDetails.preferences);
      const prefsToReturn = [];
      let needToAddColorOption = false;
      for (let a = 0; a < prefs.length; a += 1) {
        if (prefs[a][1].length > 64) {
          prefs[a][1] = `${prefs[a][1].slice(0, 63)}...`;
        }
        if (prefs[a][0].slice(-13) === 'AppearsOnLeft') {
          if (prefs[a][1]) {
            prefs[a][1] = this.$t('prefs.appearsOnLeftTrueLabel');
          } else {
            prefs[a][1] = this.$t('prefs.appearsOnLeftFalseLabel');
          }
        }
        if (prefs[a][0] === 'user.logo') {
          prefs[a][1] = this.$t('prefs.userLogoCustom');
        }
        if (prefs[a][0] === 'statusAutoUpdate') {
          prefs[a][0] = 'statusAutoUpdateLong';
        }
        if (prefs[a][0] === 'markAsSentDownload') {
          prefs[a][0] = 'markAsSentDownloadLong';
        }
        if (prefs[a][0] === 'markAsSentEmail') {
          prefs[a][0] = 'markAsSentEmailLong';
        }
        if (prefs[a][0] === 'markAsSentPrint') {
          prefs[a][0] = 'markAsSentPrintLong';
        }
        if (prefs[a][0] === 'defaultTerms') {
          if (prefs[a][1]) {
            prefs[a][1] = `${prefs[a][1]} ${this.$t('invoice.days')}`;
          } else {
            prefs[a][1] = this.$t('invoice.daySame');
          }
        }
        if (prefs[a][1] === false) {
          prefs[a][1] = this.$t('prefs.labelFalse');
        }
        if (prefs[a][1] === true) {
          prefs[a][1] = this.$t('prefs.labelTrue');
        }
        if (prefs[a][0] === 'colorLabel'
            || prefs[a][0] === 'colorAccent'
            || prefs[a][0] === 'colorBackground'
            || prefs[a][0] === 'colorImpact'
            || prefs[a][0] === 'colorText') {
          needToAddColorOption = true;
        } else if (prefs[a][0] !== 'customer' && prefs[a][0] !== '_id') {
          prefsToReturn.push(prefs[a]);
        }
      }
      if (needToAddColorOption) {
        prefsToReturn.push([
          'colorProfileCustom',
          this.$t('prefs.colorProfileCustomLabel'),
        ]);
      }
      return prefsToReturn;
    },
    dateCreated() {
      return moment(this.model.date.created).format('l');
    },
    dateDue() {
      return moment(this.model.date.invoice.due).format('l');
    },
    dateDocument() {
      if (this.model.date.invoice.dated) {
        return moment(this.model.date.invoice.dated).format('l');
      }
      return this.dateCreated;
    },
    dateExpires() {
      return moment(this.model.date.proposal.expires).format('l');
    },
    dateSent() {
      return moment(this.model.date.invoice.sent).format('l');
    },
    dateProposal() {
      if (this.model.date.proposal.dated) {
        return moment(this.model.date.proposal.dated).format('l');
      }
      return this.dateCreated;
    },
    dateProposalSent() {
      return moment(this.model.date.proposal.sent).format('l');
    },
    dateProposalExpires() {
      return moment(this.model.date.proposal.expires).format('l');
    },
    datesRecord() {
      let datesRecord = null;
      if (this.model.date.invoice.due || this.model.date.invoice.sent) {
        datesRecord = '';
        if (this.model.date.invoice.dated && moment(this.model.date.invoice.dated).isValid()) {
          datesRecord += `${this.$t('invoice.dateInvoice')}: ${this.dateDocument}<br/>`;
        }
        if (this.model.date.invoice.sent && moment(this.model.date.invoice.sent).isValid()) {
          datesRecord += `${this.$t('invoice.dateInvoiceSent')}: ${this.dateSent}`;
          if (this.model.deliveryMethod.invoice) {
            datesRecord += ` <small>${this.model.deliveryMethod.invoice}</small>`;
          }
          datesRecord += '<br />';
        }
        if (this.model.date.invoice.due && moment(this.model.date.invoice.due).isValid()) {
          datesRecord += `${this.$t('invoice.dateInvoiceDue')}: ${this.dateDue}<br/>`;
        }
      }
      return datesRecord;
    },
    datesRecordProposal() {
      let datesRecord = null;
      if (this.model.date.proposal.dated
      || this.model.date.proposal.expires
      || this.model.date.proposal.sent) {
        datesRecord = '';
        if (this.model.date.proposal.dated && moment(this.model.date.proposal.dated).isValid()) {
          datesRecord += `${this.$t('invoice.dateProposal')}: ${this.dateProposal}<br/>`;
        }
        if (this.model.date.proposal.sent && moment(this.model.date.proposal.sent).isValid()) {
          datesRecord += `${this.$t('invoice.dateSentProposal')}: ${this.dateProposalSent}`;
          if (this.model.deliveryMethod.proposal) {
            datesRecord += ` <small>${this.model.deliveryMethod.proposal}</small>`;
          }
          datesRecord += '<br />';
        }
        if (this.model.date.proposal.expires && moment(this.model.date.proposal.expires).isValid()) {
          datesRecord += `${this.$t('invoice.dateProposalExpires')}: ${this.dateProposalExpires}<br/>`;
        }
      }
      return datesRecord;
    },
    defaultHeader: {
      get() {
        if (this.preferences.enableHeader) {
          return this.preferences.defaultHeader;
        }
        return null;
      },
      set(newVal) {
        this.preferences.defaultHeader = newVal;
      },
    },
    defaultFooter: {
      get() {
        if (this.preferences.enableFooter) {
          return this.preferences.defaultFooter;
        }
        return null;
      },
      set(newVal) {
        this.preferences.defaultFooter = newVal;
      },
    },
    defaultMemo: {
      get() {
        if (this.preferences.enableMemo) {
          return this.preferences.defaultMemo;
        }
        return null;
      },
      set(newVal) {
        this.preferences.defaultMemo = newVal;
      },
    },
    defaultSubheader: {
      get() {
        if (this.preferences.enableSubheader) {
          return this.preferences.defaultSubheader;
        }
        return null;
      },
      set(newVal) {
        this.preferences.defaultSubheader = newVal;
      },
    },
    // documentNumber() {
    //   let prefix = null;
    //   if (this.preferences.defaultPrefix) {
    //     prefix = this.preferences.defaultPrefix;
    //   } else if (this.preferences.defaultType) {
    //     prefix = this.preferences.defaultType;
    //   } else {
    //     prefix = this.$t('invoice.defaultType');
    //   }
    //   const leadingZeros = '0000000000';
    //   let docNumber = (this.preferences.documentNumber * 1);
    //   if (!docNumber || typeof docNumber !== 'number') {
    //     docNumber = 1;
    //   }
    //   let size = this.preferences.documentNumberLength;
    //   if (size === 1 && docNumber > 9) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 2 && docNumber > 99) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 3 && docNumber > 999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 4 && docNumber > 9999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 5 && docNumber > 99999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 6 && docNumber > 999999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 7 && docNumber > 9999999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 8 && docNumber > 99999999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   if (size === 9 && docNumber > 999999999) {
    //     size += 1;
    //     this.increaseDocumentNumberLength();
    //   }
    //   return `${prefix}-${(leadingZeros + docNumber).substr(-size)}`;
    // },
    documentNumberPrefix() {
      return this.$t('prefs.documentNumberHeader');
    },
    dueDateWouldBe() {
      return moment().add(this.preferences.defaultTerms, 'days').format('L');
    },
    dueDateSet() {
      if (this.model && this.model.date.invoice.sent && this.model.date.invoice.due) {
        return true;
      }
      return false;
    },
    proposalExpirationDate() {
      if (this.model && this.model.date.proposal.sent && this.model.date.proposal.expires) {
        return true;
      }
      return false;
    },
    proposalExpirationDateWouldBe() {
      return moment().add(this.preferences.defaultTermsProposal, 'days').format('L');
    },
    headerSubtext() {
      if (this.enable.document) {
        return `${this.$t('prefs.header.documentSub')} ${this.model.number}`;
      }
      if (this.enable.customer) {
        return this.$t('prefs.header.customerSub');
      }
      return this.$t('prefs.header.systemSub');
    },
    headerText() {
      if (this.enable.document) {
        return this.$t('prefs.header.document');
      }
      if (this.enable.customer) {
        const customerIdentifier = this.customerIdentifier(this.$store.getters.customer(this.customerDetails._id))
          ? this.customerIdentifier(this.$store.getters.customer(this.customerDetails._id)) : this.$t('invoice.thisCustomer');
        return `${this.$t('prefs.header.customer')} ${customerIdentifier}`;
      }
      return this.$t('prefs.header.system');
    },
    notificationPhone: {
      get() {
        return this.displayPhone(this.preferences.shiftSignInNotify);
      },
      set(newVal) {
        this.preferences.shiftSignInNotify = newVal.replace(/[^A-Z0-9]+/ig, '');
      },
    },
    perPage: {
      get() {
        return this.preferences.perPage;
      },
      set(newVal) {
        if (newVal * 1 === 0) {
          this.preferences.perPage = 10;
        } else if ((this.preferences.perPage * 1) > 100) {
          this.preferences.perPage = 100;
        } else {
          this.preferences.perPage = newVal;
        }
        this.saveSettings('perPage', this.preferences.perPage);
      },
    },
    preferences: {
      get() {
        if (this.enable.document) {
          return {
            ...this.$store.state.preferences,
            ...this.customerDetails.preferences,
            ...this.model.preferences,
          };
        }
        if (this.customerDetails) {
          return {
            ...this.$store.state.preferences,
            ...this.customerDetails.preferences,
          };
        }
        return this.$store.state.preferences;
      },
    },
    prefixLabel() {
      if (this.enable.customer) {
        return this.$t('prefs.defaultPrefixCust');
      }
      return this.$t('prefs.defaultPrefix');
    },
    proposalHeaderText() {
      let headerText;
      if (this.proposalMode) {
        headerText = this.$t('prefs.paymentMemoProposal');
      } else if (this.preferences.showPaymentsMemo
              && this.preferences.paymentMemoHeader) {
        headerText = this.preferences.paymentMemoHeader;
      } else {
        headerText = this.$t('prefs.paymentMemo');
      }
      return headerText;
    },
    terms: {
      get() {
        return this.preferences.defaultTerms;
      },
      set(newVal) {
        if (newVal * 1 === 0) {
          this.preferences.defaultTerms = 0;
        } else {
          this.preferences.defaultTerms = newVal;
        }
        this.saveSettings('defaultTerms', this.preferences.defaultTerms);
      },
    },
    termsProposal: {
      get() {
        return this.preferences.defaultTermsProposal;
      },
      set(newVal) {
        if (newVal * 1 === 0) {
          this.preferences.defaultTermsProposal = 0;
        } else {
          this.preferences.defaultTermsProposal = newVal;
        }
        this.saveSettings('defaultTermsProposal', this.preferences.defaultTermsProposal);
      },
    },
    userPhone: {
      get() {
        return this.displayPhone(this.preferences.user.phone);
      },
      set(newVal) {
        this.preferences.user.phone = newVal.replace(/[^A-Z0-9]+/ig, '');
      },
    },
    userFax: {
      get() {
        return this.displayPhone(this.preferences.user.fax);
      },
      set(newVal) {
        this.preferences.user.fax = newVal.replace(/[^A-Z0-9]+/ig, '');
      },
    },
    userZip: {
      get() {
        return this.preferences.user.address.zip ? this.preferences.user.address.zip : null;
      },
      set(newVal) {
        this.preferences.user.address.zip = newVal;
      },
    },
    zipResults() {
      return this.$store.state.zipLookup;
    },
  },
  data() {
    return {
      addressLabel: null,
      addressSecondaryLabel: null,
      activeTab: 0,
      addingLogo: false,
      addingSecondLine: false,
      brandHoverMessage: false,
      changeDate: null,
      changeDateDue: null,
      changeDateProposal: null,
      changeDateProposalExpires: null,
      colorChoices: [
        {
          background: '#000000',
          text: '#ffffff',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'Black',
        },
        {
          background: '#2F4F4F',
          text: '#FFFFFF',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'Slate',
        },
        {
          background: '#C0C0C0',
          text: '#2f4f4f',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'Silver',
        },
        {
          background: '#FFFFF0',
          text: '#2F4F4F',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'Ivory',
        },
        {
          background: '#ffffff',
          text: '#000000',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'White',
        },
        {
          background: '#8B0000',
          text: '#FFCCCC',
          accent: '#C79B9B',
          impact: '#8B0000',
          label: 'Ruby',
        },
        {
          background: '#FF0000',
          text: '#FFFFFF',
          accent: '#D47979',
          impact: '#780000',
          label: 'Red',
        },
        {
          background: '#FF8C00',
          text: '#FFFFF0',
          accent: '#FF8C00',
          impact: '#D67600',
          label: 'Orange',
        },
        {
          background: '#FFD700',
          text: '#4B0082',
          accent: '#BDB76B',
          impact: '#4B0082',
          label: 'Gold',
        },
        {
          background: '#FFFF00',
          text: '#242424',
          accent: '#808080',
          impact: '#2f4f4f',
          label: 'Yellow',
        },
        {
          background: '#F0E68C',
          text: '#6B6929',
          accent: '#808080',
          impact: '#8B4513',
          label: 'Khaki',
        },
        {
          background: '#00FF00',
          text: '#004522',
          accent: '#1FCF75',
          impact: '#006331',
          label: 'Lime',
        },
        {
          background: '#2E8B57',
          text: '#F5F5F5',
          accent: '#67c181',
          impact: '#2E8B57',
          label: 'Green',
        },
        {
          background: '#006400',
          text: '#FFFFFF',
          accent: '#3CB371',
          impact: '#006400',
          label: 'Forest',
        },
        {
          background: '#191970',
          text: '#FAF0E6',
          accent: '#6495ED',
          impact: '#191970',
          label: 'Navy',
        },
        {
          background: '#4169E1',
          text: '#F0FFFF',
          accent: '#6495ED',
          impact: '#191970',
          label: 'Blue',
        },
        {
          background: '#4682B4',
          text: '#F0F8FF',
          accent: '#40E0D0',
          impact: '#4682B4',
          label: 'Steel',
        },
        {
          background: '#AFEEEE',
          text: '#191970',
          accent: '#40E0D0',
          impact: '#191970',
          label: 'Cyan',
        },
        {
          background: '#800080',
          text: '#E6E6FA',
          accent: '#DDA0DD',
          impact: '#800080',
          label: 'Purple',
        },
        {
          background: '#FFB6C1',
          text: '#A1106C',
          accent: '#DB7093',
          impact: '#C71585',
          label: 'Pink',
        },
      ],
      colorToUpdate: {},
      confirmReset: false,
      customColors: false,
      customerPrefsToDelete: [],
      deleteInProgress: false,
      deleteInvoiceRequest: false,
      lastChanged: moment(),
      lastChangedBefore: moment(),
      lastSaved: moment(),
      leftOrRight: [{
        text: 'Left',
        value: true,
      },
      {
        text: 'Right',
        value: false,
      }],
      needToClose: false,
      prefHoverItem: null,
      preferencesToSave: {},
      proposalMode: false,
      showErrorMsg: null,
      showSettingsLabel: false,
      typeOfDueDate: [
        {
          text: this.$t('prefs.dueDateIsAutomatic'),
          value: true,
        },
        {
          text: this.$t('prefs.dueDateIsManual'),
          value: false,
        },
      ],
      typeOfExpirationDate: [
        {
          text: this.$t('prefs.dueDateIsAutomaticProposal'),
          value: true,
        },
        {
          text: this.$t('prefs.dueDateIsManualProposal'),
          value: false,
        },
      ],
      uploadInProgress: false,
      zipWatcher: {
        id: null,
        type: null,
      },
    };
  },
  methods: {
    addressSecondLine() {
      if (!this.addingSecondLine && !this.preferences.user.address.secondLine) {
        this.addingSecondLine = true;
      } else {
        this.addingSecondLine = false;
        if (this.preferences.user.address.secondLine) {
          this.preferences.user.address.secondLine = null;
          this.saveSettings('user', this.preferences.user);
        }
      }
    },
    changeColor(color) {
      if (!this.proposalMode) {
        this.preferences.colorAccent = color.accent;
        this.saveSettings('colorAccent', this.preferences.colorAccent);
        this.preferences.colorBackground = color.background;
        this.saveSettings('colorBackground', this.preferences.colorBackground);
        this.preferences.colorImpact = color.impact;
        this.saveSettings('colorImpact', this.preferences.colorImpact);
        this.preferences.colorText = color.text;
        this.saveSettings('colorText', this.preferences.colorText);
        this.preferences.colorLabel = color.label;
        this.saveSettings('colorLabel', this.preferences.colorLabel);
      } else {
        this.preferences.colorAccentProposal = color.accent;
        this.saveSettings('colorAccentProposal', this.preferences.colorAccentProposal);
        this.preferences.colorBackgroundProposal = color.background;
        this.saveSettings('colorBackgroundProposal', this.preferences.colorBackgroundProposal);
        this.preferences.colorImpactProposal = color.impact;
        this.saveSettings('colorImpactProposal', this.preferences.colorImpactProposal);
        this.preferences.colorTextProposal = color.text;
        this.saveSettings('colorTextProposal', this.preferences.colorTextProposal);
        this.preferences.colorLabelProposal = color.label;
        this.saveSettings('colorLabelProposal', this.preferences.colorLabelProposal);
      }
      this.customColors = false;
    },
    changeDateButton() {
      this.model.date.invoice.dated = new Date();
      this.changeDate = this.model.date.invoice.dated;
    },
    changeDateProposalButton() {
      this.model.date.proposal.dated = new Date();
      this.changeDateProposal = this.model.date.proposal.dated;
    },
    changeDateDueButton() {
      this.changeDateDue = this.dueDate;
    },
    changeDateExpiresButton() {
      this.changeDateProposalExpires = this.expirationDate;
    },
    changeDateCancel() {
      this.changeDate = null;
    },
    changeDateDueCancel() {
      this.changeDateDue = null;
    },
    changeDateDueReset() {
      this.model.date.invoice.due = moment(this.model.date.invoice.dated).add(this.preferences.defaultTerms, 'days').format();
      this.changeDateDue = null;
    },
    changeDateProposalExpiresReset() {
      this.model.dateProposalExpires = moment(this.model.date.proposal.dated).add(this.preferences.defaultTermsProposal, 'days').format();
      this.changeDateProposalExpires = null;
    },
    checkInvoiceDate() {
      if (!this.model.date.invoice.dated && this.changeDate) {
        this.model.date.invoice.dated = this.changeDate;
      }
      if (!this.model.date.invoice.dated && !this.changeDate) {
        this.model.date.invoice.dated = this.model.date.created;
      }
      this.model.date.invoice.dated = moment(this.model.date.invoice.dated).format();
      this.changeDate = null;
      this.$emit('change-date');
    },
    checkInvoiceDateDue() {
      if (!this.model.date.invoice.due && this.changeDateDue) {
        this.model.date.invoice.due = this.changeDateDue;
      }
      if (!this.model.date.invoice.due && !this.changeDateDue) {
        this.model.date.invoice.due = moment(this.model.date.invoice.dated).add(this.preferences.defaultTerms, 'days');
      }
      this.model.date.inbvoice.due = moment(this.model.date.invoice.due).format();
      this.changeDateDue = null;
      this.$emit('change-date');
    },
    checkProposalDate() {
      if (!this.model.date.proposal.dated && this.changeDateProposal) {
        this.model.date.proposal.dated = this.changeDateProposal;
      }
      if (!this.model.date.proposal.dated && !this.changeDateProposal) {
        this.model.date.proposal.dated = this.model.date.created;
      }
      this.model.date.proposal.dated = moment(this.model.date.proposal.dated).format();
      this.changeDateProposal = null;
      this.$emit('change-date');
    },
    checkProposalExpirationDate() {
      if (!this.model.date.proposal.expires && this.changeDateProposalExpires) {
        this.model.date.proposal.expires = this.changeDateProposalExpires;
      }
      if (!this.model.date.proposal.expires && !this.changeDateProposalExpires) {
        this.model.date.proposal.expires = moment(this.model.date.proposal.dated).add(this.preferences.defaultTermsProposal, 'days');
      }
      this.model.date.proposal.expires = moment(this.model.date.proposal.expires).format();
      this.changeDateProposalExpires = null;
      this.$emit('change-date');
    },
    clearDateRecord() {
      this.model.notes.push({
        _id: this.$guid(),
        date: moment().format(),
        // eslint-disable-next-line max-len
        text: `${this.$t('invoice.dateResetNotes')}: ${moment(this.model.date.invoice.sent).format('l')} ${this.$t('invoice.dateResetNotes2')}: ${moment(this.model.date.invoice.due).format('l')}`,
        showToCustomer: false,
      });
      this.model.date.invoice.dated = null;
      this.model.date.invoice.sent = null;
      this.model.date.invoice.due = null;
      this.model.date.invoice.paid = null;
      this.model.date.proposal.dated = null;
      this.model.date.proposal.sent = null;
      this.model.date.proposal.expires = null;
      this.$emit('change-date');
    },
    clearEndTime() {
      this.preferences.autoEndTime = null;
      this.saveSettings('autoEndTime', this.preferences.autoEndTime);
    },
    clearStartTime() {
      this.preferences.autoStartTime = null;
      this.saveSettings('autoSTartTime', this.preferences.autoStartTime);
    },
    customizeColors() {
      this.customColors = !this.customColors;
    },
    deleteInvoiceCancel() {
      this.deleteInvoiceRequest = false;
    },
    deleteInvoice(id) {
      this.deleteInvoiceRequest = false;
      this.$store.state.deleteRequest = id;
    },
    deleteAllSettings() {
      this.$emit('clearCustomerPrefs');
      this.confirmReset = false;
      this.activeTab = 0;
    },
    deleteSetting(selectedOption) {
      // toggle delete/don't delete
      let needToRemove = true;
      for (let a = 0; a < this.customerPrefsToDelete.length; a += 1) {
        if (selectedOption === this.customerPrefsToDelete[a]) {
          needToRemove = false;
          this.customerPrefsToDelete.splice(a, 1);
        }
      }
      if (needToRemove) {
        this.customerPrefsToDelete.push(selectedOption);
      }
    },
    deleteSettingsCancel() {
      this.customerPrefsToDelete = [];
    },
    deleteSettingsConfirm() {
      const needToAdd = [];
      let needToRemove = null;
      for (let a = 0; a < this.customerPrefsToDelete.length; a += 1) {
        if (this.customerPrefsToDelete[a] === 'colorProfileCustom') {
          const prefs = Object.entries(this.customerDetails.preferences);
          for (let z = 0; z < prefs.length; z += 1) {
            if (prefs[z][0] === 'colorLabel'
                || prefs[z][0] === 'colorAccent'
                || prefs[z][0] === 'colorBackground'
                || prefs[z][0] === 'colorImpact'
                || prefs[z][0] === 'colorText') {
              needToRemove = a;
              needToAdd.push(prefs[z][0]);
            }
          }
        }
        if (this.customerPrefsToDelete[a].slice(-4) === 'Long') {
          this.customerPrefsToDelete[a] = this.customerPrefsToDelete[a].slice(0, -4);
        }
      }
      if (needToAdd.length) {
        this.customerPrefsToDelete.splice(needToRemove, 1);
        for (let x = 0; x < needToAdd.length; x += 1) {
          this.customerPrefsToDelete.push(needToAdd[x]);
        }
      }
      const customerPrefsObject = { ...this.customerDetails.preferences };
      for (let i = 0; i < this.customerPrefsToDelete.length; i += 1) {
        const key = this.customerPrefsToDelete[i];
        this.$delete(customerPrefsObject, key);
      }
      // eslint-disable-next-line max-len
      const receiptToSend = `${this.$t('customer.clearedPref')} ${this.customerPrefsToDelete.length} ${this.customerPrefsToDelete.length >= 2 ? this.$t('customer.clearedPref2a') : this.$t('customer.clearedPref2')}`;
      this.$emit('clearCustomerPrefItem', { receipt: receiptToSend, prefs: customerPrefsObject });
      this.activeTab = 0;
      this.customerPrefsToDelete = [];
    },
    duplicateInvoice(model) {
      this.$emit('duplicateDocument', model);
    },
    ifComingSoon(goingTo) {
      if (goingTo === 'Items') {
        this.$store.dispatch('comingSoon');
      }
    },
    increaseDocumentNumberLength() {
      this.preferences.documentNumberLength += 1;
      this.saveSettings('documentNumberLength', this.preferences.documentNumberLength);
    },
    logoAdd() {
      this.addingLogo = true;
      this.showErrorMsg = null;
    },
    logoRemove() {
      this.addingLogo = false;
      this.deleteInProgress = true;
      console.log(this.preferences.user.logo.split('/').pop());
      logos.deleteObject({
        Key: this.preferences.user.logo.split('/').pop(),
      }, (err, data) => {
        if (err) {
          console.log('failed', err.message);
          this.deleteInProgress = false;
        } else {
          this.preferences.user.logo = null;
          this.deleteInProgress = false;
          this.$store.dispatch('clearLogo');
          this.saveSettings('user', this.preferences.user);
          console.log('file deleted', data);
        }
      });
    },
    markedForDeletion(pref) {
      for (let a = 0; a < this.customerPrefsToDelete.length; a += 1) {
        if (pref === this.customerPrefsToDelete[a]) {
          return true;
        }
      }
      return false;
    },
    prefHover(index) {
      if (index === null) {
        this.prefHoverItem = null;
      } else {
        this.prefHoverItem = index;
      }
    },
    uploadLogo(input) {
      console.log('uploading');
      this.uploadInProgress = true;
      if (input.srcElement.files[0].size >= 3000000) {
        this.addingLogo = false;
        this.uploadInProgress = false;
        this.showErrorMsg = `${this.$t('prefs.logo.errorSize')}`;
      } else {
        logos.upload({
          Key: `${this.$guid()}-${input.srcElement.files[0].name}`,
          Body: input.srcElement.files[0],
          ACL: 'public-read',
        }, (err, data) => {
          if (err) {
            console.log('Failed:', err.message);
            this.addingLogo = false;
            this.uploadInProgress = false;
            this.showErrorMsg = `${this.$t('prefs.logo.error')}: ${err.message}`;
            this.$store.dispatch('clearLogo');
          } else {
            console.log('Upload complete!', data.Location);
            this.addingLogo = false;
            this.preferences.user.logo = data.Location;
            this.uploadInProgress = false;
            this.saveSettings('user', this.preferences.user);
            this.lastSaved = moment();
          }
        });
      }
    },
    restartApp() {
      this.$router.push('/');
      this.$router.go();
    },
    saveColorSettings(pref, value) {
      const prefObj = {};
      prefObj[pref] = value;
      this.lastChanged = moment();
      this.colorToUpdate = prefObj;
    },
    saveSettings(pref, value) {
      const prefObj = {};
      prefObj[pref] = value;
      this.$emit('save-now', prefObj);
    },
    showSettings() {
      this.$bvModal.show('settingsModal');
    },
    updateModel() {
      this.$emit('update-model');
    },
    updateTaxOption() {
      this.saveSettings('taxOption', this.preferences.taxOption);
      this.$emit('update-taxes');
    },
    updateTaxRate() {
      this.saveSettings('taxRate', this.preferences.taxRate);
      this.$emit('update-taxes');
    },
    zipLookup(zip, destination) {
      if (zip && !this.preferences.zipAutofill && destination === 'userZip') {
        // No autofill - just save
        this.saveSettings('user', this.preferences.user);
        return;
      }
      if (zip && zip.length === 5) {
        // Send results to zipwatcher
        const lookupId = this.$guid();
        const lookup = {
          id: lookupId,
          zip,
        };
        this.zipWatcher.id = lookupId;
        this.zipWatcher.type = destination;
        this.$store.dispatch('zipLookup', lookup);
      } else if (destination === 'userZip') {
        this.zipWatcher.id = null;
        this.zipWatcher.type = null;
        this.$store.dispatch('zipLookupClear');
        this.saveSettings('user', this.preferences.user);
      }
    },
  },
  watch: {
    lastChanged() {
      const colorSaveWatcher = setInterval(() => {
        if ((this.lastSaved < this.lastChanged)
          && ((this.lastChangedBefore < moment(this.lastChanged).subtract(0.5, 'seconds'))
            || (this.lastChanged < moment().subtract(0.5, 'seconds'))
          )) {
          this.lastSaved = moment();
          this.preferences.colorLabel = this.$t('prefs.colorCustomLabel');
          this.$emit('save-now', { colorLabel: this.preferences.colorLabel });
          this.$emit('save-now', this.colorToUpdate);
          this.colorToUpdate = {};
        }
      }, 350);
      // eslint-disable-next-line no-unused-expressions
      colorSaveWatcher;
      setTimeout(() => {
        clearInterval(colorSaveWatcher);
      }, 750);
    },
    'zipResults.id': {
      handler(newVal) {
        function titleCase(input) {
          let city = input.toLowerCase();
          city = city.split(' ');
          for (let a = 0; a < city.length; a += 1) {
            city[a] = `${city[a].charAt(0).toUpperCase()}${city[a].slice(1)}`;
          }
          return city.join(' ');
        }
        if (newVal && newVal !== 'err' && this.zipWatcher.type === 'userZip') {
          if (newVal === this.zipWatcher.id) {
            /* if (this.zipWatcher.type === 'primary' && this.zipResults.city) {
              this.customerDetails.address.city = titleCase(this.zipResults.city);
              this.customerDetails.address.state = this.zipResults.state;
            }
            if (this.zipWatcher.type === 'billing' && this.zipResults.city) {
              this.customerDetails.billingAddress.city = titleCase(this.zipResults.city);
              this.customerDetails.billingAddress.state = this.zipResults.state;
            } */
            if (this.zipResults.city) {
              this.preferences.user.address.city = titleCase(this.zipResults.city);
              this.preferences.user.address.state = this.zipResults.state;
              this.saveSettings('user', this.preferences.user);
            }
            this.zipWatcher = {
              id: null,
              type: null,
            };
            this.saveSettings('user', this.preferences.user);
            this.$store.dispatch('zipLookupClear');
          }
        }
        if (newVal === 'err' && this.zipWatcher.type === 'userZip') {
          if (this.preferences.user.address.city) {
            this.preferences.user.address.city = null;
          }
          if (this.preferences.user.address.state) {
            this.preferences.user.address.state = null;
          }
          this.saveSettings('user', this.preferences.user);
          this.zipWatcher = {
            id: null,
            type: null,
          };
          this.$store.dispatch('zipLookupClear');
          // this.saveSettings('user', this.preferences.user);
        }
      },
    },
  },
};
</script>
