import AWS from 'aws-sdk';
import {
  bucketOrig,
  bucketLogo,
  identityPool,
  region,
  // regionSes,
  // userAdminAccessKey,
  // userAdminSecret,
} from '../../secrets.json';


AWS.config.update({
  region: region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-1:13f99002-9b08-4f68-9c80-82a9b1de7701',
  })
});

export const s3key = 'test';
// export const sns = new AWS.SNS();

// export const ses = new AWS.SES({
//   params: { Region: regionSes },
// });

export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketOrig },
});

export const logos = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketLogo },
});

export const bucket = bucketOrig;

export async function signUp(Username, TemporaryPassword) {
  let userData;
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.adminCreateUser({
      UserPoolId: identityPool,
      Username,
      TemporaryPassword,
    }).promise().then((i) => {
      userData = i;
    });
  } catch (err) {
    return { err };
  }
  return userData.User;
}

export async function resetPassword(Username, Password) {
  let status;
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.adminSetUserPassword({
      UserPoolId: identityPool,
      Username,
      Password,
      Permanent: false,
    }).promise().then((i) => {
      status = i.$response;
    });
  } catch (err) {
    return { err };
  }
  return status;
}
